import React from "react";
import BaseWrapper from "../components/auth-flow/BaseWrapper";
import OTPVerificationForm from "../components/auth-flow/OTPVerificationForm";
import TitleSetter from "../components/common/TitleSetter";

const OTPVerification = () => {
  return (
    <div>
      <TitleSetter title={"OTP Verification"} />
      <BaseWrapper
        FormComponent={OTPVerificationForm}
        backgroundImageURL={`url(${process.env.PUBLIC_URL + "/images/loginbg.png"})`}
      />
    </div>
  );
};

export default OTPVerification;
