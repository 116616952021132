/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../styles/pages/contact-us.css";
import TitleSetter from "../components/common/TitleSetter";

const ContactUs = () => {
  return (
    <>
      <TitleSetter title={"Contact Us"} />
      <div className="explorewrap contactuswrp">
        <div className="container">
          <div className="contactbanner-wrap">
            <div className="contentmain-text">
              <div className="row">
                <div className="col-md-5">
                  <div className="left-Aboutimg">
                    <img src="images/logo_ergin.jpg" alt="" />
                  </div>
                  <div className="contact-content">
                    <p>
                      With offices and joint-ventures worldwide, Elgin is ready
                      to find the perfect solution for your operational needs.
                      Contact Elgin’s product divisions directly from the below
                      list
                    </p>
                  </div>
                  <div className="industrail-division-wrap">
              <div className="contactSubheading">Industrial Division</div>
              <div className="row">
                <div className="col-md-12">
                  <div className="industrail-division">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="divisiondiv internationdivisiondiv">
                        <div className="contryname">
                    <img src="images/usa.png" alt="" />
                      USA
                      </div>
                      <div className="internationadd">
                          <p>
                            10050 Cash Road <br /> Stafford, TX 77477 <br />{" "}
                            Phone: +1 281-261-5778 <br /> Fax: +1 281-499-4080{" "}
                            <br /> Email :elgin.info@elginps.com
                          </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="divisiondiv internationdivisiondiv">
                        <div className="contryname">
                    <img src="images/usa.png" alt="" />
                      USA
                      </div>
                      <div className="internationadd">
                          <p>
                            {" "}
                            172 Black Betsy Bottom Road
                            <br /> Poca, WV 25159 <br /> Phone: +1 304-755-0292{" "}
                            <br /> Fax: +1 304-755-0296 <br />
                            Email :Chad.Dillon@elginps.com
                          </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </div>
                <div className="col-md-7 sm-none">
                <div className="contactBanner-bg"></div>
                </div>
              </div>
            </div>
            
            <div className="internationdivsion-wrap">
              <div className="contactSubheading">
                Industrial Division (International)
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="internationdivisiondiv">
                    <div className="contryname">
                    <img src="images/canada.png" alt="" />
                      CANADA
                      </div>
                    <div className="internationadd">
                      <p>
                        Bay 119 <br /> 5512 45th Street
                        <br /> Leduc, AB T9E 7B2
                        <br /> Phone: +1-877-986-7277 (Toll Free)
                        <br /> Phone: +1-780-986-7277
                        <br /> Email :David.Blasetti@elginps.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="internationdivisiondiv">
                    <div className="contryname"> 
                    <img src="images/russia.png" alt="" /> 
                    RUSSIA
                    </div>
                    <div className="internationadd">
                      <p>
                        119415, Prospekt Vernadskogo 41,
                        <br /> Bld. 1, Office # 735
                        <br /> Moscow, Russian Federation 119415
                        <br /> Phone/Fax: +7 967 053 8883
                        <br /> Email :Vladimir.Kerdivarenko@elginps.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="internationdivisiondiv">
                    <div className="contryname">
                    <img src="images/india.png" alt="" /> 
                       INDIA</div>
                    <div className="internationadd">
                      <p>
                        B2 / 15 B.I.D.C. Estate <br />
                        Gorwa, Vadodara 390 016
                        <br /> Phone +91 832 023 7721
                        <br /> Email :Dev.Mehta@elginps.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="miningDivisionwrap">
              <div className="contactSubheading">Mining/Aggregate Division</div>
              <div className="row">
                <div className="col-md-4">
                  <div className="miningcard-box contactcard">
                    <span>Vibrating Screens / Fabrication Services</span>
                    <p>
                      1176 Shelter Road
                      <br />
                      Princeton, WV 24739 <br />
                      Phone: +1 304-755-0292 <br />
                      Phone: +1 304-431-4100
                      <br />
                      Fax: 304-431-4138 <br />
                      Email :
                      <a href="mailto:Tim.Lilly@elginps.com">
                        Tim.Lilly@elginps.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="miningcard-box contactcard">
                    <span>Polyurethane / Profile Wire Screens</span>
                    <p>
                      21405 Gov. G.C. Peery Hwy. <br />
                      Tazewell, VA 24651 <br />
                      Phone: +1 276-988-8901 <br />
                      Email :
                      <a href="mailto:Tim.Lilly@elginps.com">
                        Tim.Lilly@elginps.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="miningcard-box contactcard">
                    <span>HVC / HSC / Rotary Br /eakers</span>
                    <p>
                      5595 Highway 34 North <br />
                      Raleigh, IL 62977 <br />
                      Phone: +1 618-268-4850 or +1 314-776-2848 <br />
                      Email :
                      <a href="mailto:Rex.Eisenhauer@elginps.com">
                        Rex.Eisenhauer@elginps.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="miningcard-box contactcard">
                    <span>Coanda / Flat Panel / Cylyndrical Screens</span>
                    <p>
                      33 W. Waterloo St. <br />
                      Canal Winchester, OH 43110 <br />
                      Phone: +1 614-524-4588 <br />
                      Fax: +1 614-524-4586 <br />
                      Email :
                      <a href="mailto:Chad.Barber@elginindustries.com">
                        Chad.Barber@elginps.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
