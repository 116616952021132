import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../styles/pages/raise-ticket.css";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import {
  callBackendAPI,
  showError,
  showInfo,
  showMessage,
} from "../utils/helpers";
import {
  REST_API_METHODS,
  phoneRegExp,
  addressRegEx,
  Mobile_Max_Length,
  Mobile_Min_Length,
} from "../utils/constants";
import * as yup from "yup";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Error from "../components/common/Error";
import TitleSetter from "../components/common/TitleSetter";

import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import IsLoadingHOC from "../components/common/IsLoadingHOC";

const validationSchema = yup
  .object({
    first_name: yup.string().required("First name is required").max(60),
    last_name: yup.string().required("Last name is required").max(60),
    email: yup.string().email().required("Email is required"),
    company: yup.string().required("Company is required").max(60),
    phone: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact is required")
      .min(Mobile_Min_Length)
      .max(Mobile_Max_Length),
    address: yup
      .string()
      .matches(addressRegEx, "Address is not valid")
      .required("Address is required")
      .max(1000),
    state: yup.string().required("State is required").max(10),
    city: yup.string().required("City is required").max(10),
    pin_code: yup
      .string()
      .required("Pin code is required"),
    comment: yup.string(),
    assets: yup.string(),
  })
  .required();

const RequestQuote = (props) => {
  const { setLoading } = props;
  const history = useHistory();
  let { id } = useParams()

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const countryIsoCode = localStorage.getItem('country_code');
  const [countryCode, setCountryCode] = useState(countryIsoCode);
  const configurator = localStorage.getItem('configurator-summary');
  const [configuratorSummary] = useState(JSON.parse(configurator));

  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false);
  const [images] = useState(configuratorSummary?.finalConfigurator.map(item => item.name));

  useEffect(() => {
    if (!id || !configurator) {
      history.goBack();
    }
    setLoading(false);
  }, [id]);

  const onSubmit = handleSubmit(
    async (values) => {
      console.log("values", values);
      setLoading(true);
      try {
        if (!id) {
          showInfo("Something went wrong, Please try again..");
          return history.goBack();
        }
        const user = JSON.parse(localStorage.getItem("user"));
        values.user = user._id;
        values.assets = id;
        values.phone = "+" + values.phone;
        values.configuratorSummary = configuratorSummary;
        const response = await callBackendAPI(
          "requestQuote",
          REST_API_METHODS.POST,
          values
        );
        if (!!response.status) {
          showMessage(response.message);
          history.push('/categories-assets-groups');
        } else if (!response.status) {
          showError(response.message);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    },
    (errors) => {
      console.log("errors", errors);
    }
  );


  return (
    <>
      <TitleSetter title={"Request Quote"} />
      {/* <!-- Explore Section Start Here  --> */}
      <div className="explorewrap cart-wrap">
        <div className="container">
          <div className="explorecont setting_wrap requestform">
            <div className="cartPage-heading">
              <h3>Request Quote</h3>
              <div className="common_btn">
                <button onClick={onSubmit}>Submit</button>
              </div>
            </div>
            <div className="addaddresswrap request-form-wrap">
              <div className="row">
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="common_input_warp">
                        <label htmlFor="">First Name<span className="star-nec">*</span></label>
                        <div className="form-group">
                          <input
                            {...register("first_name")}
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="first_name"
                            autoComplete="off"
                            required=""
                          />
                          {!!errors.first_name && (
                            <Error message={errors.first_name.message} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="common_input_warp">
                        <label htmlFor="">Last Name<span className="star-nec">*</span></label>
                        <div className="form-group">
                          <input
                            {...register("last_name")}
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="last_name"
                            autoComplete="off"
                            required=""
                          />
                          {!!errors.last_name && (
                            <Error message={errors.last_name.message} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="common_input_warp">
                        <label htmlFor="">Email<span className="star-nec">*</span></label>
                        <div className="form-group">
                          <input
                            {...register("email")}
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            autoComplete="off"
                            required=""
                          />
                          {!!errors.email && (
                            <Error message={errors.email.message} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="common_input_warp">
                        <label htmlFor="">Company<span className="star-nec">*</span></label>
                        <div className="form-group">
                          <input
                            {...register("company")}
                            type="text"
                            className="form-control"
                            placeholder="Company"
                            name="company"
                            autoComplete="off"
                            required=""
                          />
                          {!!errors.company && (
                            <Error message={errors.company.message} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="common_input_warp">
                        <label htmlFor="">Phone No<span className="star-nec">*</span></label>
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="phone"
                            rules={{ required: true }}
                            render={({ field: { ref, ...field } }) => (
                              <ReactPhoneInput
                                {...field}
                                inputClass="form-control intl-tel-input-custom w-100"
                                inputExtraProps={{
                                  ref,
                                  required: true,
                                  autoFocus: true,
                                }}
                                isValid={(value, country) => {
                                  if (value.match(/12345/)) {
                                    return (
                                      "Invalid value: " +
                                      value +
                                      ", " +
                                      country.name
                                    );
                                  } else if (value.match(/1234/)) {
                                    return false;
                                  } else {
                                    return true;
                                  }
                                }}
                                country={countryCode}
                                countryCodeEditable={false}
                                specialLabel={"Player Mobile Number"}
                                enableSearch={true}
                              />
                            )}
                          />
                          {!!errors.phone && (
                            <Error message={errors.phone.message} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="common_input_warp">
                        <label htmlFor="">Address<span className="star-nec">*</span></label>
                        <div className="form-group">
                          <textarea
                            {...register("address")}
                            className="form-control"
                            placeholder="Address"
                            name="address"
                            id=""
                            cols="30"
                            rows="10"
                          ></textarea>
                          {!!errors.address && (
                            <Error message={errors.address.message} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="common_input_warp">
                        <label htmlFor="">State<span className="star-nec">*</span></label>
                        <div className="form-group">
                          <input
                            {...register("state")}
                            type="text"
                            className="form-control"
                            placeholder="Sate"
                            name="state"
                            autoComplete="off"
                          />
                          {!!errors.state && (
                            <Error message={errors.state.message} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="common_input_warp">
                        <label htmlFor="">City<span className="star-nec">*</span></label>
                        <div className="form-group">
                          <input
                            {...register("city")}
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name="city"
                            autoComplete="off"
                          />
                          {!!errors.city && (
                            <Error message={errors.city.message} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="common_input_warp">
                        <label htmlFor="">Pin Code<span className="star-nec">*</span></label>
                        <div className="form-group">
                          <input
                            {...register("pin_code")}
                            type="test"
                            className="form-control"
                            placeholder="Pin Code"
                            name="pin_code"
                            autoComplete="off"
                          />
                          {!!errors.pin_code && (
                            <Error message={errors.pin_code.message} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="common_input_warp">
                        <label htmlFor="">Comment</label>
                        <div className="form-group">
                          <textarea
                            {...register("comment")}
                            className="form-control"
                            placeholder="Write something here.."
                            name="comment"
                            id=""
                            cols="30"
                            rows="10"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-md-5 summarydetail-wrap">
                  <h4>Configurator Summary</h4>
                  <hr />
                  <div className="form-group dflex text-capitalize">
                    <label className="font-weight-bold">Category Name : </label><br />
                    <span className="word-wrap">{configuratorSummary?.category}</span>
                  </div>
                  <div className="form-group dflex text-capitalize">
                    <label className="font-weight-bold">Assets Group Name : </label><br />
                    <span className="word-wrap">{configuratorSummary?.machine}</span>
                  </div>
                  <div className="form-group dflex text-capitalize">
                    <label className="font-weight-bold">Assets Name :</label><br />
                    <span className="word-wrap">{configuratorSummary?.assets}</span>
                  </div>

                  <div className="configurator-summary">
                    {/* this section show for label image */}
                    {configuratorSummary?.labels.map((a, i) => (
                      <div className="summary-list" key={i}>
                        <label className="font-weight-bolder text-capitalize">{a.labelName}</label>

                        <img height={20} width={20} src={a.tagImage} />
                      </div>
                    ))}
                  </div>
                  <div className="configurator-summary configurator-view-img">
                    {/* this section for slider image */}
                    <label className="font-weight-bolder text-capitalize">Configurator Views</label>
                    {configuratorSummary?.finalConfigurator.map((a, i) => (
                      <img key={i} className="cursor-pointer" onClick={() => {
                        setIsOpen(true)
                        setPhotoIndex(i);
                      }} height={20} width={20} src={a.name} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setPhotoIndex(
              (photoIndex + images.length - 1) % images.length
            )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}


      </div>
      {/* <!-- Explore Section CSS End Here  --> */}
    </>
  );
};

export default IsLoadingHOC(RequestQuote);
