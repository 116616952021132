import React from "react";

const BaseWrapper = (props) => {
  const {
    backgroundImageURL: backgroundImage,
    FormComponent,
  } = props;
  
  return (
    <div
      className="logicardwrap"  >
      <div className="row">
      <div className="col-md-7">
          <div className="logincontent"  style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/login-new-bg.png'})`, backgroundRepeat: 'no-repeat'}}>
          {/* backgroundImageURL={`url(${process.env.PUBLIC_URL + "/images/loginbg.jpg"})`} */}
            <div className="logincontcard">
            <h2>Elgin Engine</h2>
            <p>
            Elgin Cloud Based Asset Management<br></br> for All Your Project Solutions
            </p>
            </div>
          </div>
        </div>
        <FormComponent />

        

      </div>
    </div>
  );
};

export default BaseWrapper;
