import { useEffect, useState } from 'react';

const useWeather = () => {
    const [lat, setLat] = useState('');
    const [long, setLong] = useState('');
    const [weather, setWeather] = useState([]);

    const fetchData = async () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setLat(position.coords.latitude);
            setLong(position.coords.longitude);
        });

        if (!!navigator && !!lat && !!long) {
            await fetch(`${process.env.REACT_APP_WEATHER_API_URL}/weather/?lat=${lat}&lon=${long}&units=metric&appid=${process.env.REACT_APP_WEATHER_API_KEY}`)
                .then(res => res.json())
                .then(result => {
                    setWeather(result?.main || {});
                });
        }
    }

    useEffect(() => {
        fetchData();
    }, [lat, long])

    return { weather, fetchData }
};

export default useWeather;
