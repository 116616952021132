import React from "react";
import { Redirect, Route } from "react-router-dom";

const PublicRoute = (props) => {
  const { component: Component, path, ...rest } = props;
  const shouldRender = props.computedMatch.path === path;

  if (!!localStorage.getItem("user")) return <Redirect to={"/"} />;

  if (!shouldRender) return null;

  return (
    <React.Fragment>
      <Route {...rest} component={Component} />
    </React.Fragment>
  );
};

export default PublicRoute;
