import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useScrollTop } from '../hooks';

import "../styles/pages/home.css";
import TitleSetter from "../components/common/TitleSetter";
import MoreButton from "../components/common/MoreButton";
import ImageGallery from "react-image-gallery";
import { viewSupport } from "../utils/helpers";
import useConfiguratorAndPredicative from "../hooks/useConfiguratorAndPredicative";

const Home = () => {
  const history = useHistory();
  const { status, fetchConfiguratorAndPredicative } = useConfiguratorAndPredicative();

  useScrollTop();

  const getAssetsSection = () => {
    return (
      <div className="explorewrap assetslinkswpap assets-box-wrap">
        <div className="container  clearfix">
          <div className="assets-type-wrap">
            <div className="assetslink-div assets-bg-first" onClick={() => history.push("/assets-list")}>
              <div className="assets-icon">
                <img src="images/Linked_Assets_Icons.png" alt="" />
              </div>
              <div className="assets-heading">
                Linked  Assets
              </div>
            </div>
            <div className="assetslink-div assets-bg-fifth" onClick={viewSupport}>
              <div className="assets-icon">
                <img src="images/Technical_Support_Icon.png" alt="" />
              </div>
              <div className="assets-heading">
                Technical Support
              </div>
            </div>
            <div className="assetslink-div assets-bg-third">
              <Link to={'/location'}>
                <div className="assets-icon">
                  <img src="images/Geo_Location_Icon.png" alt="" />
                </div>
                <div className="assets-heading">
                  Equipment Geo Location
                </div>
              </Link>
            </div>

            {!!status?.configuratorMain &&
              <div className="assetslink-div assets-bg-second">
                <Link to={'/categories-assets-groups'}>
                  <div className="assets-icon">
                    <img src="images/Asset_Config_Icon.png" alt="" />
                  </div>
                  <div className="assets-heading">
                    Asset configurator
                  </div>
                </Link>
              </div>
            }
            {!!status?.predictiveMain &&
              <div className="assetslink-div assets-bg-fourth">
                <Link to={'/predictive-maintenance'}>
                  <div className="assets-icon">
                    <img src="images/Predictive_Maintenance_Icon.png" alt="" />
                  </div>
                  <div className="assets-heading">
                    predictive maintenance
                  </div>
                </Link>
              </div>
            }
          </div>
        </div>
      </div>
    );
  };

  // const img = [
  //   { url: "/images/homebanner.jpg" },
  //   { url: "/images/CSI-D4-VCD_2020.png" },
  //   { url: "/images/ESS-1655-HYD-1-1024x634.jpg" },
  //   { url: "/images/ESS-1967XP-Polisher-3-1024x342.png" },
  //   { url: "/images/Hyper-G-Mud-Cleaner_2-1-1024x755.png" },
  //   { url: "/images/KEMTRON-600X-D-1024x678.jpg" },
  // ];

  const images = [
    // {
    //   original: '/images/banner.png',
    //   thumbnail: '/images/bannerimg1.png',
    // },
    // {
    //   original: '/images/bannerimg2.png',
    //   thumbnail: '/images/bannerimg2.png',
    // },
    // {
    //   original: '/images/bannerimg3.png',
    //   thumbnail: '/images/bannerimg3.png',
    // },
    // {
    //   original: '/images/bannerimg4.png',
    //   thumbnail: '/images/bannerimg4.png',
    // },
  ];

  const getExploreSection = () => {
    return (
      <div className="explorewrap-sec margin-tp-zero">
        <div className="covr-bgimg">
          <img src="images/line-bg.png" alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="explorecont">
                <h2>Explore Elgin’s Product Application</h2>
                <p>
                  Elgin Separation Solutions is an innovative turn-key solutions provider of natural resource recycling, liquid/solid separation, product classification, dewatering, waste management, and material handling operations that solve challenging real-world problems operators face today. We have brought together the industry’s leading specialists in the engineering, design, manufacture, commissioning, and servicing aspects to fully support your operational needs. Learn more about how our team can custom design the ideal system for you.
                </p>
                {/* <p>
                  Elgin offers complete solutions for liquid/solids separation,
                  dewatering, material classification, water intake, waste
                  management, and power distribution operations.
                </p> */}
              </div>
              <div className="more_btn">
                <MoreButton path={"/explore"} />
              </div>
            </div>
            <div className="col-md-5">
              <div className="imageswrap">
                <div className="row leftrightwrap">
                  <div className="image-div rightfirst-img">
                    <div className="imagewrap">
                      <svg xmlns="http://www.w3.org/2000/svg" width="112.921" height="112.984" viewBox="0 0 112.921 112.984">
                        <path id="recycle-management" d="M113.1,49.977l-11.67-4.42a53.774,53.774,0,0,0-4.244-10.432l5.128-11.316a1.614,1.614,0,0,0-.354-1.945L93.3,13.377a1.614,1.614,0,0,0-1.945-.354L80.036,18.151A44.581,44.581,0,0,0,69.6,13.907l-4.42-11.67A1.513,1.513,0,0,0,63.592,1H51.569a1.546,1.546,0,0,0-1.591,1.061l-4.42,11.67a53.774,53.774,0,0,0-10.432,4.244L23.809,12.846a1.614,1.614,0,0,0-1.945.354l-8.487,8.664a1.614,1.614,0,0,0-.354,1.945l5.128,11.316a35.972,35.972,0,0,0-4.244,10.432l-11.67,4.42A1.7,1.7,0,0,0,1,51.569V63.592a1.791,1.791,0,0,0,1.061,1.591l11.67,4.42a53.774,53.774,0,0,0,4.244,10.432L12.846,91.352A1.614,1.614,0,0,0,13.2,93.3l8.487,8.487a1.614,1.614,0,0,0,1.945.354L34.948,97.01a38.094,38.094,0,0,0,10.432,4.244l4.42,11.67a1.546,1.546,0,0,0,1.591,1.061H63.415a1.791,1.791,0,0,0,1.591-1.061l4.42-11.67A53.773,53.773,0,0,0,79.859,97.01l11.316,5.128a1.614,1.614,0,0,0,1.945-.354l8.487-8.487a1.614,1.614,0,0,0,.354-1.945L96.833,80.036A35.972,35.972,0,0,0,101.076,69.6l11.67-4.42a1.546,1.546,0,0,0,1.061-1.591V51.569A1.178,1.178,0,0,0,113.1,49.977ZM59.7,85.517A26.239,26.239,0,0,1,32.119,66.775L29.29,72.433a1.7,1.7,0,1,1-3.006-1.591l5.128-10.078a1.7,1.7,0,0,1,2.122-.707h.177l10.078,5.128A1.7,1.7,0,0,1,42.2,68.189l-7.249-3.713a22.958,22.958,0,0,0,24.4,17.681,1.691,1.691,0,1,1,.354,3.359Zm8.841-46.679-9.548,6.188a1.516,1.516,0,0,1-2.3-.53,1.572,1.572,0,0,1,.53-2.3l6.719-4.42a21.374,21.374,0,0,0-6.719-1.061A22.712,22.712,0,0,0,37.6,48.032a1.668,1.668,0,1,1-2.829-1.768A26.347,26.347,0,0,1,57.4,33.357a38.966,38.966,0,0,1,6.365.707l-3.536-5.3a1.572,1.572,0,0,1,.53-2.3,1.546,1.546,0,0,1,2.3.53l6.188,9.548a1.88,1.88,0,0,1-.707,2.3C68.719,38.661,68.543,38.661,68.543,38.838Zm13.968,41.9-11.316.53h-.177a1.852,1.852,0,0,1-1.768-1.591v-.177l-.53-11.316a1.682,1.682,0,0,1,3.359-.177l.354,7.957A22.689,22.689,0,0,0,74.908,45.2a1.768,1.768,0,0,1,2.829-2.122,25.9,25.9,0,0,1-1.768,34.3l6.365-.354a1.866,1.866,0,0,1,.177,3.713Z" transform="translate(-1 -1)" fill="#383838" />
                      </svg>

                      {/* <img src="images/waste.svg" alt="" /> */}
                      <p>Waste Management</p>
                    </div>
                  </div>
                  <div className="image-div rightsecond-img">
                    <div className="imagewrap">
                      <svg xmlns="http://www.w3.org/2000/svg" width="105.637" height="141.855" viewBox="0 0 105.637 141.855">
                        <g id="Group_262437" data-name="Group 262437" transform="translate(0 0)">
                          <path id="Subtraction_2" data-name="Subtraction 2" d="M53.266,141.855h0L0,52.567,53.267,0l52.37,52.567-52.37,89.288Zm-.318-135.2L6.288,53.476l46.66,46.047,46.4-46.047Z" transform="translate(0 0)" fill="#383838" />
                          <path id="Union_1" data-name="Union 1" d="M15.739,54.259l-.015,0v0C-7.9,51.183,2.1,29.941,2.1,29.941L16.848.275,16.854,0l.06.142L16.95.069l0,.149L29.694,30.166s9.685,21.016-13.94,24.09v0Z" transform="translate(36.825 26.703)" fill="#383838" />
                        </g>
                      </svg>

                      <p>Dewatering</p>
                    </div>
                  </div>
                  <div className="image-div rightthird-img">
                    <div className="imagewrap">
                      <svg xmlns="http://www.w3.org/2000/svg" width="128.979" height="85.615" viewBox="0 0 128.979 85.615">
                        <path id="Union_2" data-name="Union 2" d="M106.093,85.614,19.581,84.275a3.254,3.254,0,0,1-3.128-2.564L.073,5.771A3.252,3.252,0,0,1,6.43,4.4L22.265,77.813l81.343,1.261,18.964-76.6a3.251,3.251,0,1,1,6.311,1.563L109.3,83.146a3.25,3.25,0,0,1-3.155,2.469ZM98.869,71.319H27.694L16.974,12.2A44.83,44.83,0,0,1,27.318,6a25.87,25.87,0,0,1,4.447-1.367,20.135,20.135,0,0,1,4.024-.421,19.074,19.074,0,0,1,8.54,2.069,39.535,39.535,0,0,1,6.912,4.547c.492.384.979.771,1.45,1.146,3.69,2.934,6.877,5.467,10.422,5.467a9.4,9.4,0,0,0,6.089-2.6C81.852,4.133,84.339,3.979,88.46,3.724c.8-.048,1.62-.1,2.6-.229a6.012,6.012,0,0,1,.779-.047,15.388,15.388,0,0,1,5.534,1.368,48.5,48.5,0,0,1,5.958,3.01c3.709,2.165,6.677,4.358,6.708,4.378L98.869,71.319h0ZM79.989,51.086a6,6,0,1,0,6-6A6.009,6.009,0,0,0,79.989,51.086Zm-22.009,0a6,6,0,1,0,6-6A6.009,6.009,0,0,0,57.979,51.086Zm-22.008,0a6,6,0,1,0,6-6A6.009,6.009,0,0,0,35.972,51.086Z" transform="translate(0 0)" fill="#383838" />
                      </svg>

                      <p>Liquid/Solid Separation</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getBannerSection = () => {
    return (
      <div className="bannersection homebanner">
        <div className="banner-div">
          <img src="images/banner.jpg" alt="" className="ondesktop" />
          <img src="images/bannerphn.jpg" alt="" className="onphone" />
          {/* <ImageGallery
            items={images}
            showBullets={true}
            fullscreen={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showNav={false}
            showThumbnails={false}
            lazyLoad={true}
            autoPlay={true}
            slideDuration={3000}
            additionalClass="app-image-gallery"
          /> */}
          <div className="homebannercontent">
            <h2>Access To All Your Elgin Products For Complete Asset Management</h2>
            <p>
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry’s */}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <TitleSetter title={"Home"} />

      {getBannerSection()}

      {getAssetsSection()}

      {getExploreSection()}

    </div>
  );
};

export default Home;
