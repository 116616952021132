import React, { useState } from "react";
import * as yup from "yup";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { callBackendAPI, showMessage } from "../../utils/helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { REST_API_METHODS } from "../../utils/constants";
import Error from "../common/Error";

const validationSchema = yup
  .object({
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

const ResetPasswordForm = () => {
  const history = useHistory();
  const [apiError, setApiError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(
    async (values) => {
      setApiError("");
      const token = localStorage.getItem("otp-verification-token");
      const id = localStorage.getItem("otp-verification-user-id");

      if (!!id && !!token) {
        try {
          const response = await callBackendAPI(
            "resetPassword",
            REST_API_METHODS.POST,
            {
              id,
              token,
              password: values.password,
            }
          );
          if (!!response.status) {
            localStorage.removeItem("otp-verification-email");
            localStorage.removeItem("otp-verification-token");
            localStorage.removeItem("otp-verification-user-id");
            showMessage(response.message)
            history.push("/login");
          } else {
            setApiError(response.message);
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    (errors) => {
      console.log("errors", errors);
    }
  );

  return (
    <div className="col-md-5">
      <div className="logincardwrap">
        <div className="logincard resetpassword">
        <img src="images/logo_ergin.jpg" alt="" />
          <span>Reset Password</span>
          <form noValidate className="common_input_warp" onSubmit={onSubmit}>
            <div className="form-group">
              <input
                {...register("password")}
                type="password"
                className="form-control"
                placeholder="Enter Password"
                autoComplete="off"
                required=""
              />
              {!!errors.password && <Error message={errors.password.message} />}
            </div>
            <div className="form-group">
              <input
                {...register("confirmPassword")}
                type="password"
                className="form-control"
                placeholder="Re-Enter Password"
                autoComplete="off"
                required=""
              />
              {!!errors.confirmPassword && (
                <Error message={errors.confirmPassword.message} />
              )}
            </div>
            {!!apiError && <Error message={apiError} />}
            {/* <!-- <div className="forgotcontwrap forgotpass">
                          <a href="#">Back to login</a>
                </div> --> */}
            <div className="common_btn">
              <button type="submit">Reset Password</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
