import React from "react";
import BackButton from "../components/common/BackButton";

import "../styles/pages/faq.css";
import TitleSetter from "../components/common/TitleSetter";
import { FQAFeature } from '../components/settings/index';

const FAQ = () => {
  return (
    <>
      <TitleSetter title={"FAQs"} />
      <div className="explorewrap termscondition">
        <img src="images/left_bg.png" alt="" />
        <div className="container">
          <div className="explorecont setting_wrap">
            <div className="faqwrap">
              <FQAFeature />
            </div>
            <div className="">
              <BackButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FAQ;
