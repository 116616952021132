import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { callBackendAPI, showMessage, userInfo } from "../utils/helpers";
import { Mobile_Max_Length, Mobile_Min_Length, REST_API_METHODS } from "../utils/constants";
import "../styles/pages/notification.css";
import "../styles/pages/cart.css";
import Empty from "../components/common/Empty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import TitleSetter from "../components/common/TitleSetter";
import IsLoadingHOC from "../components/common/IsLoadingHOC";
import Error from "../components/common/Error";
import AddAddress from "./AddAddress";
import Modal from "../modal/Modal";

const todayDate = moment(new Date()).format("YYYY-MM-DD");
const validationSchema = yup
  .object({
    customer_name: yup.string().required(),
    phone: yup
      .string()
      .min(Mobile_Min_Length)
      .max(Mobile_Max_Length),
    email: yup.string().email().required(),
    requestDate: yup.string().required(),
    address: yup.string().required(),
    comment: yup.string().max(1000),

  }).required();

let userAddress = [];


const Cart = (props) => {
  const { setLoading, isLoading } = props;
  const history = useHistory();

  const [carts, setCart] = useState([]);
  const [address, setUserAddress] = useState([]);
  const [user] = useState(userInfo());
  const [show, setShow] = useState(false);
  const countryIsoCode = localStorage.getItem('country_code');
  const [countryCode, setCountryCode] = useState(countryIsoCode);

  const {
    register,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    getAddressList();
    getCartList();
  }, []);

  const getCartList = async (offset = 0) => {
    try {
      setLoading(true);
      const user = userInfo();
      const response = await callBackendAPI(
        `getCartList?userId=${user._id}`,
        REST_API_METHODS.GET
      );
      if (!!response.status) {
        setCart(response.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const removeCart = async (event, id) => {
    event.stopPropagation();
    setLoading(true);
    const response = await callBackendAPI(
      `removeCart?id=${id}`,
      REST_API_METHODS.DELETE
    );
    if (!!response.status) {
      let temp_state = [...carts];
      const index = temp_state.findIndex((a) => a._id == id);
      temp_state.splice(index, 1);
      setCart(temp_state);
      showMessage(response.message);
    }
    setLoading(false);
  };

  const removeCartItem = async (event, pIndex, cartId, partNumber) => {
    event.stopPropagation();
    setLoading(true);
    const response = await callBackendAPI(
      `removeCartItem?cartId=${cartId}&partNumber=${partNumber}`,
      REST_API_METHODS.DELETE
    );
    if (!!response.status) {
      getCartList();
      showMessage(response.message);
    } else {
      setLoading(false);
    }
  };

  const updatePartQuantity = async (event, index, cartId, par, type) => {
    let quantity;
    if (type === 'increment') {
      quantity = par.quantity += 1;
    } else {
      quantity = par.quantity -= 1;
    }

    if (type === 'decrement' && quantity < 0) {
      return;
    }

    const params = {
      id: cartId,
      partName: par.partName,
      quantity: quantity
    }

    event.target.parentElement.classList.add('loadershow');
    const response = await callBackendAPI(
      `updateCartQuantity`,
      REST_API_METHODS.PUT,
      params
    );

    if (response.status) {
      let tem_state = [...carts];
      let temp_part = { ...tem_state[index] }

      const pIndex = temp_part.parts.findIndex(p => p.partName === par.partName);
      if (pIndex >= 0) {
        temp_part.parts[pIndex].quantity = quantity;
        if (quantity <= 0) {
          temp_part.parts.splice(pIndex, 1);
        }
      }

      //cart and pard list modify
      if (!temp_part.parts.length) {
        tem_state.splice(index, 1);
      } else {
        tem_state[index] = temp_part;
      }

      setCart(tem_state);
      event.target.parentElement.classList.remove('loadershow');
    }
  }

  const onSubmit = handleSubmit(
    async (values) => {
      console.log('value', values)
      try {
        setLoading(true);
        const user = userInfo();
        const params = values;
        params.userId = user._id;

        const response = await callBackendAPI(
          `createTickets`,
          REST_API_METHODS.POST,
          params
        );
        if (!!response.status) {
          setCart([]);
          showMessage(response.message);
          history.push("/submitted-request");
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }

    },
    (errors) => {
      console.log("errors", errors);
      const isNumberValid = getValues('phone');
      if (!isNumberValid) {
        setError('phone', { type: 'manual', message: 'Phone number is required' });
      }
    }
  );


  const getAddressList = async () => {
    try {
      const response = await callBackendAPI(
        `getAddress?userId=${user._id}`,
        REST_API_METHODS.GET
      );
      if (!!response.status && !!response.data.length) {
        userAddress = response.data.sort((a, b) => a.createdAt - b.createdAt);
        setUserAddress(userAddress);
        const id = userAddress[0]._id;
        setValue("address", id);
        changeAddress(id);
      }
    } catch (e) {
      console.log(e);
    }
  };


  const changeAddress = (event) => {
    const id = event.target ? event.target.value : event ? event : 0;
    const data = userAddress.find((a) => a._id === id);
    let completeAddress = {};
    if (!!data) {
      completeAddress = data;
    }
    if (!!data) {
      setValue("address", data._id);
      // updateError("address");
    }
  };

  const addMoreParts = async (cart) => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getSerialList?userId=${user._id}`,
        REST_API_METHODS.GET
      );

      setLoading(false);
      if (!!response?.status && !!response.data) {
        const selected = response.data.find((a) => { if (a.serialNo === cart.serialNo && a?.assets?.name == cart.assets.name) return a });
        if (!!selected) {
          const state = {
            assId: selected._id,
            name: selected.assets.name,
            svgPath: selected.assets.svgPath,
          };
          localStorage.setItem("svg-assets", JSON.stringify(state));
          history.push("/svg-model");
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const closeModal = (event) => {
    getAddressList();
    setShow(false);
  };

  return (
    <>
      <TitleSetter title={"Cart"} />
      <div className="explorewrap termscondition cart-wrap">
        {/* <img src="images/left_bg.png" alt="" /> */}
        <div className="container">
          <div className="explorecont setting_wrap">
            <div className="cartPage-heading">
              <h3>My Cart</h3>
              {!!carts.length && <div className="common_btn">
                <button className="" type="submit" onClick={onSubmit}>Request a Quote</button>
              </div>}
            </div>
            <div className="noficationwrap">
              <ul>
                {carts.map((cart, index) => (
                  <li key={index} className="courser-pointer">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="cart-product-wrap">
                          <div className="deleticon onphone">
                            <FontAwesomeIcon
                              className="delete"
                              icon={faTrash}
                              color="red"
                              onClick={(event) => removeCart(event, cart._id)}
                            />
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="product-img">
                                <img src={cart?.assets?.images[0].url} alt="" />
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="productInfo-table">
                                <p className="text-capitalize">
                                  {cart?.assets?.name} - {cart?.serialNo}
                                </p>
                                <div className="table-responsive">
                                  <table>
                                    <tr>
                                      <th>Parts Name</th>
                                      <th>Parts No</th>
                                      <th>Quantity</th>
                                      <th>Action</th>
                                    </tr>
                                    {cart?.parts.map((par, i) => (
                                      <tr key={i}>
                                        <td>{par.partName}</td>
                                        <td><div className="partNumber-width">{par.partNumber}</div></td>
                                        <td>
                                          <div className="quantity-input">
                                            <button className="quantity-input__modifier quantity-input__modifier--left"
                                              onClick={(e) => updatePartQuantity(e, index, cart._id, par, 'decrement')}>
                                              &#8722;
                                            </button>
                                            <input
                                              className="quantity-input__screen"
                                              type="text"
                                              value={par?.quantity}
                                              readOnly
                                            />
                                            <button className="quantity-input__modifier quantity-input__modifier--right"
                                              onClick={(e) => updatePartQuantity(e, index, cart._id, par, 'increment')}>
                                              &#xff0b;
                                            </button>

                                            <div className="loader"></div>
                                          </div>
                                        </td>
                                        <td>
                                          <FontAwesomeIcon
                                            className="delete"
                                            icon={faTrash}
                                            color="red"
                                            onClick={(event) => removeCartItem(event, i, cart._id, par.partNumber)}
                                          />
                                        </td>
                                      </tr>))}
                                  </table>
                                </div>
                                <a href="javascript:void(0)" className="float-lg-right pt-4" onClick={() => addMoreParts(cart)}>Add more parts</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 ondesktop text-right">
                        <FontAwesomeIcon
                          className="delete"
                          icon={faTrash}
                          color="red"
                          onClick={(event) => removeCart(event, cart._id)}
                        />
                      </div>
                    </div>
                  </li>
                ))}

                {!carts.length && !isLoading && (
                  <>
                    <div className="emptycart-wrap">
                      <Empty message="Cart is empty." />
                      <div className="common_btn quotebbtn">
                        <button
                          onClick={() => {
                            history.push("/assets-list");
                          }}
                        >
                          Request a Quote
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </ul>
            </div>

            {!!carts.length && <div className="formwrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="cart-form-wrap">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            {...register("customer_name")}
                            type="text"
                            className="form-control"
                            placeholder="Customer Name"
                            name="customer_name"
                            autoComplete="off"
                          />
                          {!!errors.customer_name && (
                            <Error message={"Customer name is required"} />
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Controller
                            name="phone"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { ref, ...field } }) => (
                              <ReactPhoneInput
                                {...field}
                                inputClass="form-control intl-tel-input-custom w-100"
                                inputExtraProps={{
                                  ref,
                                  required: true,
                                  autoFocus: true,
                                }}
                                isValid={(value, country) => {
                                  if (value.match(/12345/)) {
                                    return (
                                      "Invalid value: " +
                                      value +
                                      ", " +
                                      country.name
                                    );
                                  } else if (value.match(/1234/)) {
                                    return false;
                                  } else {
                                    return true;
                                  }
                                }}
                                country={countryCode}
                                countryCodeEditable={false}
                                specialLabel={"Player Mobile Number"}
                                enableSearch={true}
                              />
                            )}
                          />
                          {!!errors.phone && (
                            <Error message={errors.phone.message} />
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            {...register("email")}
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            autoComplete="off"
                          />
                          {!!errors.email && (
                            <Error message={errors.email.message} />
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            {...register("requestDate")}
                            type="text"
                            onFocus={(e) => {
                              e.target.type = 'date'
                            }}
                            onBlur={(e) => {
                              e.target.type = 'text'
                            }}
                            className="form-control"
                            placeholder="Expected Delivery Date"
                            name="requestDate"
                            min={todayDate}
                            autoComplete="off"
                            onChange={(e) => {
                              setValue("requestDate", e.target.value);
                              // updateError("requestDate");
                            }}
                          />
                          {!!errors.requestDate && (
                            <Error message={"Date is required"} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="common_input_warp addaddressbtn">
                          <label htmlFor="">Address</label>
                          <Modal
                            title="New address"
                            onClose={() => setShow(false)}
                            show={show}
                            type="address"
                            width="700"
                          >
                            <AddAddress closeModal={closeModal} />
                          </Modal>
                          <a
                            className="float-lg-right"
                            href="javascript:void(0)"
                            onClick={() => setShow(true)}
                          >
                            New Address
                          </a>
                          <div className="form-group">
                            <select
                              {...register("address")}
                              className="classic"
                              name=""
                              id=""
                              disabled={!address.length}
                              onChange={changeAddress}
                              style={{
                                backgroundImage: `url(${process.env.PUBLIC_URL +
                                  "/images/down_arrow.png"
                                  })`,
                              }}
                            >
                              {address.map((ele, index) => (
                                <option key={index} value={ele._id}>
                                  {ele.first_name +
                                    ", " +
                                    ele.last_name +
                                    ", " +
                                    ele.address +
                                    ", " +
                                    ele?.city +
                                    ", " +
                                    ele?.state +
                                    ", " +
                                    ele?.pin_code}
                                </option>
                              ))}
                            </select>
                            {!!errors.address && (
                              <Error message={"Address is required"} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="cart-form-wrap">
                    <div className="form-group">
                      <textarea
                        {...register("comment")}
                        className="form-control"
                        placeholder="Add Special Comments/Requests Here..."
                        name="comment"
                        id=""
                      ></textarea>
                      <small>If the part being ordered is different from what you want, please provide details in the comment section above.</small>
                      {!!errors.comment && (
                        <Error message={"Comment is required"} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>}

          </div>
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(Cart);
