import { useEffect, useState } from "react";
import {
  callBackendAPI,
  convertDateAndTime,
  showMessage,
  userInfo,
} from "../utils/helpers";
import { REST_API_METHODS } from "../utils/constants";
import "../styles/pages/notification.css";
// import { onMessageListener } from "../utils/firebase";
import Empty from "../components/common/Empty";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import TitleSetter from "../components/common/TitleSetter";
import IsLoadingHOC from "../components/common/IsLoadingHOC";

const Notification = (props) => {
  const { setLoading, isLoading } = props;

  const [notifications, setNotifications] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    getNotifications();
  }, []);

  // onMessageListener().then((message) => {
  //   getNotifications();
  // });

  const getNotifications = async (offset = 0) => {
    try {
      setLoading(true);
      const user = userInfo();
      const response = await callBackendAPI(
        `getNotificationList?userId=${user._id}&skip=${offset}&limit=5`,
        REST_API_METHODS.GET
      );
      if (!!response.status) {
        setNotifications(response.data);
        setPageCount(Math.ceil(response.totalItems / 5));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    getNotifications(newOffset);
    setItemOffset(newOffset);
  };

  const deleteNotification = async (id) => {
    const response = await callBackendAPI(
      `deleteNotification?id=${id}`,
      REST_API_METHODS.DELETE
    );
    if (!!response.status) {
      getNotifications();
      showMessage(response.message);
      setItemOffset(0);
    }
  };

  // const getHtmlBody = (notify) => {
  //   return `<b>Ticket id</b> ${notify._id} <br /> <b>Ticket type</b> ${
  //     notify.type.name
  //   } <br /> ${notify.part ? `<b>Part name</b> ${notify.part}` : ""}`;
  // };

  return (
    <>
      <TitleSetter title={"Notification"} />
      <div className="notification-page-wrap">
        {/* <img src="images/left_bg.png" alt="" /> */}
        <div className="container">
          <div className="noficationwrap">
            <div className="page-heading">Notifications</div>
            <div className="noficationlist">
              <ul>
                {notifications.map((notification, index) => (
                  <li key={index}>
                    <div className="notificationlistdiv">
                      <div className="noticontent">
                        <div className="notiIcon">
                          <img src="images/Notificationbell.png" alt="" />
                        </div>
                        <div className="noticont">
                          <p className="text-capitalize">
                            {notification.title}
                          </p>
                          <span
                            className="text-capitalize word-break"
                            dangerouslySetInnerHTML={{
                              __html: notification.body,
                            }}
                          ></span>
                          <span className="notitime">
                            | {convertDateAndTime(notification.createdAt)}
                          </span>
                        </div>
                      </div>
                      <div className="noti-delet">
                        {notification.type !== "" && (
                          <span className="text-success notificationType">
                            {notification.type}
                          </span>
                        )}
                        <FontAwesomeIcon
                          className="delete"
                          icon={faTrash}
                          color="red"
                          onClick={() => deleteNotification(notification._id)}
                        />
                      </div>
                    </div>

                    {/* <p className="text-capitalize">{notification.title}</p>
                    {notification.body && (
                      <span
                        className="text-capitalize word-break"
                        dangerouslySetInnerHTML={{
                          __html: notification.body,
                        }}
                      ></span>
                    )}
                    <br />
                    <span>{convertDateAndTime(notification.createdAt)}</span>
                    <FontAwesomeIcon
                      className="delete"
                      icon={faTrash}
                      color="red"
                      onClick={() => deleteNotification(notification._id)}
                    /> */}

                  </li>
                ))}

                {!notifications.length && !isLoading && (
                  <Empty message="No notification" />
                )}
              </ul>
            </div>
          </div>
          <ReactPaginate
            className="pagination"
            breakLabel="..."
            nextLabel="Next"
            previousLabel="Previous"
            pageCount={pageCount}
            pageRangeDisplayed={3}
            renderOnZeroPageCount={null}
            onPageChange={handlePageClick}
            forcePage={itemOffset}
          />
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(Notification);
