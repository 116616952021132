import _ from "lodash";
import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import IsLoadingHOC from "../components/common/IsLoadingHOC";
import { REST_API_METHODS } from "../utils/constants";
import { callBackendAPI } from "../utils/helpers";

const NotificationManagement = (props) => {
    const { setLoading, isLoading } = props;
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = async (offset = 0) => {
        try {
            setLoading(true);
            const response = await callBackendAPI(
                `notificationMange`,
                REST_API_METHODS.GET
            );
            if (!!response.status) {
                const data = Object.entries(_.omit(response.data, '_id')).map(([key, value]) => {
                    return { key, value };
                });

                setNotifications(data);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    const handleChange = async (checked, index, key) => {
        let temp_state = [...notifications];
        temp_state[index].value = checked;
        setNotifications(temp_state);

        let payload = {};
        payload[key] = checked;

        await callBackendAPI(
            `updateNotification`,
            REST_API_METHODS.PUT,
            payload
        );
    }

    return (
        <>
            <h2>Notification Settings</h2>
            <ul>
                {notifications.map((item, index) => (
                    <li key={index}>
                        <span className="text-capitalize pr-5">{item.key} Notification</span>
                        <Switch onChange={(event) => handleChange(event, index, item.key)} checked={item.value} />
                    </li>
                ))}
            </ul>
        </>
    )
}

export default IsLoadingHOC(NotificationManagement);