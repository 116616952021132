import React from "react";

const TermsConditionsFeature = ({ title, description }) => {
    return (
        <div className="faqwrap">
            <h2>{title}</h2>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
    )
}
export default TermsConditionsFeature;