// Autocomplete.js
import React, { useEffect } from 'react';
import { getLocality } from '../../utils/helpers';
import Error from './Error';

const AutoComplete = ({ setLoading, register, errors, setAddress, index = 0, forMulti = false }) => {

    useEffect(() => {
        setTimeout(() => {
            initAutocomplete();
        }, 3000);
    }, []);

    const initAutocomplete = () => {
        // Create the search box and link it to the UI element.
        const input = document.getElementById(`pac-input-${index}`);
        const searchBox = new window.google.maps.places.SearchBox(input);

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }
            setLoading(true);
            const lat = places[0].geometry.location.lat();
            const lng = places[0].geometry.location.lng();
            getLocality(lat, lng).then((response) => {
                if (!!response) {
                    setAddress(response, index);
                }
                setLoading(false);
            });
        });
    };

    return (
        <div>
            {!forMulti ?
                <>
                    <input
                        {...register("address")}
                        className="form-control"
                        type="text"
                        id={`pac-input-${index}`}
                        name="address"
                        placeholder="Enter Address"
                    />
                    {!!errors.address && (
                        <Error message={errors.address.message} />
                    )}
                </> :
                <>
                    <input
                        {...register(`machines[${index}].address`, {
                            required: "This is required message",
                        })}
                        type="text"
                        id={`pac-input-${index}`}
                        name={`machines[${index}].address`}
                        autoComplete="off"
                        className="form-control"
                        placeholder="Enter Address"
                    />
                    {!!errors.machines?.[index]?.address && (
                        <Error
                            message={
                                errors.machines?.[index]?.address?.message
                            }
                        />
                    )}
                </>
            }
        </div >
    );
}

export default AutoComplete;