/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const AddPart = () => {
  let { id } = useParams();

  return (
    <div id="container">
      <span>Add Part successfully for this {id}</span>
    </div>
  );
};

export default AddPart;
