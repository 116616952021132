import { Link } from "react-router-dom"
import useConfiguratorAndPredicative from "../../hooks/useConfiguratorAndPredicative";
import { viewSupport } from "../../utils/helpers";

const Footer = () => {
  const { status, fetchConfiguratorAndPredicative } = useConfiguratorAndPredicative();

  return (
    <div>
      <div className="footerwrap">
        <div className="container">
          <div className="footer-linkwrap">
            <div className="row">
              <div className="col-6 col-sm-3 col-md-3">
                <div className="footer-link footer-menu">
                  <p>Company</p>
                  <ul className="quick-link">
                    <li>
                      <Link to={'/contact-us'}>Contact us</Link>
                    </li>
                    <li>
                      <Link to={'/about-us'}>About us</Link>
                    </li>
                    <li>
                      <Link to={'/terms-and-conditions'}>Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to={'/privacy-policy'}>Privacy Policy</Link>
                    </li>
                    <li>
                      <a href="javascript:void(0)" onClick={viewSupport}>Technical Support</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-sm-3 col-md-3">
                <div className="footer-link footer-menu">
                  <p>Quick Links</p>
                  <ul className="quick-link">
                    <li>
                      <Link to={'/assets-list'}>Linked Assets</Link>
                    </li>
                    <li>
                      <Link to={'/location'}>Equipment Geo Location</Link>
                    </li>
                    {!!status?.configuratorMain &&
                      <li>
                        <Link to={'/categories-assets-groups'}>Asset Configurator</Link>
                      </li>
                    }
                    {!!status?.predictiveMain &&
                      <li>
                        <Link to={'/predictive-maintenance'}>Predictive Maintenance</Link>
                      </li>
                    }
                  </ul>
                </div>
              </div>
              <div className="col-6 col-sm-3 col-md-3">
                <div className="footer-link footer-menu">
                  <p>Resources</p>
                  <ul className="quick-link">
                    <li>
                      <Link to={'/faq'}>FAQs</Link>
                    </li>
                    <li>
                      <Link to={'/explore'}>Explore</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-sm-3 col-md-3">
                <div className="footer-link ">
                  <p>Connect</p>
                  <div className="socialicon">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/elginseparationsolutions/" target="_blank" rel="noreferrer">
                          <img src="/images/facebook.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/c/Elginseparationsolutions2015/featured" target="_blank" rel="noreferrer">
                          <img src="/images/youtube.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/elgin-separation-solutions" target="_blank" rel="noreferrer">
                          <img src="/images/linkedin.svg" alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="mailtext">
                    <span>
                      Mail us: <a href="mailto:info@elgin-ai.com">info@elgin-ai.com</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright_section">
            <p>Copyright 2021 - Elgin Separation Solutions</p>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Footer;
