import React from "react";
import { HashRouter, Switch } from "react-router-dom";

import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import ForgotPassword from "../pages/ForgotPassword";
import OTPVerification from "../pages/OTPVerification";

import AddPart from "../pages/AddPart";
import SVGModel from "../pages/SVGModel";

import FAQ from "../pages/FAQ";
import AboutUs from "../pages/AboutUs";
import Settings from "../pages/Settings";
import TermsAndConditions from "../pages/TermsAndConditions";

import MyProfile from "../pages/MyProfile";
import AddAddress from "../pages/AddAddress";
import ChangePassword from "../pages/ChangePassword";

import Home from "../pages/Home";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import AssetsList from "../pages/AssetsList";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import AssetsDetails from "../pages/AssetsDetails";
import RaiseTicket from "../pages/RaiseTicket";
import MySubmittedRequest from "../pages/MySubmittedRequest";
import Explore from "../pages/Explore";
import RequestQuote from "../pages/RequestQuote";
import MaintenanceAlerts from "../pages/MaintenanceAlerts";
import Notification from "../pages/Notification";
import ContactUs from "../pages/ContactUs";
import Cart from "../pages/Cart";
import NotificationManagement from "../pages/NotificationManagement";
import UserManagement from "../pages/user-management/UserManagement";
import UserForm from "../pages/user-management/UserForm";
import AssetsLinked from "../pages/user-management/AssetsLinked";
import AssetsForm from "../pages/user-management/AssetsForm";
import AssetsConfigurator from "../pages/configurator-management/AssetsConfigurator";
import GeoLocation from "../pages/GeoLocation";
import PredictiveMaintenance from "../pages/predictive-maintenance/PredictiveMaintenance";
import PredictiveMaintenanceView from "../pages/predictive-maintenance/PredictiveMaintenanceView";
import MySubmittedDetails from "../pages/MySubmittedDetails";
import { Role } from "../auth-helpers";
import UserLocations from "../pages/UserLocations";
import Configurator from "../pages/configurator-management/Configurator";
import AssetsConfiguratorList from "../pages/configurator-management/AssetsConfiguratorList";

const AppRouter = () => {
  // const basename = process.env.PUBLIC_URL;

  return (
    <HashRouter>
      <Switch>
        <PrivateRoute exact={true} path={"/svg-model"} component={SVGModel} />
        <PrivateRoute
          exact={true}
          path={"/buy-now/:id/:partNumber"}
          component={SVGModel}
        />
        <PrivateRoute exact={true} path={"/add-part/:id"} component={AddPart} />

        <PublicRoute exact={true} path={"/login"} component={Login} />
        <PublicRoute
          exact={true}
          path={"/forgot-password"}
          component={ForgotPassword}
        />
        <PublicRoute
          exact={true}
          path={"/otp-verification"}
          component={OTPVerification}
        />
        <PublicRoute
          exact={true}
          path={"/reset-password"}
          component={ResetPassword}
        />

        <PrivateRoute exact={true} path={"/about-us"}
          component={AboutUs}
        />
        <PrivateRoute
          exact={true}
          path={"/terms-and-conditions"}
          component={TermsAndConditions}
        />
        <PrivateRoute
          exact={true}
          path={"/privacy-policy"}
          component={PrivacyPolicy}
        />
        <PrivateRoute exact={true} path={"/faq"}
          component={FAQ}
        />

        <PrivateRoute exact={true} path={"/"} innerHeaderShow={false} component={Home} />
        <PrivateRoute exact={true} path={"/my-profile"}
          component={MyProfile}
        />
        <PrivateRoute
          exact={true}
          path={"/add-address"}
          component={AddAddress}
        />
        <PrivateRoute
          exact={true}
          path={"/edit-address/:id"}
          component={AddAddress}
        />
        <PrivateRoute
          exact={true}
          path={"/change-password"}
          component={ChangePassword}
        />
        <PrivateRoute exact={true} path={"/settings"}
          component={Settings}
        />
        <PrivateRoute
          exact={true}
          path={"/assets-list"}
          component={AssetsList}
        />
        <PrivateRoute
          exact={true}
          path={"/assets-details/:id"}
          component={AssetsDetails}
        />
        <PrivateRoute
          exact={true}
          path={"/raise-ticket/:id/:edit_type"}
          component={RaiseTicket}
        />
        <PrivateRoute
          exact={true}
          path={"/raise-ticket-cart"}

        />
        <PrivateRoute
          exact={true}
          path={"/submitted-request"}
          component={MySubmittedRequest}
        />
        <PrivateRoute
          exact={true}
          path={"/submitted-request/:id"}
          component={MySubmittedDetails}
        />
        <PrivateRoute
          exact={true}
          path={"/explore"}
          component={Explore}
        />
        <PrivateRoute
          exact={true}
          path={"/request-quote/:id"}
          component={RequestQuote}
        />
        <PrivateRoute
          exact={true}
          path={"/maintenance-alert"}
          component={MaintenanceAlerts}
        />
        <PrivateRoute
          exact={true}
          path={"/notification"}
          component={Notification}
        />
        <PrivateRoute exact={true} path={"/contact-us"}
          component={ContactUs}
        />

        <PrivateRoute
          exact={true}
          path={"/cart"}
          component={Cart}
        />

        <PrivateRoute
          exact={true}
          path={"/notification-management"}
          component={NotificationManagement}
        />

        <PrivateRoute
          exact={true}
          path={"/location"}
          component={UserLocations}
        />

        <PrivateRoute
          exact={true}
          path={"/location/:lat/:long"}
          component={UserLocations}
        />

        <PrivateRoute
          exact={true}
          path={"/user-management"}
          roles={Role.Assign}
          component={UserManagement}
        />
        <PrivateRoute
          exact={true}
          path="/user-management/new"
          roles={Role.Assign}
          component={UserForm}
        />
        <PrivateRoute
          exact={true}
          path="/user-management/edit/:id"
          roles={Role.Assign}
          component={UserForm}
        />
        <PrivateRoute
          exact={true}
          path="/assets-linked/:id"
          roles={Role.Assign}
          component={AssetsLinked}
        />
        <PrivateRoute
          exact={true}
          path="/assets-link/new/:id"
          roles={Role.Assign}
          component={AssetsForm}
        />
        <PrivateRoute
          exact={true}
          path="/assets-link/edit/:id"
          roles={Role.Assign}
          component={AssetsForm}
        />
        <PrivateRoute
          exact={true}
          path="/geo-location"
          component={GeoLocation}
        />
        <PrivateRoute
          exact={true}
          path="/predictive-maintenance"
          component={PredictiveMaintenance}
        />
        <PrivateRoute
          exact={true}
          path="/predictive-maintenance-view/:id/:serialNo"
          component={PredictiveMaintenanceView}
        />
        <PrivateRoute
          exact={true}
          path="/categories-assets-groups"
          component={AssetsConfigurator}
        />
        <PrivateRoute
          exact={true}
          path="/configurator-assets/:categoryId/:machineId"
          component={AssetsConfiguratorList}
        />
        <PrivateRoute
          exact={true}
          path="/configurator/:assetsName/:categoryId/:machineId"
          component={Configurator}
        />
      </Switch>
    </HashRouter>
  );
};

export default AppRouter;
