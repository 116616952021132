import React, { useEffect, useState } from "react";
import { REST_API_METHODS } from "../../utils/constants";
import { callBackendAPI, userInfo } from "../../utils/helpers";

const SerialNumbersModal = ({ assetsName, svgLoad, closeModal }) => {
    const [serialNumberList, setSerialNumberList] = useState([]);
    const [selectAssets, setSelectAssets] = useState({});
    const user = userInfo();

    useEffect(() => {
        if (assetsName) {
            getSerialList();
        }
    }, [assetsName]);

    const getSerialList = async () => {
        try {
            const response = await callBackendAPI(
                `getSerialList?userId=${user._id}`,
                REST_API_METHODS.GET
            );

            if (!!response?.status && !!response.data) {
                const defaultSerial = response.data.filter((a) => a?.assets?.name == assetsName);
                const selected = defaultSerial[0];
                const state = {
                    assId: selected._id,
                    name: selected.assets.name,
                    svgPath: selected.assets.svgPath,
                };
                setSelectAssets(state);
                setSerialNumberList(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const viewAssetsDetailsHandler = (event) => {
        const id = event.target.value;
        const serial = serialNumberList.find(a => a._id == id);
        let temp_state = { ...selectAssets };
        const state = {
            assId: serial._id,
            name: serial.assets.name,
            svgPath: serial.assets.svgPath,
        };
        temp_state = state;
        setSelectAssets(temp_state);
    }

    return (
        <div className="serialnumberpop-wrap">
            <select
                className="form-control"
                onChange={viewAssetsDetailsHandler}
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL +
                        "/images/down_arrow.png"
                        })`,
                }}
            >
                {serialNumberList.filter(
                    (a) =>
                        a?.assets?.name == assetsName
                ).map((serial, index) => (
                    <option key={index} value={serial._id}>
                        {serial.serialNo}
                    </option>
                ))}
            </select>
            <div className="common_btn">
                <button onClick={() => svgLoad(selectAssets)}>Set</button>
                <button onClick={closeModal}>Cancel</button>
            </div>
        </div>
    )
}

export default SerialNumbersModal;