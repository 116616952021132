import { useEffect, useState } from 'react';
import { userInfo } from '../utils/helpers';

const usePrime = () => {
    const [isPrime, setPrimeMamber] = useState(false);
    const user = userInfo();

    useEffect(() => {
        const isPrime = user?.role.some(a => a.name == 'user');
        setPrimeMamber(isPrime);
    }, []);

    return { isPrime }
};

export default usePrime;
