import React from "react";

import BackButton from "../components/common/BackButton";
import TitleSetter from "../components/common/TitleSetter";
import { ChangePasswordFeature } from "../components/settings";

const ChangePassword = () => {
  return (
    <>
      <TitleSetter title={"Change Password"} />
      <div className="explorewrap termscondition">
        <img src="images/left_bg.png" alt="" />
        <div className="container">
          <div className="explorecont setting_wrap">
            <ChangePasswordFeature />
            <div className="">
              <BackButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
