import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import IsLoadingHOC from "../../components/common/IsLoadingHOC";
import TitleSetter from "../../components/common/TitleSetter";
import { REST_API_METHODS } from "../../utils/constants";
import { callBackendAPI } from "../../utils/helpers";

const AssetsConfiguratorList = (props) => {
  const { setLoading } = props;
  const { categoryId, machineId } = useParams();
  const history = useHistory();

  const [heading, setHeading] = useState({});
  const [configureAssets, setConfigureAssets] = useState([]);

  useEffect(() => {
    getConfiguratorAssets();
  }, []);

  const getConfiguratorAssets = async () => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getConfiguratorAssets?categoryId=${categoryId}&machineId=${machineId}`,
        REST_API_METHODS.GET
      );
      if (!!response?.status && response.data?.length) {
        setHeading({
          category: response.data[0].category.name,
          machine: response.data[0].machine.name
        })
        setConfigureAssets(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const viewHandler = (name) => {
    history.push('/configurator/' + name + '/' + categoryId + '/' + machineId);
  }

  return (
    <div>
      <TitleSetter title="Asset Configurator" />
      <div className="configuraotorlist-wrap">
        <div className="configuraotorlist-heading">
          <div className="container">
            {heading?.category && <h4>
              {heading?.category} {" > "}
              <span>{heading?.machine}</span>
            </h4>}
          </div>
        </div>
        <div className="assets-machine-wrap">
          <ul>
            {configureAssets.map((asset, index) => (
              <li key={index}>
                <div className="assets-machine-box" key={index}>
                  <div className="machine-img-big">
                    <img src={asset?.assetsImage} alt="" />
                  </div>
                  {/* <div className="col-md-6">
                      <div className="machine-blur">
                        <img src={asset?.assetsImage} alt="" />
                      </div>
                    </div> */}
                  <div className="assets-machine-name">
                    <p>{asset.assets}</p>
                    <div className="configurbtn">
                      <button onClick={() => viewHandler(asset.assets)}>Configure Now</button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default IsLoadingHOC(AssetsConfiguratorList);
