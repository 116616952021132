import React, { useEffect, useRef, useState } from "react";
import IsLoadingHOC from "../../components/common/IsLoadingHOC";
import Chart from "react-apexcharts";
import $ from "jquery";

import {
  callBackendAPI,
  convertDateForGraph,
  convertDateToMillisecondsWithoutTime,
  getCurrentTime,
  removeDays,
  showMessage,
  userInfo,
} from "../../utils/helpers";
import { REST_API_METHODS } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { DateRange } from "react-date-range";
import TitleSetter from "../../components/common/TitleSetter";
import Modal from "../../modal/Modal";
import { SetWeekHrs } from "../../components/modals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBellSlash, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import SetApproximateWeekHrs from "../../components/common/SetApproximateWeekHrs";

const PredictiveMaintenanceView = (props) => {
  const { setLoading } = props;
  const { id, serialNo } = useParams();
  const childRef = useRef();

  let maintenanceId = 0;

  const columOptions = {
    chart: {
      height: 350,
      type: 'line',
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    // forecastDataPoints: {
    //   count: 7
    // },
    stroke: {
      width: 5,
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: [],
      tickAmount: 10,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    // title: {
    //   text: 'Forecast',
    //   align: 'left',
    //   style: {
    //     fontSize: "16px",
    //     color: '#666'
    //   }
    // },
    fill: {
      type: 'gradient',
      // gradient: {
      //   shade: 'dark',
      //   gradientToColors: ['#FDD835'],
      //   shadeIntensity: 1,
      //   type: 'horizontal',
      //   opacityFrom: 1,
      //   opacityTo: 1,
      //   stops: [0, 100, 100, 100]
      // },
    },
    yaxis: {
      min: 0,
      max: 40
    }
  };

  // circle config
  var optionsCircle = {
    chart: {
      type: "radialBar",
      animations: {
        enabled: true,
      },
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: true,
            fontSize: "14px",
            fontFamily: undefined,
            fontWeight: 400,
            color: undefined,
            offsetY: -56,
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    },
    fill: {
      colors: [
        function ({ value, seriesIndex, w }) {
          if (value < 50) {
            return "#00FF00";
          } else if (value >= 50 && value < 90) {
            return "#FFFF00";
          } else {
            return "#FF0000";
          }
        },
      ],
    },
    labels: [],
  };

  const currentDate = new Date();
  const addedStartDate = currentDate.setDate(currentDate.getDate() - 1);
  const addedEndDate = currentDate.setDate(currentDate.getDate() - 7);
  const [show, setShow] = useState(false);
  const user = userInfo();

  var today = new Date();
  var yesterdayDate = new Date(today);
  yesterdayDate.setDate(today.getDate() - 1);
  yesterdayDate.toLocaleDateString();

  const [predictiveMaintenance, setPredictiveMaintenance] = useState({});
  const [columSeriesOptions, setColumSeriesOptions] = useState({});
  const [assetsDetails, setAssetsDetails] = useState({});
  const [columSeries, setColumSeries] = useState([]);
  const [upcomingAlerts, setUpComingAlerts] = useState([]);
  const [selectPart, setSelectPart] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const [filterDate, setFilterDate] = useState([
    {
      startDate: addedStartDate,
      endDate: addedEndDate,
      key: "selection",
    },
  ]);

  let startDate = addedStartDate;
  let endDate = addedEndDate;

  useEffect(() => {
    getPredictiveMaintenance();
  }, []);

  const getPredictiveMaintenance = async () => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getPredictiveMaintenanceDetails?id=${id}&serialNo=${serialNo}`,
        REST_API_METHODS.GET
      );

      if (!!response.status) {
        const parts = response.data.parts;
        const comingMaintenance = parts.filter(a => a.percentage >= 90);

        setPredictiveMaintenance(response.data);
        setAssetsDetails(response.data);
        setUpComingAlerts(comingMaintenance);
        if (!!response.data && !!response.data.parts.length) {
          const part = response.data.parts[0];
          getAssetsPartsGraphData('', part, startDate, endDate);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getAssetsPartsGraphData = async (e, item, startDate, endDate) => {
    if (e) {
      e.stopPropagation();
    }

    setSelectPart(item);
    try {
      setLoading(true);
      const end = convertDateToMillisecondsWithoutTime(startDate);
      const start = convertDateToMillisecondsWithoutTime(endDate);

      const response = await callBackendAPI(
        `getAssetsPartsGraphData?partId=${item._id}&userId=${user._id}&startDate=${start}&endDate=${end}`,
        REST_API_METHODS.GET
      );

      if (!!response.status) {
        const data = response.data;
        const newArray = [];
        const dateData = [];

        for (let index = 6; index >= 0; index--) {
          const currentDate = new Date().getTime();
          const nDate = removeDays(startDate, index);

          const convertDate = convertDateForGraph(nDate);
          const previousDate = convertDateForGraph(nDate);

          const findData = data.find((a) => a.createdAt === previousDate);
          if (!!findData) {
            newArray.push(findData.everyDayRunningHrs);
          } else {
            newArray.push(0);
          }
          dateData.push(convertDate);
        }

        const series = [
          {
            name: "Hours",
            data: newArray
          }
        ];
        setColumSeries(series);
        const maxNum = Math.max(...newArray);
        setColumSeriesOptions((state) => {
          state = columOptions;
          // state.plotOptions.bar.columnWidth = optimalColumnWidthPercent;
          state.xaxis.categories = dateData;
          state.yaxis.max = maxNum + 10;
          return state;
        });
      } else {
        setColumSeries([]);
        setColumSeriesOptions([]);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  const dateHandler = (item) => {
    let temp = [...filterDate];
    var date = new Date(item.selection.startDate);
    date.setDate(date.getDate() - 6);

    startDate = item.selection.startDate;
    endDate = date;

    temp[0].startDate = startDate;
    temp[0].endDate = endDate;
    setFilterDate(temp);

    setModalOpen(false);
    getAssetsPartsGraphData('', selectPart, startDate, endDate);
  };

  const setWeekHrsModal = (e, asset) => {
    e.stopPropagation();
    setShow(true);
  };

  const setWeekHrs = async (hours) => {
    try {
      setLoading(true);
      const params = {
        id: assetsDetails._id,
        weekHrs: hours,
      };
      const response = await callBackendAPI(
        `setWeekHrs`,
        REST_API_METHODS.PUT,
        params
      );
      if (response.status) {
        let temp_state = { ...assetsDetails };
        temp_state.weekHrs = hours;
        setAssetsDetails(temp_state);
      }
      setLoading(false);
      closeModal();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShow(false);
  };

  const appendParts = (item) => {
    const hrs = item.partRunningHrs + item.extraRunningHrs;
    const mDayHrs = parseInt(item?.part?.maintenance_days) * 24;
    const options = Object.assign({}, optionsCircle);
    // options.labels = [`Maintenance Cycle ${hrs + "/" + mDayHrs + " Hrs"}`];
    return (
      <>
        <div className="pard-card">
          <h6>{item?.part?.name}</h6>
          <span style={item?.percentage > 90 ? { color: 'red' } : {}}>
            {hrs ?
              (mDayHrs - hrs) < 0 ?
                -(mDayHrs - hrs) + ` hours is exceeded for the maintenance` :
                (mDayHrs - hrs) + ' hours remaining for next maintenance' :
              mDayHrs + ` hours remaining for next maintenance`
            }
          </span>
          <FontAwesomeIcon
            className="info-icon"
            color="#1e3e7b"
            icon={faInfoCircle}
            title={item?.part?.description || 'N/A'}
          />
        </div>
        <Chart
          id="dest"
          options={options}
          series={[item?.percentage || 0]}
          type="radialBar"
          // width={500}
          height={280}
        />
        <div className="maintinance-info-text">
          <span>Maintenance Cycle</span>
          <p>{hrs + "/" + mDayHrs + " Hrs"}</p>
        </div>
        <button
          className="reset-btn"
          onClick={(e) => { resetModalOpen(e, item) }}
        >
          Mark Complete
        </button>
      </>
    );
  };

  const appendAlerts = (item) => {
    const hrs = item.partRunningHrs + item.extraRunningHrs;
    const mDayHrs = parseInt(item?.part?.maintenance_days) * 24;

    return (
      <div className="upcoming-list-box">
        <small>Part Name</small>
        <h4>{item?.part?.name}</h4>
        <div className="runing-hours">
          <div className="hourstext">
            <span>Running Hours</span>
            <p>{hrs + "/" + mDayHrs}</p>
          </div>
        </div>
      </div>
    )
  }

  const resetModalOpen = (e, item) => {
    e.stopPropagation();
    maintenanceId = item._id;
    $("#closeAlert-complete").modal("show");
  }

  const resetPartHrs = async () => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `resetPartHrs`,
        REST_API_METHODS.PUT,
        { id: maintenanceId }
      );
      if (!!response?.status) {
        $("#closeAlert-complete").modal("hide");
        getPredictiveMaintenance();
        showMessage(response.message);

      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const handlerClose = (id) => {
    maintenanceId = id;
    $("#closeAlert-complete").modal("hide");
  };

  return (
    <div className="comming-page explorewrap">
      <TitleSetter title="Predictive Maintenance" />
      <div className="predictive-View-wrap pt-3 pb-4">
        <div className="container">
          <div className="predective-machine-bx bxnone">
            <div className="predective-detail-box">
              <div className="machine-box">
                <div className="machine-image">
                  <img
                    src={
                      assetsDetails?.assetss?.images?.length
                        ? assetsDetails?.assetss?.images[
                          assetsDetails?.assetss.defaultImageIndex
                        ].url
                        : "./images/no-image.png"
                    }
                    alt=""
                  />
                </div>
                <div className="machine-information">
                  <div className="machineName">
                    {assetsDetails?.assetss?.name}
                  </div>
                  <div className="machineSerialno">
                    Serial No. : {assetsDetails.serialNo}
                  </div>
                  <div className="machineStatus">
                    Status :{" "}
                    {assetsDetails.assetsActive ? "Active" : "In-Active"}{" "}
                  </div>
                  <div className="extraInfo-machine">
                    <div className="extrainfo-div">
                      <span>Runnings Days : </span>
                      <span>{assetsDetails?.runningDays}</span>
                    </div>
                  </div>
                  {!assetsDetails.chipInstallation && (
                    <a
                      className="predectivetime"
                      data-toggle="tooltip"
                      title="Set Hours"
                      href="javascript:void(0);"
                      onClick={(e) => { e.stopPropagation(); childRef.current.viewHandler(assetsDetails) }
                        // setWeekHrsModal(e, assetsDetails)
                      }
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="clock"
                        class="svg-inline--fa fa-clock fa-w-16 "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"
                        ></path>
                      </svg>
                    </a>
                  )}
                </div>
                <div className="upcoming-maintenance">
                  <div className="upcoming-heading">
                    <h3>Upcoming Maintenance</h3>
                  </div>
                  <div className="upcoming-list">
                    <ul>
                      {upcomingAlerts.map((item) => (
                        <li key={item._id}>
                          {appendAlerts(item)}
                        </li>))}
                      {!upcomingAlerts.length && <div className="text-muted">No data available</div>}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="machine-detail-wrap">
                <div className="page-heading">
                  Welcome To Elgin Engine AI Predictive Maintenance Module
                </div>
                <div className="hours-graph-wrap">
                  <div className="small-graph">
                    <h3>PARTS RUNNING HOURS</h3>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="small-chart-wrap">
                          {predictiveMaintenance?.parts?.map((item, index) => (
                            <div key={index} className="small-chart-div mb-4 cursor-pointer" style={{ background: item?.part?._id === selectPart?.part?._id ? '#edf8fc' : '' }} onClick={(e) => getAssetsPartsGraphData(e, item, startDate, endDate)}>
                              {appendParts(item)}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bearing-wrap">
                  <div className="d-flex gap-btn">
                    <h3>MAINTENANCE HISTORY OF <strong>{selectPart?.part?.name || 'N/A'}</strong></h3>
                    <div className="calender-icon" onClick={() => setModalOpen(!modalOpen)}>
                      <img src="../images/calender-icon.svg" />
                    </div>
                    {!!modalOpen && (
                      // <div className="calender-section">
                      <DateRange
                        ranges={filterDate}
                        onChange={dateHandler}
                        moveRangeOnFirstSelection={false}
                        editableDateInputs={false}
                        maxDate={yesterdayDate}
                      />
                      // </div>
                    )}
                  </div>
                  {/* <div className="calender-container">
                    <div className="predictive-data">
                      <select
                        className="form-control"
                        onChange={handlerFilter}
                      >
                        <option value={0}>Weekly</option>
                        <option value={1}>Custom Date</option>
                      </select>
                      {!!modalOpen && (
                        <div className="calender-section">
                          <DateRange
                            ranges={filterDate}
                            onChange={dateHandler}
                            moveRangeOnFirstSelection={false}
                            editableDateInputs={false}
                            maxDate={yesturdayDate}
                          />
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div className="chargraph">
                    <Chart
                      options={columSeriesOptions}
                      series={columSeries}
                      type="line"
                      width={"100%"}
                      height={420}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SetApproximateWeekHrs ref={childRef} setLoading={setLoading} redirect={false} />

      <div
        className="modal fade modal-center"
        id="closeAlert-complete"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog custom_modal_wrap" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="logoutpopupwrap">
                <h2 className="modlheading">Mark Complete</h2>
                <FontAwesomeIcon
                  className="w-100 h1  fonticon"
                  color={"red"}
                  icon={faBellSlash}
                />
                <p className="mt-2">Are you sure you want to complete?</p>
                <div className="common_btn">
                  <button
                    type="button"
                    data-dismiss="modal"
                    onClick={resetPartHrs}
                  >
                    Yes
                  </button>
                  <button type="button" data-dismiss="modal">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default IsLoadingHOC(PredictiveMaintenanceView);
