import BackButton from "../common/BackButton";
import "../../styles/components/details.css";
import { TermsConditionsFeature } from "../settings";

const Detail = (props) => {
  const { title, description } = props;

  return (
    <div className="explorewrap termscondition">
      <img src="images/left_bg.png" alt="" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <TermsConditionsFeature title={title} description={description} />
            <div className="">
              <BackButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
