import React, { useEffect, useState } from "react";
import "../../styles/pages/user-management.css";
import { useHistory, useParams } from "react-router-dom";
import Empty from "../../components/common/Empty";
import IsLoadingHOC from "../../components/common/IsLoadingHOC";
import TitleSetter from "../../components/common/TitleSetter";
import { REST_API_METHODS } from "../../utils/constants";
import { callBackendAPI, showMessage, userInfo } from "../../utils/helpers";
import Modal from "../../modal/Modal";
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const defaultUrl = 'images/user.png';

const AssetsLinked = (props) => {
    const { setLoading, isLoading } = props;
    const { id } = useParams();
    const history = useHistory();
    const user = userInfo();

    const [userDetails, setUserDetails] = useState({});
    const [usersAssets, setUserAssets] = useState([]);
    const [show, setShow] = useState(false);
    const [assets, setAssets] = useState({});

    useEffect(() => {
        getAssetsUserLinkedDetails();
        getUsersAssets();
    }, []);

    const getAssetsUserLinkedDetails = async () => {
        try {
            const response = await callBackendAPI(
                `getAssetsUserLinkedDetails?id=${id}`,
                REST_API_METHODS.GET
            );
            if (!!response.status) {
                setUserDetails(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getUsersAssets = async () => {
        try {
            setLoading(true);
            const response = await callBackendAPI(
                `getUserAssetsLinkedManagement?id=${id}`,
                REST_API_METHODS.GET
            );
            if (!!response.status && !!response.data.length) {
                setUserAssets(response.data);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    const removeAssets = async (item) => {
        setAssets({ ...assets, ...item });
        setShow(true);
    }

    const confirmHandler = async () => {
        try {
            setLoading(true);
            const payload = {
                id: assets._id,
                userId: assets.user._id,
                parentId: user._id
            }
            let temp_state = [...usersAssets];
            const index = temp_state.findIndex(a => a._id === assets._id);

            const response = await callBackendAPI(
                `assignAssetsRemove`,
                REST_API_METHODS.DELETE,
                payload
            );
            if (!!response.status) {
                temp_state.splice(index, 1);
                setUserAssets(temp_state);
                closeModal();
            }
            showMessage(response.message);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    }

    const closeModal = () => {
        setShow(false);
        setLoading(false);
    }

    return (
        <>
            <TitleSetter title={"User Management"} />
            <div className="user-management">
                <div className="cartPage-heading">
                    <div className="subUser-profile">
                        <div className="profileim">
                            <img src={userDetails.image} onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = defaultUrl;
                            }} alt="" />
                        </div>
                        <p>{userDetails.name}</p>
                    </div>
                    <div className="common_btn">
                        {!!usersAssets.length ? <button
                            className="pl-4 pr-4"
                            onClick={() => {
                                history.push("/assets-link/edit/" + id);
                            }}
                        >
                            Edit Assets
                        </button> :
                            <button
                                className="pl-4 pr-4"
                                onClick={() => {
                                    history.push("/assets-link/new/" + id);
                                }}
                            >
                                Link Assets
                            </button>}
                    </div>
                </div>
                <div className="container">

                    <div className="tabledatawrap table-responsive">
                        <table className="table">
                            <thead>
                                <tr className="table-header-c">
                                    <th className="align-l">Asset Group Name</th>
                                    <th className="align-l">Assets Name</th>
                                    <th className="align-l">Serial No.</th>
                                    <th className="align-l">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {usersAssets.map((item, index) => (
                                    <tr key={index}>
                                        <td className="align-l">{item?.machine?.name || '--'}</td>
                                        <td className="align-l">{item?.assets?.name || '--'}</td>
                                        <td className="align-l">{item?.serialNo || '--'}</td>
                                        <td className="align-l">
                                            <div className="action-text">
                                            <a data-toggle="tooltip" title="Delete" onClick={() => removeAssets(item, index)}>
                                            <FontAwesomeIcon icon={faTrash}/>
                                            </a>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table >
                        {!usersAssets.length && !isLoading && (
                            <Empty message="No assets linked" />
                        )}
                    </div>
                </div>
            </div>
            <Modal
                title={"Confirm"}
                onClose={() => setShow(false)}
                show={show}
            >
                <div className="">
                    <p>Are you sure you want to delete this assets?</p>
                    <div className="common_btn">
                        <button className="confirm-alert-btn" onClick={confirmHandler}>Yes</button>
                        <button className="confirm-alert-btn" onClick={closeModal}>No</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default IsLoadingHOC(AssetsLinked);