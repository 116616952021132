import React, { useEffect, useState } from "react";
import "../../styles/pages/faq.css";
import { REST_API_METHODS } from "../../utils/constants";
import { callBackendAPI } from "../../utils/helpers";

const FQAFeature = () => {

    const [faqList, setFAQList] = useState([]);

    useEffect(() => {
        getFAQList();
    }, []);

    const getFAQList = async () => {
        try {
            const response = await callBackendAPI(
                "getFAQList?skip=0&limit=10",
                REST_API_METHODS.GET
            );
            if (!!response.status) {
                setFAQList(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const toggleFaq = (faqId) => {
        const isCollapsed = !document
            .getElementById(faqId)
            ?.classList.contains("show");

        if (isCollapsed) {
            document.getElementById(`btn-${faqId}`)?.classList.remove("collapsed");
            document.getElementById(faqId)?.classList.add("collapsing");
            setTimeout(() => {
                document.getElementById(faqId)?.classList.remove("collapsing");
            }, 100);
            document.getElementById(faqId)?.classList.add("show");
        } else {
            document.getElementById(`btn-${faqId}`)?.classList.add("collapsed");
            document.getElementById(faqId)?.classList.remove("show");
        }
    };


    const getFaq = (item, id) => {
        return (
            <div className="item">
                <div className="item-header" id="headingThree">
                    <h2 className="mb-0">
                        <button
                            id={`btn-${id}`}
                            className="btn btn-link collapsed text-capitalize"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="true"
                            aria-controls="collapseTwo"
                            onClick={() => toggleFaq(id)}
                        >
                            {item.question}
                            <div className="faqcloseicons">
                                <svg
                                    className="close_faq"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="72"
                                    height="56"
                                    viewBox="0 0 72 56"
                                >
                                    <g
                                        id="Group_262535"
                                        data-name="Group 262535"
                                        transform="translate(-1200 -467)"
                                    >
                                        <rect
                                            id="Rectangle_5353"
                                            data-name="Rectangle 5353"
                                            width="72"
                                            height="56"
                                            rx="8"
                                            transform="translate(1200 467)"
                                            fill="#1c3e7d"
                                            opacity="0.234"
                                        />
                                        <g
                                            id="plus_1_"
                                            data-name="plus (1)"
                                            transform="translate(1224 483)"
                                        >
                                            <path
                                                id="Path_280838"
                                                data-name="Path 280838"
                                                d="M12.006,0V24.012M0,12.006H24.012"
                                                fill="none"
                                                stroke="#1c3e7d"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                            />
                                        </g>
                                    </g>
                                </svg>
                                <svg
                                    className="open_faq"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="72"
                                    height="56"
                                    viewBox="0 0 72 56"
                                >
                                    <g
                                        id="Group_262534"
                                        data-name="Group 262534"
                                        transform="translate(-1204 -239)"
                                    >
                                        <rect
                                            id="Rectangle_5355"
                                            data-name="Rectangle 5355"
                                            width="72"
                                            height="56"
                                            rx="8"
                                            transform="translate(1204 239)"
                                            fill="#1c3e7d"
                                            opacity="0.234"
                                        />
                                        <g
                                            id="plus_1_"
                                            data-name="plus (1)"
                                            transform="translate(1228 255)"
                                        >
                                            <path
                                                id="Path_280838"
                                                data-name="Path 280838"
                                                d="M0,12.006H24.012"
                                                fill="none"
                                                stroke="#1c3e7d"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </button>
                    </h2>
                </div>
                <div
                    id={id}
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                >
                    <p className="text-capitalize">{item.answer}</p>
                </div>
            </div>
        );
    };

    return (
        <div className="faqwrap">
            <h2>FAQs</h2>
            <div className="accordion" id="accordionExample">
                {faqList.map((item, key) => {
                    return getFaq(item, "ele" + key);
                })}
            </div>
        </div>
    )
}

export default FQAFeature;