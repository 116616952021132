import React, { useState } from "react";

import Lottie from "react-lottie";
import animationData from "./loading-colour.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const IsLoadingHOC = (WarpedComponent) => {
  function HOC(props) {
    const [isLoading, setLoading] = useState(true);

    const setLoadingStage = (isComponentLoading) => {
      setLoading(isComponentLoading);
    };

    return (
      <>
        {isLoading && (
          <div className="loaderdv"
            style={{
              zIndex: `${9999 ? "101" : "0"}`,
            }}
          >
            <Lottie options={defaultOptions} height={400} width={400} />
          </div>
        )}
        <WarpedComponent
          {...props}
          setLoading={setLoadingStage}
          isLoading={isLoading}
        />
      </>
    );
  }

  return HOC;
};

export default IsLoadingHOC;
