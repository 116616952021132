import React, { useState, useImperativeHandle, forwardRef } from "react";
import $ from 'jquery';
import * as yup from "yup";
import _ from 'lodash';

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "../../styles/pages/comming-page.css";
import "../../styles/pages/predictive.css"
import { REST_API_METHODS } from "../../utils/constants";
import { callBackendAPI, getWeekName, showMessage } from "../../utils/helpers";
import { useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../pages/predictive-maintenance/cubes-img.json";
import AutoComplete from "../../components/common/AutoComplete";
import Error from "../../components/common/Error";
import useWeather from "../../hooks/useWeather";

const validationSchema = yup
    .object({
        address: yup.string().required("Address is required"),
        city: yup.string(),
        state: yup.string(),
        country: yup.string(),
        pin_code: yup.string(),
        address_locate: yup.string()
            .typeError('Asseets locate must be a checked')
            .required('Asseets locate is required'),
        hours: yup
            .number()
            .typeError('Hours must be a number')
            .required("Set Week Hours is required")
            .max(168, 'The maximum number of hours allowed is 168. Please enter a value equal to or less than 168.'),
    })
    .required();

const SetApproximateWeekHrs = forwardRef(({ setLoading, redirect = false }, ref) => {

    const history = useHistory();
    let maintenanceId = 0;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const [asset, setAsset] = useState({});
    const [assetsDetails, setAssetsDetails] = useState({});
    const { weather, fetchData } = useWeather();

    const {
        register,
        setValue,
        getValues,
        setError,
        clearErrors,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });


    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({

        async viewHandler(asset) {
            const day = getWeekName();
            let elementId = '';
            if (!!asset.chipInstallation) {
                navigateHandler(asset);
            } else if (!asset.reminderModalStatus) {
                elementId = "#ai-chip-Modal";
            } else if (day === 'Monday') {
                elementId = "#entry-Modal";
            } else {
                navigateHandler(asset);
            }

            if (!!elementId) {
                clearErrors('hours');
                setValue('hours', asset?.weekHrs);
                const address = !!asset?.actinalAddress ? asset?.actinalAddress : asset?.address;
                Object.entries(address).map(([key, value]) => {
                    if (key === 'address_locate') {
                        setValue('address_locate', value);
                        $(`#${value}`).prop('checked', true); // Checks it
                    } else {
                        setValue(key, value);
                    }
                });
                $(elementId).modal("show");
            }
            const assetData = Object.assign({}, asset);
            const id = !!asset?._id ? asset?._id : asset?.id;
            delete assetData.id;
            assetData._id = id;
            setAsset(assetData);
            setAssetsDetails({ ...assetsDetails, ...assetData });
        }
    }));

    const navigateHandler = (asset) => {
        if (!!redirect) {
            history.push('/predictive-maintenance-view/' + asset?.assets?._id + '/' + asset.serialNo);
        }
    }

    const handlerClose = (id) => {
        maintenanceId = id;
        $("#ai-chip-Modal").modal("hide");
        $("#entry-Modal").modal("hide");
        $("#calculate-Modal").modal("hide");
        setValue("hours", '');
        setValue("address", '');
        setValue("address_locate", '');
    };

    const reminderHandler = async (event) => {
        try {
            const checked = event.target.checked;
            setLoading(true);

            const response = await callBackendAPI(
                `reminderUpdate`,
                REST_API_METHODS.PUT,
                { id: asset._id, status: checked }
            );
            if (response.status) {
                showMessage(response?.message)
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }


    const setAddress = (response) => {
        setValue("address", response.address);
        setValue("city", response.city);
        setValue("state", response.state);
        setValue("country", response.country);
        setValue("pin_code", response.pin_code);
        setValue("latitude", response.latitude);
        setValue("longitude", response.longitude);
    }

    const onSubmit = handleSubmit(
        async (values) => {
            try {
                const addressLocate = getValues("address_locate"); // "get single value"
                if (!addressLocate) return setError('address_locate', { type: 'checkout', message: 'Asseets locate is required' });

                $("#calculate-Modal").modal("show");
                const params = {
                    id: assetsDetails._id,
                    weekHrs: values.hours,
                    newAddress: _.omit(values, "hours"),
                    weather_temp: weather.temp
                }
                console.log(params)
                const response = await callBackendAPI(
                    `setWeekHrs`,
                    REST_API_METHODS.PUT,
                    params
                );
                if (response.status) {
                    handlerClose(0);
                    navigateHandler(asset);
                }
                $("#calculate-Modal").modal("hide");
            } catch (e) {
                $("#calculate-Modal").modal("hide");
                console.log(e);
            }
        },
        (errors) => {
            console.log("errors", errors);
        }
    );

    const sendQuoteMail = async () => {
        try {
            setLoading(true);
            const params = {
                id: assetsDetails._id
            }
            const response = await callBackendAPI(
                `sendQuoteMail`,
                REST_API_METHODS.PUT,
                params
            );
            if (response.status) {
                showMessage(response.message);
            }
            setLoading(false);
            handlerClose(0);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    }

    return (
        <>
            <div class="modal fade modal-center" id="ai-chip-Modal">
                <div class="modal-dialog custom_modal_wrap  new-address-modal new-modal">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="logoutpopupwrap">
                                <button type="button" class="close" onClick={handlerClose}>
                                    ×
                                </button>
                                <div className="e-chip-pop-wrap">
                                    <div className="pop-image">
                                        <img src="images/inquiry.png" alt="" />
                                    </div>
                                    <div className="chip-pop-text">
                                        <h4>
                                            No, E-AI chip is allocated to your asset, press below to get
                                            quote.
                                        </h4>
                                        <div className="remind-text">
                                            <input type="checkbox" id="html" onChange={reminderHandler} />
                                            <label for="html">Don't remind me</label>
                                        </div>
                                        <div className="chip-pop-button">
                                            <button className="blue-btn" onClick={sendQuoteMail}>Get quote</button>
                                            <button className="blue-btn first-btn" data-toggle="modal" data-target="#entry-Modal" data-backdrop="static" data-keyboard="false">Later</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade modal-center" id="entry-Modal">
                <div class="modal-dialog custom_modal_wrap new-address-modal new-modal">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="logoutpopupwrap">
                                <button type="button" class="close" onClick={handlerClose}>
                                    ×
                                </button>
                                <div className="entry-pop-wrap">
                                    <div className="entry-heading">
                                        <h3>Manual Entry</h3>
                                    </div>
                                    <div className="entry-divs">
                                        <div className="form-group">
                                            <label className="font-weight-normal">Approximate weekly asset running hours</label>
                                            <input
                                                {...register("hours")}
                                                className="form-control"
                                                pattern="[0-9]*"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                placeholder="Enter average weekly running hours"
                                            />
                                            {!!errors.hours && (
                                                <Error message={errors.hours.message} />
                                            )}
                                        </div>
                                        <div className="form-group checkbox-group">
                                            <label className="font-weight-normal">Assets</label>
                                            <div class="chchbx">
                                                <input
                                                    {...register("address_locate")}
                                                    type="radio"
                                                    id="indoor"
                                                    value="indoor"
                                                    onChange={(e) => setValue('address_locate', e.target.value)}
                                                />
                                                <label for="indoor">Indoor</label>
                                            </div>
                                            <div class="chchbx">
                                                <input
                                                    {...register("address_locate")}
                                                    type="radio"
                                                    id="outdoor"
                                                    value="outdoor"
                                                    onChange={(e) => setValue('address_locate', e.target.value)}
                                                />
                                                <label for="outdoor">Outdoor</label>
                                            </div>
                                        </div>
                                        {!!errors.address_locate && (
                                            <Error message={errors.address_locate.message} />
                                        )}
                                        <div className="form-group div-group">
                                            <label className="font-weight-normal">Address</label>
                                            <AutoComplete setLoading={setLoading} register={register} errors={errors} setAddress={setAddress} />
                                        </div>
                                        <div className="chip-pop-button">
                                            <button className="blue-btn" onClick={handlerClose}>Cancel</button>
                                            <button className="blue-btn first-btn" onClick={onSubmit}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade modal-center" id="calculate-Modal">
                <div class="modal-dialog custom_modal_wrap new-address-modal new-modal">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="logoutpopupwrap">
                                <button type="button" class="close" onClick={handlerClose}>
                                    ×
                                </button>
                                <div className="e-chip-pop-wrap">
                                    <div className="cube-text">
                                        <h4>Elgin AI calculating new set parts</h4>
                                    </div>
                                    <div className="pop-image">
                                        <div className="cube-img">
                                            <Lottie options={defaultOptions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default SetApproximateWeekHrs;