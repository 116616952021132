import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import * as yup from "yup";
import _ from "lodash";

import "../styles/pages/raise-ticket.css";

import {
  callBackendAPI,
  showError,
  showMessage,
  unique,
  getDays
} from "../utils/helpers";
import {
  REST_API_METHODS,
  Mobile_Max_Length,
  Mobile_Min_Length,
} from "../utils/constants";

import Error from "../components/common/Error";
import TitleSetter from "../components/common/TitleSetter";
import Modal from "../modal/Modal";
import AddAddress from "../pages/AddAddress";
import IsLoadingHOC from "../components/common/IsLoadingHOC";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

let valid = false;
const todayDate = moment(new Date()).format("YYYY-MM-DD");

const validationSchema = yup
  .object({
    phone: yup
      .string()
      // .matches(phoneRegExp, "Contact number is not valid")
      // .required("Contact is required")
      .min(Mobile_Min_Length)
      .max(Mobile_Max_Length),
    type: yup.string(),
    comment: yup.string().max(1000),
    requestDate: yup.string(),
    // part: yup.string(),
    machine_name: yup.string(),
    assets_name: yup.string(),
    serialNo: yup.string(),
    machine: yup.string(),
    assets: yup.string(),
    nearBy: yup.string(),
    address: yup.string(),
    customer_name: yup.string(),
    forRequest: yup.number().nullable(true),
    warranty: yup.number().nullable(true),
    quantity: yup.string(),
  })
  .required();

let userAddress = [];
let addToCardStatus = false;

const RaiseTicket = (props) => {
  const history = useHistory();
  const { setLoading } = props;

  let { id, edit_type } = useParams();

  const {
    register,
    setValue,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const inputFieldValue = watch("warranty");
  const countryIsoCode = localStorage.getItem('country_code');

  const [ticketCategories, setTicketCategories] = useState([]);
  const [address, setUserAddress] = useState([]);
  const [defaultIndex, setIndex] = useState(0);
  const [type, setType] = useState("service");
  const [machinesLinked, setLinkedMachines] = useState([]);
  const [machines, setMachines] = useState([]);
  const [machinesAssets, setMachinesAssets] = useState([]);
  const [assetsParts, setAssetsParts] = useState([]);
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [selectAddress, setSelectAddress] = useState({});
  const [show, setShow] = useState(false);
  const [multiParts, setMultiParts] = useState([]);
  const [selectedPart, setSelectedPart] = useState("");
  const [countryCode, setCountryCode] = useState(countryIsoCode);
  const [cartId, setCartId] = useState(edit_type.split("=")[1] || 0);

  const [formError, setFormError] = useState({
    type: false,
    requestDate: false,
    machine_name: false,
    assets_name: false,
    part: false,
    customer_name: false,
    phone: false,
    phoneValid: false,
    forRequest: false,
    warranty: false,
    comment: false,
    address: false,
    serialNo: false,
  });

  useEffect(() => {
    fetchCategories();
    getAddressList();
    getMachinesList();
    fetchAssetsDetails();
    document.title = "Raise A Ticket";
  }, []);

  const getMachinesList = async () => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getMachineForRaiseTicket?userId=${user._id}`,
        REST_API_METHODS.GET
      );
      if (!!response?.status && !!response.data) {
        setLinkedMachines(response.data);

        //bind serial number into machine and assets object
        response.data.map((ele) => {
          Object.keys(ele).forEach((key) => {
            if (key == "machine" || key == "assets") {
              const newData = ele[key];
              newData.serialNo = ele.serialNo;
              ele[key] = newData;
            }
          });
        });

        const machinesResponse = response.data.map((a) => a.machine);
        const uniqueMachine = unique(machinesResponse);
        setMachines(uniqueMachine);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getAssetsPartsList = async (serialNo) => {
    try {
      const findMachine = machinesAssets.find((a) => a.serialNo === serialNo);
      const id = findMachine?._id || 0;
      if (!serialNo || !findMachine) {
        return;
      }
      setValue("assets", id);
      setValue("serialNo", serialNo || "");
      updateError("serialNo");

      setLoading(true);
      setValue("part", "");
      const response = await callBackendAPI(
        `getAssetsParts?id=${id}`,
        REST_API_METHODS.GET
      );
      if (!!response?.status && !!response.data) {
        setAssetsParts(response.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await callBackendAPI(
        `getTicketCategories`,
        REST_API_METHODS.GET
      );
      if (!!response?.status && !!response.data) {
        //if user want to crate a ticket directly, so we will remove buy now functionality.
        const index = response.data.findIndex((a) => a.type == "buy-now");
        if (id == "0" && index >= 0) {
          response.data.splice(index, 1);
        }
        const cat = response.data;

        setTicketCategories(cat);
        if (!!cat.length) {
          const findCat = cat[0];
          setValue("type", findCat._id);
          setType(findCat.type);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAddressList = async () => {
    try {
      const response = await callBackendAPI(
        `getAddress?userId=${user._id}`,
        REST_API_METHODS.GET
      );
      if (!!response.status && !!response.data.length) {
        userAddress = response.data.sort((a, b) => a.createdAt - b.createdAt);
        setUserAddress(userAddress);
        const id = userAddress[0]._id;
        setValue("address", id);
        changeAddress(id);
        // const index = response.data.findIndex(
        //   (a) => a.default_address === true
        // );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAssetsDetails = async () => {
    try {
      if (id === "0") {
        return;
      }

      let url = `getAssetsLinkedDetails?id=${id}`;
      if (cartId) {
        url = `getCartDetails?id=${cartId}`;
      }

      const response = await callBackendAPI(url, REST_API_METHODS.GET);
      if (!!response?.status && !!response.data) {
        const assets = response.data;
        setValue("machine_name", assets.machine.name);
        setValue("assets_name", assets.assets.name);

        setValue("machine", assets.machine._id);
        setValue("assets", assets.assets._id);
        setValue("serialNo", assets.serialNo);

        if (cartId) {
          setValue("part", assets.parts);
          setMultiParts(assets.parts);
          setValue("customer_name", assets.customer_name);
          setValue("phone", assets.phone);
          setValue(
            "requestDate",
            moment(assets.requestDate).format("YYYY-MM-DD")
          );
          setValue("address", assets.address._id);
          setValue("comment", assets.comment);
        } else {
          setValue("part", localStorage.getItem("partName"));
          setMultiParts(JSON.parse(localStorage.getItem("partNames")) || []);
        }
        if (ticketCategories.length) {
          const findCat = ticketCategories[0];
          setValue("type", findCat._id);
          setType(findCat.type);
        }
      } else {
        history.push("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const changeAddress = (event) => {
    const id = event.target ? event.target.value : event ? event : 0;
    const data = userAddress.find((a) => a._id === id);
    let completeAddress = {};
    if (!!data) {
      completeAddress = data;
    }
    if (!!data) {
      setValue("address", data._id);
      setSelectAddress(completeAddress);
      updateError("address");
      // setValue("completeAddress", completeAddress);
    }
  };

  const closeModal = (event) => {
    getAddressList();
    setShow(false);
  };

  const checkValidate = (values) => {
    Object.entries(values).forEach(([key, value]) => {
      if (!formError[key] && !value) {
        formError[key] = true;
        valid = false;
      }
    });

    if (values["phone"].length > 1 && values["phone"].length < 10) {
      formError["phoneValid"] = true;
      valid = false;
    }
    let pattern = /[0-9]/;
    const verifyNumber = pattern.test(values["phone"]);
    if (!verifyNumber) {
      formError["phoneValid"] = true;
      valid = false;
    } else {
      formError["phoneValid"] = false;
    }
    if (!multiParts.length) {
      formError["part"] = true;
      valid = false;
    }

    Object.entries(formError).forEach(([key, value]) => {
      if (formError[key]) {
        valid = false;
      }
    });
    setFormError({ ...formError });
  };

  const addToCardHandler = (status) => {
    addToCardStatus = status;
  };

  const onSubmit = handleSubmit(
    async (values) => {
      console.log("values", values, addToCardStatus);
      valid = true;
      values.phone = "+" + values.phone;
      if (type === "service") {
        values.part = "";
        if (
          !values.type ||
          !values.machine_name ||
          !values.assets_name ||
          !values.requestDate ||
          !values.customer_name ||
          !values.phone ||
          !values.forRequest ||
          !values.warranty ||
          !values.comment ||
          !values.serialNo ||
          !values.address
        ) {
          checkValidate(values);
        }
      } else if (type === "maintenance") {
        if (multiParts.length) {
          values.part = "done";
        }
        if (
          !values.type ||
          !values.machine_name ||
          !values.assets_name ||
          !values.part ||
          !values.requestDate ||
          !values.customer_name ||
          !values.phone ||
          !values.forRequest ||
          !values.warranty ||
          !values.comment ||
          !values.serialNo ||
          !values.address
        ) {
          checkValidate(values);
        }
      } else if (type === "inquiry" || type === "default") {
        if (
          !values.type ||
          !values.customer_name ||
          !values.phone ||
          !values.address ||
          !values.comment
        ) {
          checkValidate(values);
        }
      } else if (type === "buy-now") {
        if (multiParts.length) {
          values.part = "done";
        }
        if (
          !values.type ||
          !values.machine_name ||
          !values.assets_name ||
          !values.part ||
          !values.requestDate ||
          !values.customer_name ||
          !values.phone ||
          !values.serialNo ||
          !values.address
        ) {
          checkValidate(values);
        }
      }

      if (!valid) {
        console.log("all fields", values);
        return;
      }
      if (values.requestDate) {
        values.requestDate = moment(values.requestDate, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
      }
      setLoading(true);
      try {
        values.user = user._id;
        values.part = values.part || "";
        values.forRequest = values.forRequest ? values.forRequest : -1;
        values.warranty = values.warranty || 0;
        // values.quantity = values.quantity ? parseInt(values.quantity) : 0;
        values.parts = multiParts;

        if (addToCardStatus) {
          values.userAssets = id;
        }
        if (cartId) {
          values.id = cartId;
        }

        const params = {
          path: addToCardStatus
            ? cartId
              ? "updateCart"
              : "addToCart"
            : "raiseTicket",
          method: REST_API_METHODS.POST,
          payload: values,
        };

        if (type === "inquiry" || type === "default") {
          params.payload = _.omit(
            values,
            "machine",
            "assets",
            "part",
            "quantity"
          );
        }

        params.payload = _.omit(params.payload, "machine_name", "assets_name");

        const response = await callBackendAPI(
          params.path,
          params.method,
          params.payload
        );
        if (!!response.status) {
          showMessage(response.message);
          localStorage.removeItem("partNames");
          history.push("/submitted-request");
        } else if (!response.status) {
          showError(response.message);
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    },
    (errors) => {
      setLoading(false);
      console.log("errors", errors);
    }
  );

  const removeCart = async () => {
    const response = await callBackendAPI(
      `removeCart?id=${cartId}`,
      REST_API_METHODS.DELETE
    );
    if (!!response.status) {
      showMessage(response.message);
      history.goBack();
    }
  };

  const handelType = (event) => {
    localStorage.removeItem("partNames");
    setValue("type", event.target.value);
    const typeName = ticketCategories.find((a) => a._id === event.target.value);
    setType(typeName?.type || "service");
    updateError("type");
    setMultiParts([]);
  };

  const handelMachine = (event) => {
    const id = event.target.value;
    const assetsResponse = machinesLinked.map((a) => {
      const assets = a.assets;
      assets.warrantyExpiration = a.warrantyExpiration;
      assets.assetsInstallDate = a.assetsInstallDate;
      return assets;
    });
    const filleterAssets = assetsResponse.filter((a) => a.machine === id);

    setValue("machine_name", event.target.value);
    setValue("machine", id);
    setMachinesAssets(filleterAssets);
    updateError("machine_name");
    setValue("assets_name", "");
    setValue("serialNo", "");
    setValue("part", "");
  };

  const assetsNameHandler = (e) => {
    setValue("assets", e.target.value);
    setValue("assets_name", e.target.value);
    updateError("assets_name");
    getAssetsPartsList(e.target.value);
    const matchAssets = machinesAssets.find(a => a.serialNo === e.target.value);
    if (!!matchAssets) {
      const days = getDays(matchAssets.assetsInstallDate);
      const matchDays = days < parseInt(matchAssets.warrantyExpiration) ? 1 : 2;
      setValue("warranty", matchDays);
    }
  }

  const updateError = (name) => {
    formError[name] = false;
    setFormError({ ...formError });
  };

  const serialHandler = () => {
    return (
      <div className="col-md-6">
        <div className="common_input_warp">
          <label htmlFor="">Serial No</label>
          <div className="form-group">
            <input
              {...register("serialNo")}
              type="text"
              className="form-control"
              placeholder="Serial No"
              name="serialNo"
              readOnly
              autoComplete="off"
              onChange={() => {
                updateError("serialNo");
              }}
            />
            {/* <p className="note-p">
              Note: You can find Serial No. on the name plate of the system.
            </p> */}
            {!!formError.serialNo && (
              <Error message={"Serial no is required"} />
            )}
          </div>
        </div>
      </div>
    );
  };

  const getPartList = () => {
    return (
      <>
        {(type === "buy-now" || type === "maintenance") && (
          <div className="col-md-12">
            <label htmlFor="">Parts list</label>
            {id !== "0" && !cartId && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                className="float-lg-right mt-2"
                href="javascript:void(0)"
                onClick={() => {
                  history.push("/svg-model");
                }}
              >
                Add more
              </a>
            )}
            <table className="table">
              <thead>
                <tr>
                  <th>Part Name</th>
                  <th>Part Number</th>
                  <th className="text-right">Quantity</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {multiParts.map((par, index) => (
                  <tr key={index}>
                    <td>{par.partName}</td>
                    <td>{par.partNumber}</td>
                    <td className="text-right">
                      <div className="quantity-input">
                        <button
                          className="quantity-input__modifier quantity-input__modifier--left"
                          onClick={() => decrement(index)}
                        >
                          &mdash;
                        </button>
                        <input
                          className="quantity-input__screen"
                          type="text"
                          value={par.quantity}
                          readonly
                        />
                        <button
                          className="quantity-input__modifier quantity-input__modifier--right"
                          onClick={() => increment(index)}
                        >
                          &#xff0b;
                        </button>
                      </div>
                    </td>
                    <td>
                      <FontAwesomeIcon
                        icon={faTrash}
                        color="red"
                        className="courser-pointer"
                        onClick={() => removePartModal(index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!!formError.part && <Error message={"Parts is required"} />}
          </div>
        )}
      </>
    );
  };

  const increment = (index) => {
    multiParts[index].quantity += 1;
    setMultiParts([...multiParts]);
  };

  const decrement = (index) => {
    if (multiParts[index].quantity > 1) {
      multiParts[index].quantity -= 1;
      setMultiParts([...multiParts]);
      localStorage.setItem("partNames", JSON.stringify(multiParts));
    }
  };

  const removePartModal = (index) => {
    setIndex(index);
    const modalElement = document.getElementById("remove-part-popup");
    const isModalOpen = document
      .getElementById("remove-part-popup")
      .classList.contains("show");

    if (isModalOpen) {
      document.body.classList.remove("modal-open");
      modalElement?.classList.remove("show");
      modalElement.style.display = "none";
    } else {
      document.body.classList.add("modal-open");
      modalElement?.classList.add("show");
      modalElement.style.display = "block";
    }
  };

  const removePart = (index) => {
    multiParts.splice(index, 1);
    setMultiParts([...multiParts]);
    localStorage.setItem("partNames", JSON.stringify(multiParts));
    removePartModal();
  };

  const addPartsIntoList = () => {
    let storeCart = [];
    let index = -1;
    if (selectedPart) {
      if (localStorage.getItem("partNames")) {
        storeCart = JSON.parse(localStorage.getItem("partNames"));
        index = storeCart.findIndex((a) => a.partName === selectedPart);
        if (index >= 0) {
          storeCart[index].quantity += 1;
        }
      }
      if (index === -1) {
        const findPart = assetsParts.find((a) => a.name === selectedPart);
        storeCart.push({
          partName: selectedPart,
          partNumber: findPart.part_number,
          quantity: 1,
        });
      }
      updateError("part");
      localStorage.setItem("partNames", JSON.stringify(storeCart));
      setMultiParts(storeCart);
    }
  };

  return (
    <>
      <TitleSetter title={"Raise A Ticket"} />
      {/* <!-- Explore Section Start Here  --> */}
      <div className="explorewrap">
        {/* <img src={process.env.PUBLIC_URL + "/images/left_bg.png"} alt="" /> */}

        <div className="raseticker-page">
          <div className="container">
            <div className="cartPage-heading">
              <h3>Raise a ticket</h3>
              <div className="common_btn">
                {!cartId ? (
                  <>
                    {id !== "0" && (
                      <button
                        className="mr-3"
                        onClick={async () => {
                          await addToCardHandler(true);
                          await onSubmit();
                        }}
                      >
                        Request A Quote
                      </button>
                    )}
                    <button
                      onClick={async () => {
                        await addToCardHandler(false);
                        await onSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="mr-3"
                      onClick={async () => {
                        await addToCardHandler(true);
                        await onSubmit();
                      }}
                    >
                      Update Cart
                    </button>
                    <button
                      onClick={() => {
                        removeCart();
                      }}
                    >
                      Remove Cart
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="explorecont setting_wrap raseticker-wrap">
              <div className="addaddresswrap raiseaticket">
                <div className="row">
                  <div className="col-md-12 col-lg-9">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="common_input_warp">
                          <label htmlFor="">Ticket Type</label>
                          <div className="form-group">
                            <select
                              {...register("type")}
                              className="classic"
                              name=""
                              id=""
                              disabled={id === "0" ? false : true}
                              onChange={handelType}
                              style={{
                                backgroundImage: `url(${process.env.PUBLIC_URL +
                                  "/images/down_arrow.png"
                                  })`,
                                background: id === "0" ? "arow" : "#e9ecef",
                              }}
                            >
                              {ticketCategories.map((cat, index) => (
                                <option key={index} value={cat._id}>
                                  {cat.name}
                                </option>
                              ))}
                            </select>
                            {!!formError.type && (
                              <Error message={"Type is required"} />
                            )}
                          </div>
                        </div>
                      </div>

                      {((type === "buy-now" && id === "0") ||
                        type === "service" ||
                        type === "maintenance") && (
                          <div className="col-md-6">
                            <div className="common_input_warp">
                              <label htmlFor="">Asset Group</label>
                              <div className="form-group">
                                <select
                                  {...register("machine_name")}
                                  className="classic"
                                  name=""
                                  id=""
                                  onChange={handelMachine}
                                  style={{
                                    backgroundImage: `url(${process.env.PUBLIC_URL +
                                      "/images/down_arrow.png"
                                      })`,
                                  }}
                                >
                                  <option value="">Select assets group</option>
                                  {machines.map((machine, index) => (
                                    <option key={index} value={machine._id}>
                                      {machine.name}
                                    </option>
                                  ))}
                                </select>
                                {!!formError.machine_name && (
                                  <Error message={"Assets Group is required"} />
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                      {type === "buy-now" && id !== "0" && (
                        <div className="col-md-6">
                          <div className="common_input_warp">
                            <label htmlFor="">Asset Group</label>
                            <div className="form-group">
                              <input
                                {...register("machine_name")}
                                readOnly
                                type="text"
                                className="form-control"
                                placeholder="Asset Group"
                                name="assets_name"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {type === "buy-now" && id !== "0" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="common_input_warp">
                            <label htmlFor="">Assets Name</label>
                            <div className="form-group">
                              <input
                                {...register("assets_name")}
                                type="text"
                                readOnly
                                className="form-control"
                                placeholder="Assets Name"
                                name="assets_name"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>

                        {serialHandler()}

                        {getPartList()}
                      </div>
                    )}

                    {((type === "buy-now" && id === "0") ||
                      type === "service" ||
                      type === "maintenance") && (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="common_input_warp">
                              <label htmlFor="">Assets Name</label>
                              <div className="form-group">
                                <select
                                  {...register("assets_name")}
                                  className="classic"
                                  name=""
                                  onChange={assetsNameHandler}
                                  id=""
                                  style={{
                                    backgroundImage: `url(${process.env.PUBLIC_URL +
                                      "/images/down_arrow.png"
                                      })`,
                                  }}
                                >
                                  <option value="">Select assets</option>
                                  {machinesAssets.map((access, index) => (
                                    <option key={index} value={access.serialNo}>
                                      {access.name}
                                    </option>
                                  ))}
                                </select>
                                {!!formError.assets_name && (
                                  <Error message={"Assets Name is required"} />
                                )}
                              </div>
                            </div>
                          </div>

                          {serialHandler()}

                          {type !== "service" && (
                            <>
                              <div className="col-md-6">
                                <div className="common_input_warp addaddressbtn">
                                  <label htmlFor="">Part Name</label>

                                  <div className="form-group">
                                    <select
                                      {...register("part")}
                                      className="classic"
                                      name=""
                                      id=""
                                      onChange={(e) => {
                                        setValue("part", e.target.value);
                                        setSelectedPart(e.target.value);
                                      }}
                                      style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL +
                                          "/images/down_arrow.png"
                                          })`,
                                      }}
                                    >
                                      <option value="">Select part name</option>
                                      {assetsParts.map((part) => (
                                        <option key={part._id} value={part.name}>
                                          {part.name}
                                        </option>
                                      ))}
                                    </select>
                                    <a
                                      className="float-lg-right mt-2"
                                      href="javascript:void(0)"
                                      onClick={addPartsIntoList}
                                    >
                                      Add to list
                                    </a>
                                  </div>
                                </div>
                              </div>
                              {getPartList()}
                            </>
                          )}
                        </div>
                      )}

                    {(type === "buy-now" ||
                      type === "service" ||
                      type === "maintenance") && (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="common_input_warp">
                              <label htmlFor="">Expected Delivery Date</label>
                              <div className="form-group">
                                <input
                                  {...register("requestDate")}
                                  type="text"
                                  onFocus={(e) => {
                                    e.target.type = 'date'
                                  }}
                                  onBlur={(e) => {
                                    e.target.type = 'text'
                                  }}
                                  className="form-control"
                                  placeholder="Expected Delivery Date"
                                  name="date"
                                  min={todayDate}
                                  autoComplete="off"
                                  onChange={(e) => {
                                    setValue("requestDate", e.target.value);
                                    updateError("requestDate");
                                  }}
                                />
                                {!!formError.requestDate && (
                                  <Error message={"Date is required"} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    <div className="row">
                      <div className="col-md-6">
                        <div className="common_input_warp">
                          <label htmlFor="">Customer Name</label>
                          <div className="form-group">
                            <input
                              {...register("customer_name")}
                              type="text"
                              className="form-control"
                              placeholder="Customer Name"
                              name="customer_name"
                              autoComplete="off"
                              onChange={() => {
                                updateError("customer_name");
                              }}
                            />
                            {!!formError.customer_name && (
                              <Error message={"Customer name is required"} />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="common_input_warp">
                          <label htmlFor="">Contact Details</label>
                          <div className="form-group">
                            <Controller
                              control={control}
                              name="phone"
                              rules={{ required: true }}
                              render={({ field: { ref, ...field } }) => (
                                <ReactPhoneInput
                                  {...field}
                                  inputClass="form-control intl-tel-input-custom w-100"
                                  inputExtraProps={{
                                    ref,
                                    required: true,
                                    autoFocus: true,
                                  }}
                                  isValid={(value, country) => {
                                    if (value.match(/12345/)) {
                                      return (
                                        "Invalid value: " +
                                        value +
                                        ", " +
                                        country.name
                                      );
                                    } else if (value.match(/1234/)) {
                                      return false;
                                    } else {
                                      return true;
                                    }
                                  }}
                                  country={countryCode}
                                  countryCodeEditable={false}
                                  specialLabel={"Player Mobile Number"}
                                  enableSearch={true}
                                />
                              )}
                            />
                            {!!errors.phone && (
                              <Error message={errors.phone.message} />
                            )}
                            {!formError.phone && !!formError.phoneValid && (
                              <Error message={"Please enter valid number"} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="common_input_warp addaddressbtn">
                          <label htmlFor="">Complete Address</label>
                          <Modal
                            title="New address"
                            onClose={() => setShow(false)}
                            show={show}
                            type="address"
                            width="700"
                          >
                            <AddAddress closeModal={closeModal} />
                          </Modal>
                          <a
                            className="float-lg-right"
                            href="javascript:void(0)"
                            onClick={() => setShow(true)}
                          >
                            New Address
                          </a>
                          <div className="form-group">
                            <select
                              {...register("address")}
                              className="classic"
                              name=""
                              id=""
                              disabled={!address.length}
                              onChange={changeAddress}
                              style={{
                                backgroundImage: `url(${process.env.PUBLIC_URL +
                                  "/images/down_arrow.png"
                                  })`,
                              }}
                            >
                              {address.map((ele, index) => (
                                <option key={index} value={ele._id}>
                                  {ele.first_name +
                                    ", " +
                                    ele.last_name +
                                    ", " +
                                    ele.address +
                                    ", " +
                                    ele?.city +
                                    ", " +
                                    ele?.state +
                                    ", " +
                                    ele?.pin_code}
                                </option>
                              ))}
                            </select>
                            {!!formError.address && (
                              <Error message={"Address is required"} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {!!Object.keys(selectAddress).length && (
                      <div className="row">
                        <div className="col-md-12 col-lg-6">
                          <div className="setting_wrap addresscontwrap addressdatawrap">
                            <ul>
                              <li>
                                <div className="addresstypesvg">
                                  {selectAddress.address_type == "1" ? (
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/home.png"
                                      }
                                      alt=""
                                    />
                                  ) : selectAddress.address_type == "2" ? (
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/office.png"
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/other.png"
                                      }
                                      alt=""
                                    />
                                  )}
                                </div>

                                <div className="addresscont">
                                  <h2>
                                    {selectAddress.first_name}{" "}
                                    {selectAddress.last_name}
                                  </h2>
                                  <p>
                                    {selectAddress.address +
                                      ", " +
                                      selectAddress?.city +
                                      ", " +
                                      selectAddress?.state}
                                    <br />
                                    {selectAddress?.pin_code}
                                  </p>
                                  {selectAddress.default_address && (
                                    <label htmlFor="">Default Address</label>
                                  )}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}

                    {(type === "service" || type === "maintenance") && (
                      <div className="row request-wrap">
                        <div className="col-md-12">
                          <div className="common_input_warp">
                            <label htmlFor="">For Request</label>
                            <div className="form-group labeldiv">
                              <input
                                {...register("forRequest")}
                                type="radio"
                                id="On_call"
                                name="request"
                                onChange={(e) => {
                                  setValue("forRequest", e.target.value);
                                  updateError("forRequest");
                                }}
                                value="1"
                              />
                              <label htmlFor="On_call">On Call</label>
                              <br />
                              <input
                                type="radio"
                                id="On_site"
                                name="request"
                                {...register("forRequest")}
                                onChange={(e) => {
                                  setValue("forRequest", e.target.value);
                                  updateError("forRequest");
                                }}
                                value="2"
                              />
                              <label htmlFor="On_site">On Site</label>
                              <br />
                              <input
                                type="radio"
                                id="In_Facility_Service"
                                name="request"
                                {...register("forRequest")}
                                onChange={(e) => {
                                  setValue("forRequest", e.target.value);
                                  updateError("forRequest");
                                }}
                                value="3"
                              />
                              {"  "}
                              <label htmlFor="In_Facility_Service">
                                In Facility Service
                              </label>
                              <br />
                              {!!formError.forRequest && (
                                <Error message={"Required"} />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="common_input_warp">
                            <label htmlFor="">Under Warranty</label>
                            <div className="form-group labeldiv">
                              <input
                                {...register("warranty")}
                                type="radio"
                                id="yes"
                                name="warranty"
                                disabled
                                checked={inputFieldValue === 1 ? true : false}
                              />
                              <label htmlFor="yes">Yes</label>
                              <br />
                              <input
                                {...register("warranty")}
                                type="radio"
                                id="no"
                                name="warranty"
                                disabled
                                checked={!!inputFieldValue ? inputFieldValue !== 1 ? true : false : ''}
                              />
                              <label htmlFor="no">No</label>
                              <br />
                              {!!formError.warranty && (
                                <Error message={"Required"} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {type === "default" && (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="common_input_warp">
                            <label htmlFor="">Nearby Area</label>
                            <div className="form-group">
                              <input
                                {...register("nearBy")}
                                type="text"
                                className="form-control"
                                placeholder="Area"
                                name="nearBy"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="common_input_warp">
                          <label htmlFor="">Comment</label>
                          <div className="form-group">
                            <textarea
                              {...register("comment")}
                              className="form-control"
                              placeholder="Write something here.."
                              name="comment"
                              onChange={() => {
                                updateError("comment");
                              }}
                              id=""
                              cols="30"
                              rows="10"
                            ></textarea>
                            {!!formError.comment && type !== "buy-now" && (
                              <Error message={"Comment is required"} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Explore Section CSS End Here  --> */}

      <div
        className="modal fade modal-center"
        id="remove-part-popup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      // aria-hidden="true"
      >
        <div className="modal-dialog custom_modal_wrap" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="logoutpopupwrap">
                <div className="like_img">
                  <img src="images/logout_icon.png" alt="" />
                </div>
                <h2>Remove</h2>
                <p>Are you sure you want to remove?</p>
                <div className="common_btn">
                  <button
                    type="button"
                    data-dismiss="modal"
                    onClick={removePart}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    data-dismiss="modal"
                    onClick={removePartModal}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(RaiseTicket);
