import React from "react";
import "../styles/pages/comming-page.css";

const GeoLocation = () => {
    return (
        <div className="comming-page explorewrap">
        <div className="commingpage-wrap">
          <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="page-content">
                <div className="left-Aboutimg">
                  <img src="images/logo_ergin.jpg" alt="" />
                </div>
                <div className="page-name-heading">
                  <div className="heading-icon geoicon">
                    <img src="images/geolocation.png" alt="" />
                  </div>
                  <h4>Geo Location</h4>
                </div>
                <div className="content-wrap">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown   
                    printer took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting, remaining
                    essentially unchanged. It was pop
                  </p>
                  <div className="content-point">
                      <ul>
                          <li> Lorem Ipsum is simply dummy text of the printing and</li>
                          <li> Lorem Ipsum is simply dummy text of the printing and</li>
                          <li> Lorem Ipsum is simply dummy text of the printing and</li>
                          <li> Lorem Ipsum is simply dummy text of the printing and</li>
                          <li> Lorem Ipsum is simply dummy text of the printing and</li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="coming-imge">
              <img src="images/geo-com.png" alt="" /> 
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    )
}

export default GeoLocation;