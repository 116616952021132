import React, { useEffect } from "react";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";

import { Redirect, Route, useLocation } from "react-router-dom";
import { userInfo } from "../utils/helpers";
import { hasRole } from "../auth-helpers";
import InnerHeader from "../components/common/InnerHeader";
import useConfiguratorAndPredicative from "../hooks/useConfiguratorAndPredicative";

const PrivateRoute = (props) => {
  const location = useLocation();
  const { component: Component, path, innerHeaderShow = true, roles, ...rest } = props;

  const shouldRender = props.computedMatch.path === path;
  const currentUser = userInfo();
  const { status, fetchConfiguratorAndPredicative } = useConfiguratorAndPredicative();

  useEffect(() => {
    if (
      document.getElementById("dropdown-menu")?.classList.contains("show") &&
      document.getElementById("dropdown-toggle")?.classList?.contains("show")
    ) {
      document.getElementById("dropdown-menu")?.classList?.remove("show");
      document.getElementById("dropdown-toggle")?.classList?.remove("show");
    }
  }, [location.pathname]);

  // TODO: Handle the scenario when User is present but with expired token

  if (!currentUser) return <Redirect to={"/login"} />;

  // check if route is restricted by role
  if (roles && hasRole(roles)) {
    return <Redirect to={{ pathname: '/' }} />
  }

  if (!shouldRender) return null;

  if (location.pathname === '/categories-assets-groups' && !status?.configuratorMain) { return <Redirect to={{ pathname: '/' }} /> }
  if (location.pathname === '/predictive-maintenance' && !status?.predictiveMain) { return <Redirect to={{ pathname: '/' }} /> }

  return (
    <React.Fragment>
      <Header />
      {!!innerHeaderShow && <InnerHeader />}
      <Route {...rest} component={Component} />
      <Footer />
    </React.Fragment>
  );
};

export default PrivateRoute;
