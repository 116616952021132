import React from "react";
import BaseWrapper from "../components/auth-flow/BaseWrapper";
import ResetPasswordForm from "../components/auth-flow/ResetPassword";
import TitleSetter from "../components/common/TitleSetter";

const ResetPassword = () => {
  return (
    <div>
      <TitleSetter title={"Reset Password"} />
      <BaseWrapper
        FormComponent={ResetPasswordForm}
        backgroundImageURL={`url(${process.env.PUBLIC_URL + "/images/loginbg.png"})`}
      />
    </div>
  );
};

export default ResetPassword;
