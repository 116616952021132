import { useEffect, useState } from "react";
import Detail from "../components/structure/Detail";
import { callBackendAPI } from "../utils/helpers";
import { REST_API_METHODS } from "../utils/constants";
import TitleSetter from "../components/common/TitleSetter";

const TermsAndConditions = () => {
  const [description, setDescription] = useState("");

  useEffect(() => {
    getTermsAdnConditionsDetails();
  }, []);

  const getTermsAdnConditionsDetails = async () => {
    try {
      const response = await callBackendAPI(
        "getContents?type=3",
        REST_API_METHODS.GET
      );
      if (!!response.status) {
        setDescription(response.data.contents);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <TitleSetter title={"Terms and Conditions"} />
      <Detail title={"Terms & Conditions"} description={description} />
    </>
  );
};

export default TermsAndConditions;
