import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faTrash } from "@fortawesome/free-solid-svg-icons";
import { callBackendAPI, showMessage, userInfo } from "../utils/helpers";
import { REST_API_METHODS } from "../utils/constants";

import "../styles/pages/my_profile.css";
import TitleSetter from "../components/common/TitleSetter";
import Modal from "../modal/Modal";
import AddAddress from "../pages/AddAddress";
import IsLoadingHOC from "../components/common/IsLoadingHOC";
import { usePrime } from "../hooks";

const tabIDs = {
  profile: ["home", "home-tab"],
  address: ["contact", "contact-tab"],
};

let selectAddress;

const MyProfile = (props) => {
  const { setLoading } = props;

  const [userProfile, setUserProfile] = useState({});
  const [userAddress, setUserAddress] = useState([]);
  const [show, setShow] = useState(false);
  const [editId, setEditId] = useState(0);
  const { isPrime } = usePrime();

  useEffect(() => {
    getUserProfile();
    getAddress();
  }, []);

  const getUserProfile = async () => {
    try {
      setLoading(true);
      const user = userInfo();
      const response = await callBackendAPI(
        `getUserProfile?id=${user._id}`,
        REST_API_METHODS.GET
      );
      if (!!response.status) {
        setUserProfile(response.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getAddress = async () => {
    try {
      setLoading(true);
      const user = userInfo();
      const response = await callBackendAPI(
        `getAddress?userId=${user._id}`,
        REST_API_METHODS.GET
      );
      if (!!response.status) {
        setUserAddress(response.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };


  const toggleDeleteAddressModal = (item = null) => {
    selectAddress = item;
    const modalElement = document.getElementById("delete-address-popup");
    const isModalOpen = document
      .getElementById("delete-address-popup")
      .classList.contains("show");

    if (isModalOpen) {
      document.body.classList.remove("modal-open");
      modalElement?.classList.remove("show");
      modalElement.style.display = "none";
    } else {
      document.body.classList.add("modal-open");
      modalElement?.classList.add("show");
      modalElement.style.display = "block";
    }
  };

  const deleteAddress = async () => {
    try {
      const response = await callBackendAPI(
        `deleteAddress?id=${selectAddress._id}`,
        REST_API_METHODS.DELETE
      );
      if (!!response.status) {
        const index = userAddress.findIndex((a) => a._id === selectAddress._id);
        if (index > -1) {
          userAddress.splice(index, 1);
        }
        showMessage(response.message);
        setUserAddress([...userAddress]);
        toggleDeleteAddressModal();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const closeModal = (event) => {
    getAddress();
    setShow(false);
  };

  const profileDetails = () => {
    return (
      <div
        className="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div className="prodfile_wrap">
          <div className="row ">
            <div className="col-md-12 col-lg-3">
              <div className="userimagewrap">
                <div className="userimage">
                  <img src={userProfile?.image || "images/user_img.jpg"} alt="" />
                  {isPrime && <div className="staruser">
                    <FontAwesomeIcon icon={faCrown} />
                  </div>}
                </div>
                <p className="text-capitalize">{userProfile.name}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="setting_wrap profilecard">
                <ul>
                  <li>
                    <a href="javascript:void(0);" className="user-profile">
                      <span className="contacticon">
                        <img src="images/iconemail.png" alt="" />
                      </span>
                      {userProfile.email}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-2" >
              <div className="setting_wrap profilecard">
                <ul>
                  <li>
                    <a href="javascript:void(0);">
                      <span className="contacticon">
                        <img src="images/iconmobile.png" alt="" />
                      </span>
                      {userProfile.phone}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="address-user profilecard">
                <ul>
                  <li>
                    {userProfile?.address &&
                      <>
                        <span className="contacticon">
                          <img src="images/gps.png" alt="" />
                        </span>
                        <span>
                          {userProfile?.address?.address},
                          {userProfile?.address?.city},
                          {userProfile?.address?.state},
                          {userProfile?.address?.country},
                          {userProfile?.address?.pin_code}
                        </span>
                      </>
                    }
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  };

  const addressDetails = () => {
    return (
      <div className="setting_wrap addresscontwrap">
        <div className="row">
          <div className="col-md-12">
            <div className="savedaddress-detail">
              <h3>Saved Address</h3>
              <a href="javascript:void(0);" onClick={() => {
                setEditId(0);
                setShow(true);
              }}> + Add New</a>
            </div>
            <div className="address-div">
              <div className="row">
                <div className="col-md-12">
                  <ul>
                    {userAddress.map((item, key) => {
                      return (
                        <li key={key}>
                          {/* <span>
                            {item.address_type == "1" ? (
                              <img
                                src={process.env.PUBLIC_URL + "/images/home.png"}
                                alt=""
                              />
                            ) : item.address_type == "2" ? (
                              <img
                                src={process.env.PUBLIC_URL + "/images/office.png"}
                                alt=""
                              />
                            ) : (
                              <img
                                src={process.env.PUBLIC_URL + "/images/other.png"}
                                alt=""
                              />
                            )}
                          </span> */}
                          <div className="addresscont">
                            {item.default_address === true && (
                              <label>Default Address</label>
                            )}
                            <h2 className="text-capitalize">
                              {item.first_name} {item.last_name}
                            </h2>
                            <p>
                              {item.address}, {item.city}, {item.state}, <br />{" "}
                              {item.pin_code}
                            </p>

                          </div>
                          <div className="addressaction">
                            {/* <Link to={`/edit-address/${item._id}`}> */}
                            <a
                              href="javascript:void(0)"
                              onClick={() => {
                                setShow(true);
                                setEditId(item._id);
                              }}
                            >
                              <svg id="edit" xmlns="http://www.w3.org/2000/svg" width="18.372" height="18.373" viewBox="0 0 18.372 18.373">
                                <path id="Path_16073" data-name="Path 16073" d="M20.268.76a.884.884,0,0,0-1.256,0l-6.75,6.75a.892.892,0,0,0-.225.373l-1.1,3.68a.886.886,0,0,0,.225.883.874.874,0,0,0,.628.261.945.945,0,0,0,.255-.036l3.68-1.1a.891.891,0,0,0,.373-.225l6.75-6.75a.887.887,0,0,0,0-1.256Z" transform="translate(-4.735 -0.497)" fill="#1e3e7b" />
                                <path id="Path_16074" data-name="Path 16074" d="M17.983,8.8a.889.889,0,0,0-.889.889v5.926a1.483,1.483,0,0,1-1.482,1.482H3.759a1.483,1.483,0,0,1-1.482-1.482V3.759A1.483,1.483,0,0,1,3.759,2.278H9.686A.889.889,0,1,0,9.686.5H3.759A3.263,3.263,0,0,0,.5,3.759V15.612a3.263,3.263,0,0,0,3.259,3.259H15.612a3.263,3.263,0,0,0,3.259-3.259V9.686A.889.889,0,0,0,17.983,8.8Z" transform="translate(-0.5 -0.499)" fill="#1e3e7b" />
                              </svg>

                            </a>
                            {/* </Link> */}
                            <a
                              href="javascript:void(0)"
                              onClick={() => toggleDeleteAddressModal(item)}
                            >
                              <FontAwesomeIcon
                                className="delete"
                                icon={faTrash}
                                color="red" />
                            </a>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <TitleSetter title={"Profile"} />
      <div className="explorewrap profile-page">
        {/* <img src="images/left_bg.png" alt="" /> */}
        <div className="container">

          <div className="cartPage-heading">
            <h3>My Profile</h3>
            <div className="profilewrapdiv">
              {profileDetails()}
            </div>
          </div>
          <div className="explorecont mypforiletabs">


            {addressDetails()}
          </div>
        </div>
        <Modal
          title={editId ? "Edit Address" : "Add New Address"}
          onClose={() => setShow(false)}
          show={show}
          type="address"
        >
          <AddAddress closeModal={closeModal} editId={editId} show={show} />
        </Modal>
      </div>
      <div
        className="modal fade modal-center"
        id="delete-address-popup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      // aria-hidden="true"
      >
        <div className="modal-dialog custom_modal_wrap" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="logoutpopupwrap">
                <h2>Delete Address</h2>
                <p>Are you sure you want to delete this address?</p>
                <div className="common_btn">
                  <button
                    type="button"
                    data-dismiss="modal"
                    onClick={deleteAddress}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    data-dismiss="modal"
                    onClick={toggleDeleteAddressModal}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(MyProfile);
