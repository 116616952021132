import React, { useEffect, useState } from "react";
import "../styles/pages/location.css";
import $ from "jquery";
import TitleSetter from "../components/common/TitleSetter";
import IsLoadingHOC from "../components/common/IsLoadingHOC";
import { callBackendAPI, userInfo } from "../utils/helpers";
import { REST_API_METHODS } from "../utils/constants";
import OverlappingMarkerSpiderfier from "overlapping-marker-spiderfier";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

const defaultUrl = "images/user.png";

const UserLocations = (props) => {
  const { setLoading } = props;
  const { lat, long } = useParams();
  const user = userInfo();

  const google = window.google || {};
  const [mapView, setMapView] = useState('');

  let map;
  const currentPosition = {
    lat: 40.20846,
    lng: -100.2910906,
  };

  // currentPostion, {
  //     lat: 28.628148820134044,
  //     lng: 77.37604431167111
  // }

  const [type, setType] = useState("");
  const [headerDetails, setHeaderDetails] = useState("");
  const [locationBody, setLocationBody] = useState([]);
  const [machines, setMachines] = useState([]);
  const [parentUser, setParentUser] = useState({});
  const [subUsers, setSubUsers] = useState([]);

  useEffect(() => {
    initMap();
    getAssignUserLocation();
  }, []);

  const initMap = () => {
    // The map, centered at Uluru
    map = new google.maps.Map(document.getElementById("map-view"), {
      zoom: 5,
      minZoom: 3,
      center: currentPosition,
      gestureHandling: "greedy",
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      zoomControl: true,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
      ],
      // mapTypeId: "satellite",
    });
    // setMap(mapView);
    // setMarker([currentPostion]);
  };

  // The marker, positioned at Uluru
  const setMarker = (locations) => {
    const options = {
      legWeight: 2, // Just an example of options - please set your own if necessary
      markersWontMove: true,
      markersWontHide: true,
      basicFormatEvents: true,
      keepSpiderfied: true,
      spiderfiedShadowColor: false,
      lineToCenter: true,
      minZoomLevel: true,
      circleSpiralSwitchover: 19,
      circleFootSeparation: 23,
      spiralFootSeparation: 26,
      spiralLengthStart: 21,
      spiralLengthFactor: 10,

      // nudgeStackedMarkers: false,
      // minNudgeZoomLevel : 1,
      nearbyDistance: 30, //in pixels
    };
    setMapView(map);
    var markerSpiderfier = new OverlappingMarkerSpiderfier(map, options);
    var markers = [];
    for (var i = 0; i < locations.length; i++) {
      const data = locations[i].data;
      var latitude = locations[i].position.lat,
        longitude = locations[i].position.lng;
      var latLng = new google.maps.LatLng(latitude, longitude);

      var marker = new google.maps.Marker({
        position: latLng,
        map: map,
        icon: {
          url: locations[i].url,
          scaledSize: new google.maps.Size(33, 28),
          origin: new google.maps.Point(0, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
          anchor: new google.maps.Point(17, -1),
        },
        title: data.name,
        data,
      });
      markers.push(marker);
      markerSpiderfier.addMarker(marker);
    }

    // Add a marker clusterer to manage the markers.
    new MarkerClusterer({ markers, map });

    var iw = new google.maps.InfoWindow({ content: "" });
    markerSpiderfier.addListener("click", function (marker, event) {
      // iw.setContent(marker.title);
      // iw.open(map, marker);
      // $('.map-view').addClass('map-view-resize');
      // $('.show-user-details').css('display', 'block');
      const data = marker.data;
      getLocationDetails(data);
      // const updateObj = _.omit(data, ['_id', 'userId', 'code_phone', 'address_type', 'default_address', 'status', 'createdAt', 'create_for', 'image', 'latitude', 'longitude'])
      // const filterObj = _.pickBy(updateObj, _.identity); // remove null and undefine values

      // const addressKeysAndValue = Object.entries(filterObj).map(([key, value]) => {
      //     const updateKey = key.split('_').join(' ');
      //     return { key: updateKey, value };
      // });

      // setImage(data.image || defaultUrl);
      // setAddressDetails(addressKeysAndValue);
      // console.log('map data', filterObj);
      // console.log('click on service event', event.latLng.lat(), event.latLng.lng());
    });

    markerSpiderfier.addListener("spiderfy", function (markers) {
      iw.close();
    });
    showMapPin(map, { latitude: lat, longitude: long });

    // var markerCluster = new MarkerClusterer(map, markers, { imagePath: '...' });
    // markerCluster.setMaxZoom(15);

    //         markers.forEach((item, i) => {
    //             console.log('item.position', item.position)
    //             const label = labels[i % labels.length];

    //             // const marker = new google.maps.Marker({
    //             //     position: item.position,
    //             //     label,
    //             //     animation: google.maps.Animation.DROP,
    //             //     map: map,
    //             //     // icon: {
    //             //     //     url: item.url,
    //             //     //     scaledSize: new google.maps.Size(33, 28),
    //             //     //     origin: new google.maps.Point(0, 0),
    //             //     //     // The anchor for this image is the base of the flagpole at (0, 32).
    //             //     //     anchor: new google.maps.Point(17, -1)
    //             //     // },
    //             //     data: item.data
    //             // });
    //
    //             // Set the coordonates of the new point
    //             var latLng = new google.maps.LatLng(item.position.lat, item.position.lng);

    //             //Check Markers array for duplicate position and offset a little
    //             if (markers.length != 0) {
    //                 for (i = 0; i < markers.length; i++) {
    //                     var existingMarker = markers[i];
    //                     var pos = existingMarker.getPosition();
    //                     if (latLng.equals(pos)) {
    //                         var a = 360.0 / markers.length;
    //                         var newLat = pos.lat() + -.00004 * Math.cos((+a * i) / 180 * Math.PI);  //x
    //                         var newLng = pos.lng() + -.00004 * Math.sin((+a * i) / 180 * Math.PI);  //Y
    //                         var latLng = new google.maps.LatLng(newLat, newLng);
    //                     }
    //                 }
    //             }

    //             // Initialize the new marker
    //             var marker = new google.maps.Marker({ map: map, position: latLng, title: item['TITLE'] });

    //             // markers can only be keyboard focusable when they have click listeners
    //             // open info window when marker is clicked
    //             marker.addListener("click", () => {
    //                 // infoWindow.setContent(label);
    //                 infoWindow.open(map, marker);
    //             });

    //             // click on service provide icon
    //             google.maps.event.addListener(marker, 'click', function (event) {

    //                 $('.map-view').addClass('map-view-resize');
    //                 $('.show-user-details').css('display', 'block');

    //                 const updateObj = _.omit(this.data, ['_id', 'userId', 'code_phone', 'address_type', 'default_address', 'status', 'createdAt', 'create_for', 'image', 'latitude', 'longitude'])
    //                 const filterObj = _.pickBy(updateObj, _.identity); // remove null and undefine values

    //                 const addressKeysAndValue = Object.entries(filterObj).map(([key, value]) => {
    //                     const updateKey = key.split('_').join(' ');
    //                     return { key: updateKey, value };
    //                 });

    //                 setImage(this.data.image || defaultUrl);
    //                 setAddressDetails(addressKeysAndValue);
    //                 console.log('map data', filterObj);
    //                 console.log('click on service event', event.latLng.lat(), event.latLng.lng());
    //             });
    //         });
  };

  const getLocationDetails = async (data) => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getLocationDetails?subUserId=${data.userId}&serial_No=${data.serial_No}&type=${data.create_for}&parentId=${data.user}`,
        REST_API_METHODS.GET
      );
      if (!!response.status && !!response.data.length) {
        $(".map-view").addClass("map-view-resize");
        $(".show-user-details").css("display", "block");
        const responseData = response.data;

        console.log("responseData", responseData);
        if (data.type === "user") {
          const user = responseData[0].user || responseData[0];
          setHeaderDetails({ ...headerDetails, ...user });
        } else {
          const machine = responseData[0].machine || {};
          machine.serialNo = responseData[0].serialNo || "N/A";
          machine.name = responseData[0].assets.name || "N/A";
          setHeaderDetails({ ...headerDetails, ...machine });
        }

        const create_for = data.create_for == 'sub-user' ? 'user' : data.create_for;
        setType(create_for);
        setLocationBody([...locationBody, ...responseData]);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getAssignUserLocation = async () => {
    try {
      setLoading(true);
      const create_for = user?.role[0]?.name === 'assign-user' ? 'sub-user' : 'user';
      const response = await callBackendAPI(
        `getAssignUserLocation?id=${user._id}&create_for=${create_for}`,
        REST_API_METHODS.GET
      );
      if (!!response.status) {
        let subUser = [];
        const machines = response.data.filter(a => a.create_for === 'machine');
        const mainUser = response.data.filter(a => a.create_for === create_for);
        if (create_for !== 'sub-user') {
          subUser = response.data.filter(a => a.create_for === 'sub-user');
        }

        const activeUser = mainUser[0] || {}
        setMachines(machines);
        setParentUser(activeUser);
        setSubUsers(subUser);
        const markers = response.data.map((a) => {
          return {
            data: a,
            position: {
              lat: parseFloat(a.latitude),
              lng: parseFloat(a.longitude),
            },
            url: a.image || defaultUrl,
          };
        });
        setMarker(markers);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const showMapPin = (mapView, a) => {
    if (!!a.latitude && !!a.longitude) {
      mapView.zoom = 18;
      mapView.setCenter({ lat: parseFloat(a.latitude), lng: parseFloat(a.longitude) });
    }
  }

  const closeView = () => {
    $(".map-view").removeClass("map-view-resize");
    $(".show-user-details").css("display", "none");
  };

  return (
    <>
      <TitleSetter title={"Equipment Geo Location"} />
      <div className="map-section">
        <div className="row">
          <div className="user-machine-info-tabs">
            <div className="sideuser-img">
              <img className="cursor-pointer w-100" src={parentUser?.image} onClick={() => showMapPin(mapView, parentUser)} />
              <div className="star-user">
                <FontAwesomeIcon icon={faCrown} />
              </div>
            </div>
            <div className="sideuser-info">
              <h5>{parentUser.name}</h5>
            </div>
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-toggle="tab"
                  href="#tabs-1"
                  role="tab"
                >
                  Sub Users
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">
                  Assets
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="tabs-1" role="tabpanel">
                <div className="user-info-list">
                  <ul>
                    {subUsers.map((a, index) => (
                      <li className="cursor-pointer" key={index} onClick={() => showMapPin(mapView, a)}>
                        <div className="user-information-div">
                          <div className="user-image">
                            <img src={a.image} alt="" />
                          </div>
                          <div className="user-dtl" >
                            <p>{a.name}</p>
                            <span>{a.address}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div class="tab-pane" id="tabs-2" role="tabpanel">
                <div className="user-info-list machine-info-list">
                  <ul>
                    {machines.map((a, index) => (
                      <li className="cursor-pointer" key={index} onClick={() => showMapPin(mapView, a)}>
                        <div className="user-information-div">
                          <div className="user-image">
                            <img src={a.image} alt="" />
                          </div>
                          <div className="user-dtl">
                            <p>{a.assets}</p>
                            <span>{a.serial_No}</span>
                            <br />
                            <span>{a.address}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="map-view" id="map-view" style={{ height: "85vh" }} />
          <div className="show-user-details">
            <div className="p-3 mapInfo-user">
              <button onClick={closeView}>
                <img src="images/backarrow.png" alt="" />
              </button>
              <div className={`sideuser-img ${type === "user" ? 'star-circle' : ''} w-auto`}>
                <img src={headerDetails.image} height={40} width={40} />
                {type === "user" && (
                  <div className="star-user">
                    <FontAwesomeIcon icon={faCrown} />
                  </div>
                )}
              </div>
              <div className="sideuser-info">
                <h5>{headerDetails.name}</h5>
                {(type === "user" || type === "sub-user") ? (
                  <div>
                    <span>{headerDetails.email}</span>
                    <span>{headerDetails.phone}</span>
                  </div>
                ) : (
                  <div>
                    <strong>Serial No : </strong> {headerDetails?.serialNo}
                  </div>
                )}
              </div>
            </div>
            <div className="bg-white locationUser-table">
              <ul>
                {locationBody.map((location, index) => (
                  <li key={index}>
                    {(type === "user" || type === "sub-user") ? (
                      <div className="machine-info">
                        {location?.machine && (
                          <>
                            {/* <span className="dtl-span">
                              <strong>Assets Group Name : </strong>{" "}
                              {location?.machine?.name}
                            </span> */}
                            <span className="dtl-span">
                              <strong>Assets Name : </strong>{" "}
                              {location?.assets?.name}
                            </span>
                            <span className="dtl-span">
                              <strong>Serial No : </strong> {location?.serialNo}
                            </span>
                          </>
                        )}
                        <span className="dtl-span">
                          <strong>Address : </strong>{" "}
                          {location?.address?.address || 'N/A'}
                        </span>
                      </div>
                    ) : (
                      <div className="machine-info">
                        {location?.user && (
                          <span className="dtl-span">
                            {" "}
                            <strong>User Name :</strong> {location?.user?.name}
                          </span>
                        )}
                        <span className="dtl-span">
                          <strong>Machine Address : </strong>{" "}
                          {location?.address?.address}
                        </span>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(UserLocations);
