import React, { useEffect } from "react";
import "./Modal.css";
import $ from 'jquery';

const Modal = (props) => {

    useEffect(() => {
        if (props.show) {
            $('#model-open').modal('show');
        } else {
            $('#model-open').modal('hide');
        }
    }, [props.show]);

    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        // document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        // return function cleanup() {
        //     document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        // };
    }, []);

    return (
        <div
            className="modal fade modal-center bd-example-modal-lg"
            id="model-open"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            // onClick={props.onClose}
            aria-hidden="true"
            data-backdrop="static" data-keyboard="false"
        >
            <div className="modal-dialog custom_modal_wrap modal-lg new-address-modal" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="logoutpopupwrap">
                            <button type="button" className="close" onClick={props.onClose}>&times;</button>
                            <h2>{props.title}</h2>
                            <div>{props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Modal;
