import { useEffect } from 'react';

const useScrollTop = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, []);
};

export default useScrollTop;
