import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import $ from 'jquery';
import { callBackendAPI, viewSupport } from "../../utils/helpers";
import { REST_API_METHODS } from '../../utils/constants';
import useConfiguratorAndPredicative from '../../hooks/useConfiguratorAndPredicative';

function InnerHeader() {
  const location = useLocation();
  const { status, fetchConfiguratorAndPredicative } = useConfiguratorAndPredicative();

  useEffect(() => {
    $("#innerNavMenus").on('click', 'li', function () {
      $('#innerNavMenus li.active').removeClass('active');
      $(this).addClass("active");  // adding active class
    });
  }, []);

  useEffect(() => {
    $('#innerNavMenus a[href^="https://"]').each(function () {
      var url = $(this).attr("href"); // Get current url
      const macthUrl = '/' + url.split('/#/')[1];
      if (macthUrl == location.pathname) {
        $(this).parent('li').addClass("active");
      }
    });
  }, [location.pathname]);

  return (
    <div>
      <div className="headerwap">
        <div className="inner-page-list">
          <ul id="innerNavMenus">
            <li>
              <Link to={'/assets-list'}>
                <span className="sm-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31.9"
                    height="31.81"
                    viewBox="0 0 31.9 31.81"
                  >
                    <g
                      id="Group_262619"
                      data-name="Group 262619"
                      transform="translate(-189.819 -471.662)"
                    >
                      <ellipse
                        id="Ellipse_37"
                        data-name="Ellipse 37"
                        cx="15.95"
                        cy="15.643"
                        rx="15.95"
                        ry="15.643"
                        transform="translate(189.819 472.186)"
                        fill="#e1f0ff"
                      />
                      <g
                        id="Group_262432"
                        data-name="Group 262432"
                        transform="translate(190.433 471.662)"
                      >
                        <path
                          id="Path_11142"
                          data-name="Path 11142"
                          d="M270.838,184.3v2.108h2.108v-.937h-1.171V184.3Z"
                          transform="translate(-259.317 -166.209)"
                          fill="#1e3e7b"
                        />
                        <path
                          id="Path_11143"
                          data-name="Path 11143"
                          d="M272.947,134.247h-2.108v2.108h.937v-1.171h1.171Z"
                          transform="translate(-259.317 -122.725)"
                          fill="#1e3e7b"
                        />
                        <path
                          id="Path_11144"
                          data-name="Path 11144"
                          d="M352.257,224.384h8.49V215.7h-8.49ZM356.5,218.1a1.942,1.942,0,1,1-1.94,1.94A1.945,1.945,0,0,1,356.5,218.1Z"
                          transform="translate(-330.043 -193.484)"
                          fill="#1e3e7b"
                        />
                        <path
                          id="Path_11145"
                          data-name="Path 11145"
                          d="M191.593,46.512H183.1V55.2h8.49Zm-4.245,6.353a1.94,1.94,0,1,1,1.94-1.94A1.944,1.944,0,0,1,187.348,52.865Z"
                          transform="translate(-183.103 -46.512)"
                          fill="#1e3e7b"
                        />
                        <path
                          id="Path_11146"
                          data-name="Path 11146"
                          d="M263.467,69.6h14.445a.47.47,0,0,1,.469.469v6.485a.47.47,0,0,1-.469.469H275.5a1.4,1.4,0,0,0-1.406,1.406v.937a1.407,1.407,0,0,0,1.406,1.406h2.413a4.223,4.223,0,0,0,4.217-4.217V70.067a4.223,4.223,0,0,0-4.217-4.217H263.467a1.407,1.407,0,0,0-1.406,1.406v.937A1.4,1.4,0,0,0,263.467,69.6Z"
                          transform="translate(-251.692 -63.311)"
                          fill="#1e3e7b"
                        />
                        <path
                          id="Path_11147"
                          data-name="Path 11147"
                          d="M190.745,150.908a4.22,4.22,0,0,0-4.217,4.217v6.48a4.223,4.223,0,0,0,4.217,4.217h13.981a1.41,1.41,0,0,0,1.406-1.406v-.937a1.41,1.41,0,0,0-1.406-1.406H190.745a.47.47,0,0,1-.469-.469v-6.48a.47.47,0,0,1,.469-.468h3.6a1.407,1.407,0,0,0,1.406-1.406v-.937a1.4,1.4,0,0,0-1.406-1.406Z"
                          transform="translate(-186.078 -137.199)"
                          fill="#1e3e7b"
                        />
                        <path
                          id="Path_11148"
                          data-name="Path 11148"
                          d="M321.505,134.246H319.4v.937h1.171v1.171h.937Z"
                          transform="translate(-301.499 -122.725)"
                          fill="#1e3e7b"
                        />
                        <path
                          id="Path_11149"
                          data-name="Path 11149"
                          d="M288.356,154.458a1.942,1.942,0,1,0,1.94-1.944A1.944,1.944,0,0,0,288.356,154.458Z"
                          transform="translate(-274.534 -138.594)"
                          fill="#1e3e7b"
                        />
                        <path
                          id="Path_11150"
                          data-name="Path 11150"
                          d="M320.568,185.475H319.4v.937h2.108V184.3h-.937Z"
                          transform="translate(-301.499 -166.209)"
                          fill="#1e3e7b"
                        />
                      </g>
                    </g>
                  </svg>
                </span> Linked Assets
              </Link>
            </li>
            <li>
              <a href='javascript:void(0)' onClick={viewSupport}>
                <span className="sm-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="40.158" viewBox="0 0 37 40.158">
                    <g id="Group_262620" data-name="Group 262620" transform="translate(-1115.367 -460.706)" opacity="0.5">
                      <ellipse id="Ellipse_38" data-name="Ellipse 38" cx="13.564" cy="13.256" rx="13.564" ry="13.256" transform="translate(1120.566 469.519)" fill="#e1f0ff" />
                      <g id="support" transform="translate(1116.867 462.206)">
                        <path id="Path_106" data-name="Path 106" d="M68.352,26.711a11.863,11.863,0,0,1,3.756,8.657v3.509h.953V35.369a12.808,12.808,0,0,0-4.051-9.348,14.2,14.2,0,0,0-9.761-3.895c-7.635,0-13.832,5.941-13.832,13.243v3.509h.953V35.369c0-6.776,5.77-12.289,12.876-12.289A13.246,13.246,0,0,1,68.352,26.711ZM42,40.426v8.625a1.129,1.129,0,0,0,1.135,1.107H48.2v-10.8H43.135A1.1,1.1,0,0,0,42,40.426Zm32.865-1.071H70.36v10.8h4.506A1.129,1.129,0,0,0,76,49.051V40.426A1.1,1.1,0,0,0,74.865,39.354ZM71.551,54.519a2.041,2.041,0,0,1-2.026,2.074h-5.2v.953h5.2A3,3,0,0,0,72.5,54.519v-3.8h-.953Zm-9.9.415a2.175,2.175,0,1,0,2.175,2.175,2.175,2.175,0,0,0-2.175-2.175Z" transform="translate(-42 -22.126)" fill="none" stroke="#1e3e7b" stroke-width="3" />
                      </g>
                    </g>
                  </svg>

                </span> Technical Support
              </a>
            </li>
            <li>
              <Link to={'/location'}>
                <span className="sm-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="38.914" viewBox="0 0 33 38.914">
                    <g id="Group_262622" data-name="Group 262622" transform="translate(-642.199 -461.041)" opacity="0.5">
                      <ellipse id="Ellipse_40" data-name="Ellipse 40" cx="15.217" cy="15.007" rx="15.217" ry="15.007" transform="translate(643.182 461.041)" fill="#e1f0ff" />
                      <path id="Subtraction_1" data-name="Subtraction 1" d="M16,34.743a4.635,4.635,0,0,1-4.5-3.5H.4c-.046.181-.092.273-.136.273-.129,0-.224-.8-.252-1.59a4.362,4.362,0,0,1,.113-1.575.042.042,0,0,1,.03-.015c.066,0,.149.194.245.576H11.5a4.64,4.64,0,0,1,9,0H31.6c.046-.181.092-.274.136-.274.129,0,.224.8.252,1.59a4.355,4.355,0,0,1-.113,1.576.042.042,0,0,1-.03.015c-.066,0-.148-.193-.245-.575H20.5a4.643,4.643,0,0,1-4.5,3.5ZM16,27.2a2.886,2.886,0,1,0,2.886,2.887A2.889,2.889,0,0,0,16,27.2Zm0-3.883a1.164,1.164,0,0,1-.646-.194c-.343-.228-8.39-5.69-8.386-14.087a9.036,9.036,0,1,1,18.072,0c0,8.143-7.533,13.5-8.392,14.085A1.173,1.173,0,0,1,16,23.318ZM16,5.247a3.79,3.79,0,1,0,3.789,3.79A3.8,3.8,0,0,0,16,5.247Z" transform="translate(642.699 464.712)" fill="#1e3e7b" stroke="rgba(0,0,0,0)" stroke-width="1" />
                    </g>
                  </svg>

                </span> Equipment Geo Location
              </Link>
            </li>
            {!!status?.configuratorMain &&
              <li>
                <Link to={'/categories-assets-groups'}>
                  <span className="sm-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34.432"
                      height="34.432"
                      viewBox="0 0 34.432 34.432"
                    >
                      <g
                        id="Group_262623"
                        data-name="Group 262623"
                        transform="translate(-416.58 -464.035)"
                        opacity="0.5">
                        <ellipse
                          id="Ellipse_41"
                          data-name="Ellipse 41"
                          cx="16.238"
                          cy="15.352"
                          rx="16.238"
                          ry="15.352"
                          transform="translate(418.536 465.898)"
                          fill="#e1f0ff"
                        />
                        <g
                          id="Group_262435"
                          data-name="Group 262435"
                          transform="translate(416.58 464.035)"
                        >
                          <path
                            id="Path_98"
                            data-name="Path 98"
                            d="M0,0H34.432V34.432H0Z"
                            fill="none"
                          />
                          <path
                            id="Path_99"
                            data-name="Path 99"
                            d="M29.693,24.52v4.3H26.824v-4.3H23.955v-4.3h8.608v4.3Zm-22.955,0v4.3H3.869v-4.3H1v-4.3H9.608v4.3ZM15.347,7.3V3h2.869V7.3h2.869v4.3H12.477V7.3Zm0,7.173h2.869V28.824H15.347ZM3.869,17.347V3H6.739V17.347Zm22.955,0V3h2.869V17.347Z"
                            transform="translate(0.435 1.304)"
                            fill="#1e3e7b"
                          />
                        </g>
                      </g>
                    </svg>
                  </span> Asset Configurator
                </Link>
              </li>
            }
            {!!status?.predictiveMain &&
              <li>
                <Link to={'/predictive-maintenance'}>
                  <span className="sm-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="25.143" viewBox="0 0 32 25.143">
                      <g id="Group_262621" data-name="Group 262621" transform="translate(-874.342 -464.385)" opacity="0.5">
                        <ellipse id="Ellipse_39" data-name="Ellipse 39" cx="10.769" cy="10.562" rx="10.769" ry="10.562" transform="translate(876.563 466.048)" fill="#e1f0ff" />
                        <g id="Group_262433" data-name="Group 262433" transform="translate(874.342 464.385)">
                          <path id="Path_11151" data-name="Path 11151" d="M179.631,46.042H149.917a1.144,1.144,0,0,0-1.143,1.143V70.042a1.144,1.144,0,0,0,1.143,1.143h29.714a1.144,1.144,0,0,0,1.143-1.143V47.185A1.144,1.144,0,0,0,179.631,46.042ZM178.488,48.9a.571.571,0,1,1-.571-.571A.571.571,0,0,1,178.488,48.9Zm-20,0a.571.571,0,0,1-.571.571h-6.286a.571.571,0,1,1,0-1.143h6.286a.571.571,0,0,1,.571.571Zm14.286,13.714h-3.429a.571.571,0,0,1,0-1.143h3.429a.571.571,0,1,1,0,1.143Zm1.714,7.429v-5l2.785-5.013a.571.571,0,0,0,0-.555l-2.857-5.143a.571.571,0,0,0-.5-.294h-1.143a.571.571,0,0,0-.571.571h0v4h-2.286v-4a.571.571,0,0,0-.571-.571H168.2a.571.571,0,0,0-.5.294l-2.857,5.143a.57.57,0,0,0,0,.555l2.785,5.013v5H149.917V51.756h29.714V70.042Zm-20-10.286a.569.569,0,0,1-.458-.229l-1.714-2.286a.571.571,0,0,1,0-.685l1.714-2.286a.571.571,0,1,1,.931.662l-.017.023L153.488,56.9l1.457,1.943a.571.571,0,0,1-.457.914Zm4.114-.914L160.06,56.9,158.6,54.956a.571.571,0,0,1,.914-.686h0l1.714,2.286a.57.57,0,0,1,0,.685l-1.714,2.286a.571.571,0,0,1-.914-.685Zm-2.96.8,1.143-5.714a.571.571,0,0,1,1.12.224l-1.143,5.714a.571.571,0,0,1-.56.459.553.553,0,0,1-.113-.011A.571.571,0,0,1,155.643,59.644Zm-2.3,2.969a.571.571,0,1,1-.572-.571A.571.571,0,0,1,153.345,62.613Zm1.714-.571h3.429a.571.571,0,0,1,0,1.143H155.06a.571.571,0,1,1,0-1.143Zm2.286,2.857a.571.571,0,1,1,0,1.143H155.06a.571.571,0,1,1,0-1.143Zm6.857.571a.571.571,0,0,1-.571.571h-4a.571.571,0,1,1,0-1.143h4a.571.571,0,0,1,.572.571Zm-2.857,2.857a.571.571,0,0,1-.571.571H155.06a.571.571,0,1,1,0-1.143h5.714a.571.571,0,0,1,.571.571Zm2.857,0a.571.571,0,0,1-.571.571h-1.144a.571.571,0,1,1,0-1.143h1.143a.571.571,0,0,1,.572.571Z" transform="translate(-148.774 -46.042)" fill="#1e3e7b" />
                        </g>
                      </g>
                    </svg>
                  </span> Predictive Maintenance
                </Link>
              </li>
            }
          </ul>
        </div>
      </div>
    </div>
  )
}

export default InnerHeader