import React, { useState } from "react";
import * as yup from "yup";

import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { callBackendAPI, setUserInfo, showInfo, userInfo } from "../../utils/helpers";
import { REST_API_METHODS } from "../../utils/constants";
import Error from "../common/Error";
import { requestForToken } from "../../utils/firebase";

const validationSchema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

const LoginForm = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState("");
  const [keepSignedIn, setKeepSignedIn] = useState("");
  const [apiError, setApiError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(
    async (values) => {
      setApiError("");
      try {
        const response = await callBackendAPI(
          "login",
          REST_API_METHODS.POST,
          values
        );
        if (!!response.status) {
          const roles = response.data?.role || [];
          const validateRole = roles.some((a) => a.type === "user" || a.type === "assign_user");
          if (!!validateRole) {
            setUserInfo(response.data)
            localStorage.setItem("token", response.data?.token || "");
            requestForToken().then((token) => {
              if (token) {
                tokenSave(token);
              }
            });

            history.push("/");
          } else {
            showInfo(
              "You can not access this application, Please contact to admin"
            );
          }
        } else {
          setApiError(response.message);
        }
      } catch (e) {
        console.log(e);
      }
    },
    (errors) => {
      console.log("errors", errors);
    }
  );

  const tokenSave = async (token) => {
    try {
      const user = userInfo();
      const values = {
        user: user._id,
        token,
      };
      await callBackendAPI(`createToken`, REST_API_METHODS.POST, values);
    } catch (e) {
      console.log(e);
    }
  };

  return (

    <div className="col-md-5">
      <div className="logincardwrap">
        <div className="logincard">
          <img src="images/logo_ergin.jpg" alt="" />
          <span>Login to Your account</span>
          <p>
            {" "}
            {/* Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text */}
          </p>
          <form className="common_input_warp" onSubmit={onSubmit} noValidate>
            <div className="form-group">
              <input
                {...register("email")}
                type="email"
                name="email"
                autoComplete="off"
                className="form-control"
                placeholder="E-mail"
              />
              {!!errors.email && <Error message={errors.email.message} />}
            </div>
            <div className="form-group">
              <i
                aria-hidden="false"
                onClick={() => setShowPassword((state) => !state)}
                className={"fa"}
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </i>
              <input
                {...register("password")}
                type={showPassword ? "text" : "password"}
                name="password"
                autoComplete="off"
                className="form-control"
                placeholder="Password"
              />
              {!!errors.password && <Error message={errors.password.message} />}
            </div>
            {!!apiError && <Error message={apiError} />}
            <div className="forgotcontwrap dflex dflex_bet">
              <div className="customimputcheckbox">
              </div>
              <Link to={"/forgot-password"}>Forgot password?</Link>
            </div>
            <div className="common_btn">
              <button type="submit">Sign in</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
