import { userInfo } from "../utils/helpers";
const currentUser = userInfo();

export const hasRole = (role) => {
    if (!!currentUser) {
        return currentUser.role.some(a => a.type === role);
    } else {
        return false;
    }
}
