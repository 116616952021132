import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import IsLoadingHOC from "../../components/common/IsLoadingHOC";
import TitleSetter from "../../components/common/TitleSetter";
import { REST_API_METHODS } from "../../utils/constants";
import { callBackendAPI } from "../../utils/helpers";

const AssetsConfigurator = (props) => {
  const { setLoading } = props;
  const [categories, setCategories] = useState([]);

  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    getCategoriesWithAssetsGroup();
  }, []);

  const getCategoriesWithAssetsGroup = async () => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getCategoriesWithAssetsGroup`,
        REST_API_METHODS.GET
      );
      if (!!response?.status && response.data?.length) {
        setCategories(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <TitleSetter title="Asset Configurator" />
      <div className="explorewrap minHn services-pg-wrap explorepage-wrap configurationlist-wrap">
        <div className="container">
          <div className="assets-configurator-wrap">
            <div className="main-banner-top">
              <img src="images/config_bans.png" alt="" />
              {/* <div className="banner-inner-heading">
                <h3>BUILD YOUR OWN MACHINE</h3>
              </div> */}
            </div>
            {/* <div className="row align-item-center">
              <div className="col-md-7 order-xl-1">
                <div className="aboutsimg-div explorebg-image">
                  <img src="images/exploreimg.png" alt="" />
                </div>
              </div>
              <div className="col-md-5 order-lg-0">
                <div className="explorecont-heading">
                  <h3>BUILD YOUR OWN MACHINE AS PER YOUR REQUIREMENT</h3>
                </div>
                <div className="explore-btn configure-btn">
                  <a href="javascript:void()" onClick={executeScroll}>
                    Configure Now
                  </a>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="content-wrap">
            {categories.map((cat, index) => (
              <div key={index} className="mt-3">
                <div className="mr-3">
                  <p className="mb-3">{cat.name}</p>
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        height={60}
                        width={60}
                        src={cat.icon || "images/no-image.png"}
                        alt=""
                      />
                    </div>
                    <div className="col-md-9">
                      <ul>
                        {cat?.configurator?.map((config, i) => (
                          <li key={i}>
                            <Link to={"/configurator/" + config._id}>
                              {config?.machine?.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <div className="configurator-types-wrap configurator" ref={myRef}>
        <div className="">
          <div className="row">
            {categories.map((cat, index) => (
              <div className="col-md-4" key={index}>
                <div className="configurator-type">
                  <h4>{cat.name}</h4>
                  <div className="configuratorinfo">
                    <div className="configurator-img">
                      <img src={cat.icon || "images/no-image.png"} alt="" />
                    </div>
                    <ul>
                      {cat?.machines?.map((machine, i) => (
                        <li key={i}>
                          <Link
                            to={
                              "/configurator-assets/" +
                              cat._id +
                              "/" +
                              machine._id
                            }
                          >
                            {/* <Link to={"/configurator/" + machine._id}> */}
                            {machine?.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsLoadingHOC(AssetsConfigurator);
