import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router";

import { callBackendAPI, showMessage, userInfo } from "../utils/helpers";
import { REST_API_METHODS } from "../utils/constants";

import * as moment from "moment";
import TitleSetter from "../components/common/TitleSetter";
import "../styles/pages/assets-list.css";
import IsLoadingHOC from "../components/common/IsLoadingHOC";
import Modal from "../modal/Modal";
import { SerialNumbersModal } from "../components/modals/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faClock, faInfo, faMap } from "@fortawesome/free-solid-svg-icons";
import SetApproximateWeekHrs from "../components/common/SetApproximateWeekHrs";

const AssetsList = (props) => {
  const { setLoading, isLoading } = props;
  const history = useHistory();
  const childRef = useRef();
  const [assets, setAssets] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [showSerialModal, setShowSerialModal] = useState(false);
  const [assetsName, setAssetsName] = useState('');

  let page = 1;
  const limit = 10;
  const user = userInfo();

  const fetchAssetsFn = async (offset = 0) => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getUserAssetsLinked?userId=${user._id}&skip=${offset}&limit=${limit}`,
        REST_API_METHODS.GET
      );
      if (!!response?.status && response.data?.length) {
        const assets = response.data?.map((record) => {
          const { _id, address, assets, machine, assetsInstallDate, warrantyExpiration, serialNo, weekHrs, assetsActive, chipInstallation } = record;
          return {
            id: _id,
            address: !!address
              ? `${address?.address}, ${address?.city}, ${address?.pin_code}`
              : "N/A",
            actinalAddress: address,
            asset: `${assets?.name || "N/A"}`,
            productName: `${assets?.productName || "N/A"}`,
            svgPath: assets?.svgPath,
            image: assets?.images?.length
              ? assets.images[assets.defaultImageIndex || 0].url
              : "",
            machine: !!machine
              ? `${machine?.name}`
              : "N/A",
            serialNo: `${serialNo || "N/A"}`,
            assetsActive: assetsActive || false,
            weekHrs: weekHrs || '',
            assetsInstallDate: !!assetsInstallDate
              ? moment(parseInt(assetsInstallDate)).format("D MMMM Y, hh:mm a")
              : "N/A",
            warrantyExpiration: !!warrantyExpiration
              ? moment(parseInt(warrantyExpiration)).format("D MMMM Y, hh:mm a")
              : "N/A",
            chipInstallation
          };
        });
        setAssets(assets);
        setPageCount(Math.ceil(response.totalItems / limit));
      } else {
        setAssets([]);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssetsFn();
  }, []);

  const handlePageClick = (event) => {
    let newOffset = event.selected;
    page += 1;
    fetchAssetsFn(newOffset);
  };

  const openAssetsDetails = (e, id) => {
    e.stopPropagation();
    const state = {
      id,
      explore: true,
    };
    localStorage.setItem("assets-state", JSON.stringify(state));
    history.push("/assets-details/" + id);
  };

  const svgLoad = (assets) => {
    localStorage.setItem("svg-assets", JSON.stringify(assets));
    history.push("/svg-model");
  };

  const viewProductGuide = (e, asset) => {
    e.stopPropagation();
    setAssetsName(asset?.asset || '');
    setShowSerialModal(true);
  }

  const assetsActiveToggle = async (e, index, asset) => {
    e.stopPropagation();
    try {
      setLoading(true);
      const params = {
        id: asset.id,
        assetsActive: !asset.assetsActive
      }
      const response = await callBackendAPI(
        `assetsActiveAndDeactivate`,
        REST_API_METHODS.PUT,
        params
      );

      if (response.status) {
        let temp_state = [...assets];
        temp_state[index].assetsActive = params.assetsActive;
        setAssets(temp_state);
      }
      showMessage(response.message);
      setLoading(false);
      closeModal();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const viewMap = (asset) => {
    if (!!asset.actinalAddress && asset.actinalAddress.latitude && asset.actinalAddress.longitude) {
      history.push('/location/' + asset.actinalAddress.latitude + '/' + asset.actinalAddress.longitude)
    }
  }

  const closeModal = () => {
    setShowSerialModal(false);
  }

  return (
    <>
      <TitleSetter title={"Linked Assets"} />
      <div className="explorewrap assetslinkwrap">
        {/* <img src="images/left_bg.png" alt="" /> */}
        <div className="explorewrap services-pg-wrap termscondition">
          <div className="tabledatawrap table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Product Image</th>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {assets.map((asset, index) => (
                  <tr key={index}>
                    <td>
                      <div className="pro-img">
                        <img src={asset?.image || "images/assets_img1.png"} alt="" />
                      </div>
                    </td>
                    <td className="text-capitalize">{asset.asset}</td>
                    <td className="text-capitalize">{asset.productName}</td>
                    <td className={`text-capitalize ${!asset.assetsActive ? 'text-danger' : ''} `}>{!!asset.assetsActive ? "Active" : "In-Active"}</td>
                    <td className="text-capitalize">
                      <div className="action-text">
                        <a href="javascript:void(0);" onClick={(e) => viewProductGuide(e, asset)}>Product Guide</a>
                        <a data-toggle="tooltip" title="View Details"
                          href="javascript:void(0);" onClick={(e) => openAssetsDetails(e, asset.id)}>
                          <FontAwesomeIcon icon={faInfo} />
                        </a>
                        {!asset.chipInstallation && <a data-toggle="tooltip" title="Set Hours"
                          href="javascript:void(0);" onClick={(e) => { e.stopPropagation(); childRef.current.viewHandler(asset) }}>
                          <FontAwesomeIcon icon={faClock} />
                        </a>}
                        <a data-toggle="tooltip" title={asset.assetsActive ? "Active" : "In-Active"}
                          href="javascript:void(0);" onClick={(e) => assetsActiveToggle(e, index, asset)}>
                          <FontAwesomeIcon icon={faBan} color={!asset.assetsActive ? "Red" : "white"} />
                        </a>
                        <a data-toggle="tooltip" title="View Map"
                          href="javascript:void(0);" onClick={(e) => viewMap(asset)}>
                          <FontAwesomeIcon icon={faMap} />
                        </a>
                      </div>
                    </td>
                  </tr>))}
              </tbody>
            </table>
          </div>
        </div>

        <ReactPaginate
          className="pagination"
          breakLabel="..."
          nextLabel="Next"
          previousLabel="Previous"
          pageCount={pageCount}
          pageRangeDisplayed={3}
          renderOnZeroPageCount={null}
          onPageChange={handlePageClick}
        />
      </div>

      <SetApproximateWeekHrs ref={childRef} setLoading={setLoading} redirect={false} />

      <Modal
        title={"Select Serial No"}
        onClose={() => setShowSerialModal(false)}
        show={showSerialModal}
        type="Select Serial No"
      >
        <SerialNumbersModal
          assetsName={assetsName}
          svgLoad={svgLoad}
          closeModal={closeModal}
        />
      </Modal>
    </>
  );
};

export default IsLoadingHOC(AssetsList);
