import React, { useState } from "react";
import * as yup from "yup";

import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { callBackendAPI } from "../../utils/helpers";
import { REST_API_METHODS } from "../../utils/constants";
import Error from "../common/Error";

const validationSchema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

const ForgotPasswordForm = () => {
  const [apiError, setApiError] = useState("");
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(
    async (values) => {
      setApiError("");
      try {
        const response = await callBackendAPI(
          "forgotPassword",
          REST_API_METHODS.POST,
          values
        );
        if (!!response.status) {
          localStorage.setItem("otp-verification-email", values.email);
          history.push("/otp-verification/");
        } else {
          setApiError(response.message);
        }
      } catch (e) {
        console.log(e);
      }
    },
    (errors) => {
      console.log("errors", errors);
    }
  );

  return (
    <div className="col-md-5">
      <div className="logincardwrap">
        <div className="logincard resetpassword">
          <img src="images/logo_ergin.jpg" alt="" />
          <span>Forgot Password</span>
          <p>
            Please enter your registered Email ID to reset your password
          </p>
          <form className="common_input_warp" onSubmit={onSubmit} noValidate>
            <div className="form-group">
              <input
                {...register("email")}
                type="email"
                className="form-control"
                placeholder="E-mail"
                name="email"
              />
              {!!errors.email && <Error message={errors.email.message} />}
            </div>
            {!!apiError && <Error message={apiError} />}
            <div className="forgotcontwrap">
              <Link to={"/login"}>Back to login</Link>
            </div>
            <div className="common_btn">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
