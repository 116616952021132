import React from "react";

const Preference = ({ preference, checkedHandler, applyPreference, resetDefault }) => {
    return (
        <div className="prefrencelist">
            <ul>
                {preference.map((item, index) => {
                    if (item.type !== '_id') {
                        return <li key={index}>
                            <input id={'preference_' + index} type="checkbox" checked={item.checked} onChange={(event) => checkedHandler(event, index)}></input>
                            <label for={'preference_' + index}>{item.name}</label>
                        </li>
                    }
                })}
            </ul>
            <div className="common_btn">
                <button onClick={() => applyPreference()}>Set</button>
                <button onClick={resetDefault}>Reset</button>
            </div>
        </div>
    )
}

export default Preference;