import React, { useEffect } from "react";
import TitleSetter from "../../components/common/TitleSetter";
import { BACKEND_API_URL } from "../../utils/constants";

const Configurator360View = ({ serverDir }) => {
    const folderName = BACKEND_API_URL + "/get_svg/" + serverDir.mainEntry;

    useEffect(() => {
        window.CI360.init();
    }, [window]);

    return (
        <>
            <TitleSetter title="Asset Configurator 360 view" />

            <div
                className="cloudimage-360"
                id="gurkha-suv"
                data-folder={folderName}
                data-filename-x={`${serverDir.assetsName}{index}.jpg`}
                data-amount-x={serverDir.count || 0}
                // data-magnifier="3"
                // data-spin-reverse
                data-fullscreen
            // data-responsive="scaleflex"
            ></div>
        </>
    )
}

export default Configurator360View;