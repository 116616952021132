import React, { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Error from "../common/Error";

const validationSchema = yup
    .object({
        hours: yup
            .number()
            .typeError('Hours must be a number')
            .required("Set Week Hours is required")
            .max(168, 'The maximum number of hours allowed is 168. Please enter a value equal to or less than 168.'),
    });

const SetWeekHrs = ({ show, assetsDetails, setHrs, closeModal }) => {

    const {
        register,
        setValue,
        clearErrors,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (show) {
            clearErrors('hours');
            setValue('hours', assetsDetails.weekHrs);
        }
    }, [show]);


    const onSubmit = handleSubmit(
        async (values) => {
            try {
                setHrs(values.hours);
            } catch (error) {

            }
        },
        (errors) => {
            console.log("errors", errors);
        }
    );

    return (
        <div className="serialnumberpop-wrap">
            <input
                {...register("hours")}
                className="form-control mb-4"
                pattern="[0-9]*"
                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                placeholder="Enter average weekly running hours"
            />
            {!!errors.hours && (
                <Error message={errors.hours.message} />
            )}
            <div className="common_btn">
                <button type="button" onClick={onSubmit}>Set</button>
                <button onClick={closeModal}>Cancel</button>
            </div>
        </div>
    )
}

export default SetWeekHrs;