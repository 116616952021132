import { useEffect, useState } from 'react';
import { REST_API_METHODS } from '../utils/constants';
import { callBackendAPI, userInfo } from '../utils/helpers';

const useConfiguratorAndPredicative = () => {
    const [status, setStatus] = useState({});
    const user = userInfo();
    const fetchConfiguratorAndPredicative = async () => {
        if (!!user) {
            const response = await callBackendAPI(
                "getConfiguratorAndPredicativeStatus",
                REST_API_METHODS.GET
            );
            if (!!response.status) {
                setStatus(response.data);
            }
        }
    }

    useEffect(() => {
        fetchConfiguratorAndPredicative();
    }, [])

    return { status, fetchConfiguratorAndPredicative }
};

export default useConfiguratorAndPredicative;
