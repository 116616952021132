import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { BACKEND_API_URL, REST_API_METHODS } from "../utils/constants";
import "../styles/pages/svg-modal.css";
import { callBackendAPI } from "../utils/helpers";
import IsLoadingHOC from "../components/common/IsLoadingHOC";
import Lottie from "react-lottie";
import animationData from "../components/common/supermarket-cart.json";
import $ from 'jquery';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const timeout = 4000;
const SVGModel = (props) => {
  const { setLoading } = props;
  const history = useHistory();
  let { id, partNumber } = useParams();

  const [assetsUrl, setAssetsUrl] = useState("");
  const [svgShow, setSvgShow] = useState(true);

  let { assId, name, svgPath } = JSON.parse(localStorage.getItem("svg-assets"));
  if (id && svgShow) {
    setSvgShow(false);
    setLoading(false);
  }

  useEffect(async () => {
    console.log("partNumber", partNumber);
    if (!!assId && !!name && !!svgPath) {
      const assetsName =
        BACKEND_API_URL + "/get_svg/" + svgPath + name + ".svg";
      setAssetsUrl(assetsName);
    }

    if (id) {
      // $('#root').addClass('blur-text');
      const payload = {
        assId,
        partName: id,
        partNumber
      }

      $('.headerwap').css("display", "none");
      $('.asstesdetailswrap').css("display", "none");
      $('.footerwrap').css("display", "none");

      const response = await callBackendAPI(
        'addToCart',
        REST_API_METHODS.POST,
        payload
      );

      $('#root').css("display", "block");
      setSvgShow(true);
      history.goBack();
      setTimeout(() => {
        $('#root').removeClass('blur-text');
        $('.asstesdetailswrap').css("display", "block");
        $('.headerwap').css("display", "block");
        $('.footerwrap').css("display", "block");
        $('.bell-count').innerHTML = 2
      }, timeout);
      // window.parent.top.location.replace(`/#/raise-ticket/${assId}/new`);
    } else if (!id && !partNumber) {
      setTimeout(() => {
        setLoading(false);
      }, timeout);
    }
  }, [id]);

  const toggleRoute = () => {
    const state = {
      id: assId,
      explore: true,
    };
    localStorage.setItem("assets-state", JSON.stringify(state));
    history.push("/assets-details/" + assId);
  };

  return (
    <>
      {svgShow && <div id="svg-section">
        <div id={"model"}>
          {!id ? (
            <embed
              id="svg-modal"
              style={{ height: "100vh", width: "100vw" }}
              src={assetsUrl}
              alt="My Happy SVG"
              frameBorder="0"
              allowFullScreen
            />
          ) : (
            ""
          )}
        </div>
      </div>}

      {!svgShow && <div className="svg-section-loader">
        <Lottie options={defaultOptions} height={300} width={300} />
      </div>}
    </>
  );
};

export default IsLoadingHOC(SVGModel);
