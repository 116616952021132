import React, { useEffect, useRef, useState } from "react";
import OtpInput from "react-otp-input";

import { callBackendAPI, showMessage } from "../../utils/helpers";
import { REST_API_METHODS } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import Error from "../common/Error";

const OTPVerificationForm = (props) => {
  let timerInterval = useRef();
  const history = useHistory();
  const [otp, setOTP] = useState();
  const [apiError, setApiError] = useState("");
  const [timerValue, setTimerValue] = useState(30);
  const [email] = useState(localStorage.getItem("otp-verification-email"));

  useEffect(() => {
    timerInterval.current = setInterval(timerProgress, 10);

    return () => {
      if (timerInterval.current) {
        clearInterval(timerInterval.current);
      }
    };
  }, [timerValue]);

  const timerProgress = () => {
    if (timerValue > 0) {
      setTimerValue((state) => state - 1);
    } else {
      clearInterval(timerInterval.current);
    }
  };

  const resendOtp = async () => {
    const response = await callBackendAPI(
      "forgotPassword",
      REST_API_METHODS.POST,
      { email }
    );
    if (response.status) {
      showMessage('OTP re-send successfully.')
    }
  }

  const verifyOTP = async () => {
    setApiError("");

    if (!!email && !!otp) {
      try {
        const response = await callBackendAPI(
          "verifyOTP",
          REST_API_METHODS.POST,
          {
            email,
            otp,
          }
        );
        if (!!response.status) {
          localStorage.setItem("otp-verification-token", response.data.token);
          localStorage.setItem("otp-verification-user-id", response.data._id);
          history.push("/reset-password");
        } else {
          setApiError(response.message);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      setApiError('Please enter OTP');
    }
  };

  return (
    <div className="col-md-5">
      <div className="logincardwrap">
        <div className="logincard resetpassword otpwrap">
        <img src="images/logo_ergin.jpg" alt="" />
          <span>OTP Verification</span>
          <p>
            {" "}
            Enter the verification code sent to your Email <br />{" "}
            {email}
          </p>
          <div className="common_input_warp">
            <div className="form-group">
              {/* <label htmlFor="">Enter OTP</label> */}
              <div className="otp_input_warp">
                <div className="otp_fields">
                  <form
                    method="get"
                    className="digit-group"
                    data-group-name="digits"
                    data-autosubmit="false"
                    autoComplete="off"
                  >
                    <OtpInput
                      value={otp}
                      onChange={(e) => setOTP(e)}
                      numInputs={4}
                    />
                  </form>
                </div>
                {!!apiError && <Error message={apiError} />}
                <div className=" otp_time_wrap dflex dflex_bet">
                  {timerValue === 0 ? null : (
                    <span className="otp_time">00:{timerValue} Seconds</span>
                  )}
                  {/* <!-- <span className="otp_resend">Resend Code</span> --> */}
                </div>
              </div>
            </div>
            <div className="common_btn">
              <button onClick={verifyOTP}>Proceed</button>
            </div>
            <div className="forgotcontwrap resendlink">
              <p>
                If you did’t received code<a href="javascript:void(0)" onClick={resendOtp}>Resend</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPVerificationForm;
