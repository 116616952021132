import React, { useEffect, useState } from "react";
import "../styles/pages/about_us.css";
import { callBackendAPI } from "../utils/helpers";
import { REST_API_METHODS } from "../utils/constants";
import TitleSetter from "../components/common/TitleSetter";
import IsLoadingHOC from "../components/common/IsLoadingHOC";

const AboutUs = (props) => {
  const { setLoading } = props;

  const [description, setDescription] = useState("");

  useEffect(() => {
    getAboutDetails();
  }, []);

  const getAboutDetails = async () => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        "getContents?type=1",
        REST_API_METHODS.GET
      );
      if (!!response.status) {
        setDescription(response.data.contents);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <TitleSetter title={"About Us"} />
      <div className="explorewrap">
        {/* <img src="images/left_bg.png" alt="" /> */}
        <div className="aboutuswrap">
        <div className="container">
          <div className="row">
          <div className="col-md-12 col-lg-5 order-lg-2">
              <div className="imageswrap aboutsimg-div text-center">
              <img src="images/about-bg.png" alt="" />
              </div>
            </div>
            <div className="col-md-12 col-lg-7 ">
              <div className="left-Aboutimg">
              <img src="images/logo_ergin.jpg" alt="" />
              </div>
              <div className="explorecont about-content">
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
              </div>
            </div>
            
          </div>
          <div className="row">
            <div className="col-md-12">
            {/* <div dangerouslySetInnerHTML={{ __html: description }}></div> */}
            </div>
          </div>
        </div> 
        </div> 
      </div>
    </>
  );
};

export default IsLoadingHOC(AboutUs);
