import React, { useEffect, useState } from "react";
import "../../styles/pages/user-management.css";
import { useHistory } from "react-router-dom";
import Empty from "../../components/common/Empty";
import IsLoadingHOC from "../../components/common/IsLoadingHOC";
import TitleSetter from "../../components/common/TitleSetter";
import { REST_API_METHODS } from "../../utils/constants";
import { callBackendAPI, showMessage } from "../../utils/helpers";
import Modal from "../../modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faBan, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const defaultUrl = "images/user.png";

const UserManagement = (props) => {
  const { setLoading, isLoading } = props;
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [show, setShow] = useState(false);
  const [showBlock, setBlockShow] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getAssignUserList`,
        REST_API_METHODS.GET
      );
      if (!!response.status) {
        setUsers(response.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const sendMail = async (user) => {
    try {
      setLoading(true);
      const payload = {
        id: user._id,
      };
      const response = await callBackendAPI(
        `sendWelcomeMail`,
        REST_API_METHODS.POST,
        payload
      );
      if (!!response.status) {
        showMessage(response.message);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const edutUser = (user) => {
    history.push("user-management/edit/" + user._id);
  };

  const removeModal = (user) => {
    setSelectedUsers({ ...selectedUsers, ...user });
    setBlockShow(false);
    setShow(true);
  };

  const blockAndUnblock = (user) => {
    setSelectedUsers({ ...selectedUsers, ...user });
    setBlockShow(true);
    setShow(true);
  };

  const confirmHandler = async () => {
    try {
      setLoading(true);
      const payload = { id: selectedUsers._id };
      const index = users.findIndex((a) => a._id === selectedUsers._id);
      let temp_state = [...users];
      let response;

      if (showBlock) {
        payload.status = !selectedUsers.status;
        response = await callBackendAPI(
          `blockUnBlockUserManagement`,
          REST_API_METHODS.PUT,
          payload
        );
        if (!!response.status) {
          temp_state[index].status = payload.status;
          showMessage(response.message);
        }
      } else {
        response = await callBackendAPI(
          `assignUserRemove`,
          REST_API_METHODS.DELETE,
          payload
        );
        if (!!response.status) {
          temp_state.splice(index, 1);
          showMessage(response.message);
        }
      }
      setUsers(temp_state);
      closeModal();
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const closeModal = () => {
    setShow(false);
    setBlockShow(false);
    setLoading(false);
  };

  return (
    <>
      <TitleSetter title={"User Management"} />
      <div className="user-management">
        <div className="cartPage-heading">
          <h3>Sub-user Management</h3>
          <div className="common_btn">
            <button
              className="pl-4 pr-4"
              onClick={() => {
                history.push("/user-management/new");
              }}
            >
              Add Sub-User
            </button>
          </div>
        </div>
        <div className="container">
          <div className="tabledatawrap table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Address</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index}>
                    <td className="text-capitalize">{index + 1}</td>
                    <td>
                      <img
                        height={40}
                        width={40}
                        alt=""
                        src={user.image}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultUrl;
                        }}
                      />
                      {/* <img src={user.image} height={40} width={40} alt="" /> */}
                    </td>
                    <td className="text-capitalize">{user?.name}</td>
                    <td>{user?.email}</td>
                    <td>+{user?.dialCode}{user?.phone}</td>
                    <td>
                      {user?.address ? (
                        <div>
                          {user?.address?.address},<br /> {user?.address?.city},{" "}
                          {user?.address?.state},{user?.address?.pin_code}
                        </div>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      {user?.assignStatus == 1 ? (
                        <span>Approved</span>
                      ) : user?.assignStatus == 2 ? (
                        <span>Rejected</span>
                      ) : user?.assignStatus == 3 ? (
                        <span>Disabled</span>
                      ) : (
                        <span>Pending</span>
                      )}
                    </td>
                    <td>
                      <div className="action-text">
                        <a
                          href="javascript:void(0)"
                          className={user.assignStatus !== 1 ? "disabled" : ""}
                          onClick={() => {
                            history.push("/assets-linked/" + user._id);
                          }}
                        >
                          Link Assets
                        </a>


                        <a data-toggle="tooltip" title="Edit"
                          href="javascript:void(0)"
                          className={user.assignStatus !== 1 ? "disabled" : ""}
                          onClick={() => edutUser(user)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </a>

                        <a data-toggle="tooltip" title="Delete"
                          href="javascript:void(0)"
                          onClick={() => removeModal(user)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </a>

                        <a data-toggle="tooltip" title={user.status ? "Block" : "Unblock"}
                          href="javascript:void(0)"
                          className={user.assignStatus !== 1 ? "disabled" : ""}
                          onClick={() => blockAndUnblock(user)}
                        >
                          <FontAwesomeIcon icon={faBan} color={!user.status ? "Red" : "white"} />
                        </a>
                        <a data-toggle="tooltip" title="Send Mail"
                          href="javascript:void(0)"
                          className={user.assignStatus !== 1 ? "disabled" : ""}
                          onClick={() => sendMail(user)}
                        >
                          <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!users.length && !isLoading && (
              <Empty message="No users available" />
            )}
          </div>
        </div>
      </div>

      <Modal title={"Confirm"} onClose={() => setShow(false)} show={show}>
        <div className="">
          {showBlock ? (
            <p>{`Are you sure you want to ${selectedUsers?.status ? "block" : "unblock"
              } this User?`}</p>
          ) : (
            <p>Are you sure you want to delete this user?</p>
          )}
          <div className="common_btn">
            <button className="confirm-alert-btn" onClick={confirmHandler}>
              Yes
            </button>
            <button className="confirm-alert-btn" onClick={closeModal}>
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IsLoadingHOC(UserManagement);
