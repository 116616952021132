import React, { useState } from "react";
import { useHistory } from "react-router";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { callBackendAPI, showError, showMessage } from "../../utils/helpers";
import { REST_API_METHODS } from "../../utils/constants";
import Error from "../common/Error";

const validationSchema = yup
  .object({
    oldPassword: yup.string().required("Old password is required"),
    newPassword: yup.string().required("New password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("newPassword")], "The passwords do not match"),
  })
  .required();

const ChangePasswordFeature = () => {
  const history = useHistory();
  const [showOldPassword, setShowOldPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(
    async (values) => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) return history.goBack();

        const params = {
          userId: user._id,
          oldPassword: values.oldPassword,
          password: values.newPassword,
        };

        const response = await callBackendAPI(
          "changePassword",
          REST_API_METHODS.PUT,
          params
        );
        if (!!response.status) {
          history.goBack();
          showMessage(response.message);
        } else {
          showError(response.message);
        }
      } catch (e) {
        console.log(e);
      }
    },
    (errors) => {
      console.log("errors", errors);
    }
  );

  return (
    <div className="faqwrap">
      <h2>Change Password</h2>
      <div className="changepassword">
        <div className="row">
          <div className="col-md-6">
            <div className="common_input_warp">
              <div className="form-group">
                <div className="pos-realtive">
                <i
                  aria-hidden="false"
                  onClick={() => setShowOldPassword((state) => !state)}
                  className={"fa"}
                >
                  <FontAwesomeIcon
                    icon={showOldPassword ? faEye : faEyeSlash}
                  />
                </i>
                <input
                  {...register("oldPassword")}
                  type={showOldPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Old Password"
                  name="oldPassword"
                  autoComplete="off"
                  required=""
                />
                </div>
                {!!errors.oldPassword && (
                  <Error message={errors.oldPassword.message} />
                )}
              </div>
              <div className="form-group">
              <div className="pos-realtive">
                <i
                  aria-hidden="false"
                  onClick={() => setShowNewPassword((state) => !state)}
                  className={"fa"}
                >
                  <FontAwesomeIcon
                    icon={showNewPassword ? faEye : faEyeSlash}
                  />
                </i>
                <input
                  {...register("newPassword")}
                  type={showNewPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="New Password"
                  name="newPassword"
                  autoComplete="off"
                  required=""
                />
                </div>
                {errors.newPassword && (
                  <Error message={errors.newPassword.message} />
                )}
              </div>
              <div className="form-group">
              <div className="pos-realtive">
                <i
                  aria-hidden="false"
                  onClick={() => setShowConfirmPassword((state) => !state)}
                  className={"fa"}
                >
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEye : faEyeSlash}
                  />
                </i>
                <input
                  {...register("confirmPassword")}
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  autoComplete="off"
                  required=""
                />
                </div>
                {errors.confirmPassword && (
                  <Error message={errors.confirmPassword.message} />
                )}
              </div>
              <div className="common_btn">
                <button onClick={onSubmit}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordFeature;
