import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import '../../styles/pages/configurator.css';

import ImageGallery from "react-image-gallery";
import { callBackendAPI, setUserInfo, userInfo } from "../../utils/helpers";
import { REST_API_METHODS } from "../../utils/constants";
import IsLoadingHOC from "../../components/common/IsLoadingHOC";
import Configurator360View from "./Configurator360View";
import $ from 'jquery';
import _ from "lodash";
import { getUserData } from "../../utils/firebase";
import TitleSetter from "../../components/common/TitleSetter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

const Configurator = (props) => {
  const { setLoading } = props;
  const history = useHistory();
  const { assetsName, categoryId, machineId } = useParams();
  const images = [];

  const user = userInfo();
  const { configuratorInfo } = user;
  const [view, setView] = useState(images);
  const [nextDiv, setNextDiv] = useState({
    step1: !!configuratorInfo ? false : true,
    step2: false,
    step3: false
  });

  const [index, setIndex] = useState(0);
  const refImg = useRef(null);
  const [configuratorDetails, setConfiguratorDetails] = useState({});
  const [mainEntryFor360, setMainEntryFor360] = useState({});
  const [view360, setView360] = useState(false);
  const [summaryShow, setSummaryShow] = useState(false);

  useEffect(() => {
    window.CI360.init();
    if (!configuratorInfo) {
      viewInfo('');
    }
  }, [window])

  useEffect(() => {
    const serialNo = '2F2210HA769';
    getUserData(serialNo).then((response) => {
      console.log(response)
    });
    getConfiguratorDetails();
  }, []);

  const getConfiguratorDetails = async () => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getConfiguratorDetails?assetsName=${assetsName}&categoryId=${categoryId}&machineId=${machineId}`,
        REST_API_METHODS.GET
      );

      if (!!response?.status && !!response.data) {
        const data = response.data;
        setConfiguratorDetails(data);
        data?.labels.forEach((ele, index) => {
          $(`.tags${index}`).on('click', 'li', function () {
            $(`.tags${index} li.active-tag`).removeClass('active-tag');
            $(this).addClass('active-tag');
          });
        });
        selectImage(data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const selectImage = (configuratorDetails) => {
    const obj = {};
    configuratorDetails?.labels.forEach((ele, index) => {
      const tab0 = document.querySelector(`.tags${index} .active-tag`).id;
      const splitData = tab0.split('_');
      obj[splitData[0]] = splitData[1];
    })

    // console.log('configuratorDetails', label.labelName, tag.tag);
    const findImages = configuratorDetails.combinations.find(a => {
      const newObj = a;
      // match both obj values
      if (JSON.stringify(obj) === JSON.stringify(_.omit(newObj, 'images', 'mainEntry', 'count', 'dirPath'))) {
        console.log(a)
        return a;
      }
    })

    console.log('findImages', findImages);

    const modifyImages = findImages.images.map(a => { return { original: a.name, thumbnail: a.name } });

    const index = refImg.current ? refImg.current.getCurrentIndex() : 0;
    setIndex(index);
    setView360(false);
    let temp_state = [...view];
    temp_state = modifyImages;
    setView(temp_state);
    let temp_state_con = { ...mainEntryFor360 };
    temp_state_con = { assetsName: configuratorDetails.assets, mainEntry: findImages.mainEntry, count: findImages.count || 0 };
    setMainEntryFor360(temp_state_con);
  }

  const view360Modal = () => {
    let temp_state = !view360;
    setView360(temp_state);
  }

  const compensationProcess = () => {
    let temp_state = !summaryShow;

    $("#label-tag li:not(.active-tag)").each(function () {
      if (!!temp_state) {
        $(this).addClass("display-none");
      } else {
        view360Modal();
        $(this).removeClass("display-none");
      }
    });

    setSummaryShow(temp_state);
  }

  const createQuote = () => {
    const obj = {};
    const objTag = {
      category: configuratorDetails.category.name,
      machine: configuratorDetails.machine.name,
      assets: configuratorDetails.assets,
      assetsImage: configuratorDetails.assetsImage,
      labels: [],
      finalConfigurator: {}
    };
    configuratorDetails?.labels.forEach((ele, index) => {
      const tab0 = document.querySelector(`.tags${index} .active-tag`).id;
      const splitData = tab0.split('_');
      obj[splitData[0]] = splitData[1];
      const findTag = ele.tags.find(a => a.tag === splitData[1]);
      objTag.labels.push({
        labelName: splitData[0],
        tagName: splitData[1],
        tagImage: findTag.image
      });
    })

    const findImages = configuratorDetails.combinations.find(a => {
      const newObj = a;
      // match both obj values
      if (JSON.stringify(obj) === JSON.stringify(_.omit(newObj, 'images', 'mainEntry', 'count', 'dirPath'))) {
        console.log(a)
        return a;
      }
    })
    objTag.finalConfigurator = findImages?.images || [];
    console.log('findImages', findImages);
    console.log('objTag', objTag);
    localStorage.setItem('configurator-summary', JSON.stringify(objTag));
    history.push('/request-quote/' + configuratorDetails._id);
  }

  const handlePreview = (num) => {
    const temp_state = { ...nextDiv };
    const object2 = Object.fromEntries(
      Object.entries(temp_state).map(([key, val]) => [key, key === 'step' + num ? true : false]),
    );

    switch (num) {
      case 2:
        setSummaryShow(true);
        setTimeout(() => {
          $('.quote-btn-active').addClass('active');
        }, 100);
        break;
      case 3:
        $('.view-active').addClass('active').siblings().removeClass('active');;
        break;
      default:
        $('.quote-btn-active').removeClass('active');
        $('.view-active').removeClass('active');
        $('.scroll-overflow').css('overflow', 'auto');
        setSummaryShow(false);
        user.configuratorInfo = true;
        setUserInfo(user);
        break;
    }
    setNextDiv(object2)
  }

  const viewInfo = (num) => {
    if (!!num) handlePreview(num);
    window.scrollTo(0, 0);
    $('.scroll-overflow').css('overflow', 'hidden');
  }
  const configureInstruction = () => {
    return (
      <>
        {nextDiv.step1 ?
          <div className="overlay-div1">
            <div className="overlayshow3">
              <div className="arrowimage">
                <img className="ondesktop" src="images\arrow.png" alt="" />
                <img className="onphone" src="images\arrow-phone.svg" alt="" />
              </div>
              <div className="info-div">
                <h4>Proceed</h4>
                <p>Click on the Proceed button to view the summary of the selected parts and see its 360° view or request for quote.</p>
                <div className="nxt-btn">
                  <button type="button" className="btnnxt" onClick={() => { handlePreview(2) }}>Next</button>
                  <button type="button" className="btnnxt" onClick={() => { handlePreview(0) }}>Skip</button>
                </div>
              </div>
            </div>
          </div> :

          nextDiv.step2 ?
            <div className="overlay-div2" >
              <div className="overlayshow2">
                <div className="arrowimage">
                  <img className="ondesktop" src="images\arrow.png" alt="" />
                  <img className="onphone" src="images\arrow-phone.svg" alt="" />
                </div>
                <div className="info-div">
                  <h4>Request a Quote</h4>
                  <p>Click on the button to request the quote for the assets by selecting the required parts of your choice.</p>
                  <div className="nxt-btn">
                    <button type="button" onClick={() => { handlePreview(3) }} className="btnnxt">Next</button>
                    <button type="button" className="btnnxt" onClick={() => { handlePreview(0) }}>Skip</button>
                  </div>
                </div>
              </div>
            </div>
            : nextDiv.step3 ?
              <div className="overlay-div3">
                <div className="overlayshow3">
                  <div className="arrowimage">
                    <img className="ondesktop" src="images\arrow.png" alt="" />
                    <img className="onphone" src="images\arrow-phone.svg" alt="" />
                  </div>
                  <div className="info-div">
                    <h4>360° view</h4>
                    <p>Click on the button to view the 360° of the selected parts.</p>
                    <div className="nxt-btn">
                      <button type="button" onClick={() => { handlePreview(4) }} className="btnnxt">Finish</button>
                    </div>
                  </div>
                </div>
              </div>
              : ""}
      </>
    )
  }

  return (
    <div className="configurator-section">
      <TitleSetter title="Asset Configurator" />
      {configureInstruction()}

      <div className="row">
        <div className="col-md-3 col-lg-2 p-zero">
          <div className="configurator-controller ">
            {summaryShow ?
              <div className="configurator-machine-name pad-zero">
                <h4 className="no-border">
                  <button type="button" className="btn" onClick={compensationProcess}>
                    <img src="images/cancel.png" alt="" />
                  </button> Summary</h4>
                {/* <p>{assetsName}</p>  */}
              </div>
              : <div className="configurator-machine-name">
                {assetsName}
              </div>
            }
            <div className="configuator-list">
              <ul className={summaryShow === true ? "disabled" : ""}>
                {configuratorDetails?.labels?.map((label, index) => (
                  <li key={index}>
                    <h4>
                      {label.labelName}
                    </h4>
                    <ul id="label-tag" className={'tags' + index}>
                      {label.tags.map((tag, tIndex) => (
                        <li
                          key={tIndex}
                          className={tIndex === 0 ? 'active-tag' : ''}
                          id={label.labelName + '_' + tag.tag}
                        >
                          <img className="machine-sm-img" src={tag.image} alt="" onClick={() => selectImage(configuratorDetails)} />
                          <span className="text-white">{tag.tag}</span>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>


            {!summaryShow ? <div className="bottom-btn">
              <button className="btn btn-outline-dark" onClick={() => viewInfo(1)}>
                <span>Info</span>
                <FontAwesomeIcon icon={faInfo} />
              </button>

              <button className="btn btn-outline-dark" onClick={compensationProcess}>
                <span>Proceed</span>
                <img src="images/proced-arrow.png" alt="" />
              </button>
            </div> : <div className="bottom-btn">
              <button className="btn btn-outline-dark quote-btn-active" onClick={createQuote}> <span>REQUEST A QUOTE</span> <img src="images/request.png" alt="" /></button>
              <button className="btn btn-outline-dark view-active" onClick={view360Modal}>  {!view360 ? <div><span>360° View</span> <img src="images/360-degree.png" alt="" /></div> : <div><span>Image View</span> <img src="images/settingss.png" alt="" /></div> }</button>
            </div>}
          </div>
        </div>
        <div className={`col-md-9 col-lg-10 configurator-image`}>
          {!view360 ? <ImageGallery
            ref={refImg}
            startIndex={index}
            items={view}
            showBullets={false}
            fullscreen={true}
            showFullscreenButton={false}
            showPlayButton={false}
            showNav={true}
            showThumbnails={false}
            lazyLoad={true}
          // autoPlay={true}
          // slideInterval={5000}
          /> :
            <Configurator360View serverDir={mainEntryFor360} />
          }
        </div>

      </div>
    </div>
  )
}

export default IsLoadingHOC(Configurator);