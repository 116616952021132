export const SUB_ASSEMBLY_ROUTE_MAPPING = {
  SUB1: "sub1",
  SUB2: "sub2",
  SUB3: "sub3",
  SUB2_1: "sub2-1",
};

export const BACKEND_API_URL = process.env.REACT_APP_API_URL;

export const REST_API_METHODS = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST",
  DELETE: "DELETE",
};

export const phoneRegExp = '[789][0-9]{9}';
export const nameRegExp = '[a-zA-Z][a-zA-Z ]+';
export const emailRegExp = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
export const addressRegEx = '[a-zA-Z0-9][a-zA-Z0-9 ,-/]+';
export const pinCodeRegExp = '[0-9]{6}';

export const Country_Code = 'in';
export const Mobile_Max_Length = 15;
export const Mobile_Min_Length = 6;
