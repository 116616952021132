import React, { useEffect, useState } from "react";
import * as yup from "yup";
import "../../styles/pages/user-management.css";
import { useFieldArray, useForm } from "react-hook-form";
import {
  callBackendAPI,
  callBackendAPIForm,
  showInfo,
  showMessage,
  unique,
  userInfo,
} from "../../utils/helpers";
import { REST_API_METHODS } from "../../utils/constants";
import Error from "../../components/common/Error";
import TitleSetter from "../../components/common/TitleSetter";
import IsLoadingHOC from "../../components/common/IsLoadingHOC";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../modal/Modal";


const formSchema = {
  machine_id: yup.string().required("form.required_message"),
  assets_id: yup.string().required("form.required_message"),
  serialNo: yup.string().required("form.required_message"),
};

const fieldsObj = {
  _id: "",
  user: {},
  machine_id: "",
  assets_id: "",
  assets_serialNo: "",
  serialNo: "",
  matchPathActive: false
};

const fieldsSchema = yup.object().shape({
  test: yup
    .array()
    .of(yup.object().shape(formSchema))
    .required("Must have fields")
    .min(1, "Minimum of 1 field"),
});

let machinesLinkedArray = [];
const AssetsForm = (props) => {
  const { setLoading, isLoading } = props;
  const { id } = useParams();

  const user = userInfo();
  const history = useHistory();
  const matchPathActive = history.location.pathname.match("/assets-link/edit/")
    ? true
    : false;

  const [apiError, setApiError] = useState("");
  const [machines, setMachines] = useState([]);
  const [machinesAssets, setMachinesAssets] = useState([[]]);
  const [show, setShow] = useState(false);
  const [assets, setAssets] = useState({});

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      machines: [fieldsObj],
    },
    validationSchema: fieldsSchema,
    mode: "onChange",
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "machines",
  });

  useEffect(async () => {
    setLoading(false);
    getUsersAssets();
  }, []);

  const getUsersAssets = async (fetchAsstes = true) => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getMachineForRaiseTicket?userId=${user._id}`,
        REST_API_METHODS.GET
      );
      if (!!response?.status && !!response.data.length) {
        machinesLinkedArray = response.data;

        //bind serial number into machine and assets object
        machinesLinkedArray.map((ele) => {
          Object.keys(ele).forEach((key) => {
            if (key == "machine" || key == "assets") {
              const newData = ele[key];
              newData.serialNo = ele.serialNo;
              newData.assigned = ele.assigned;
              ele[key] = newData;
            }
          });
        });

        const machinesResponse = machinesLinkedArray.map((a) => a.machine);
        const uniqueMachine = unique(machinesResponse);
        setMachines(uniqueMachine);

        if (!!matchPathActive && !!fetchAsstes) {
          getAssetsDetails();
        } else {
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getAssetsDetails = async () => {
    setLoading(true);
    const response = await callBackendAPI(
      `getAssetsForSubUser?id=${id}`,
      REST_API_METHODS.GET
    );
    if (!!response?.status && !!response.data) {
      const responseDate = response.data;
      let temp_state = [...machinesAssets];
      responseDate.forEach((ele, index) => {
        if (index > 0) {
          append(fieldsObj);
        }

        let temp_fild = { ...fields[index] };
        temp_fild._id = ele._id;
        temp_fild.user = ele.user;
        temp_fild.machine_id = ele.machine._id;
        temp_fild.assets_id = ele.assets._id;
        temp_fild.assets_serialNo = ele.serialNo;
        temp_fild.serialNo = ele.serialNo;
        temp_fild.matchPathActive = matchPathActive;
        update(index, temp_fild);

        const assetsResponse = machinesLinkedArray.map((a) => a.assets);
        const filleterAssets = assetsResponse.filter(
          (a) => a.machine === ele.machine._id
        );
        temp_state[index] = filleterAssets;
      });
      setMachinesAssets(temp_state);
    }
    setLoading(false);
  };

  const handelMachine = (event, index) => {
    const id = event?.target ? event.target.value : event;
    // filter assets and machine matches and assigned 
    const assetsResponse = machinesLinkedArray.map((a) => a.assets);
    const filleterAssets = assetsResponse.filter((a) => (a.machine === id));

    let selectedMachines = getValues('machines');
    selectedMachines = selectedMachines.filter(a => a.serialNo != '');

    const filterData = [];
    for (let index = 0; index < filleterAssets.length; index++) {
      const element = filleterAssets[index];
      const matchIndex = selectedMachines.findIndex(a => a.serialNo === element.serialNo);
      if (!(matchIndex > -1)) {
        filterData.push(element);
      }
    }

    let temp_state = [...machinesAssets];
    temp_state[index] = filterData;
    setMachinesAssets(temp_state);
  };

  const onSubmit = handleSubmit(
    async (values) => {
      setApiError("");
      try {
        setLoading(true);
        const payload = {
          machines: JSON.stringify(values.machines),
          parentId: user._id,
          userId: id
        };
        const response = await callBackendAPI(
          "setAssetsForSubUser",
          REST_API_METHODS.POST,
          payload
        );
        if (!!response.status) {
          history.goBack();
        } else {
          setApiError(response);
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    },
    (errors) => {
      console.log("errors", errors);
    }
  );

  const removeAssets = async (item, index) => {
    if (!!item._id) {
      item.index = index;
      setAssets({ ...assets, ...item });
      setShow(true);
    } else {
      remove(index);
    }
  }

  const confirmHandler = async () => {
    try {
      setLoading(true);
      const payload = {
        id: assets._id,
        userId: assets.user._id,
        parentId: user._id
      }

      const response = await callBackendAPI(
        `assignAssetsRemove`,
        REST_API_METHODS.DELETE,
        payload
      );
      if (!!response.status) {
        remove(assets.index);
        getUsersAssets(false);
        closeModal();
      } else {
        setLoading(false);
      }
      showMessage(response.message);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  const closeModal = () => {
    setShow(false);
    setLoading(false);
  }

  return (
    <>
      <TitleSetter title={"Assets Link"} />
      <div className="user-management">
        <div className="cartPage-heading">
          <h3>Link Assets</h3>
          <div className="common_btn">
            <button type="submit" onClick={onSubmit}>
              Submit
            </button>
          </div>
        </div>
        <div className="container">
          <div className="useform-wrap">
            <div className="row">
              <div className="col-md-12">
                <div>
                  {fields.map((item, index) => {
                    return (
                      <div
                        className="formData-wrap common_input_warp"
                        key={index}
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <select
                                {...register(`machines[${index}].machine_id`, {
                                  required: "This is required message",
                                })}
                                className="form-control"
                                name={`machines[${index}].machine_id`}
                                value={item.machine_id}
                                id=""
                                onChange={(e) => {
                                  handelMachine(e, index);
                                  let temp_fild = { ...item };
                                  temp_fild.machine_id = e.target.value;
                                  temp_fild.assets_id = '';
                                  temp_fild.serialNo = '';
                                  update(index, temp_fild);
                                }}
                                style={{
                                  backgroundImage: `url(${process.env.PUBLIC_URL +
                                    "/images/down_arrow.png"
                                    })`,
                                }}
                                disabled={item.matchPathActive === true}
                              >
                                <option value="">Select assets group</option>
                                {machines.map((machine, index) => (
                                  <option key={index} value={machine._id}>
                                    {machine.name}
                                  </option>
                                ))}
                              </select>
                              {!!errors.machines?.[index]?.machine_id && (
                                <Error
                                  message={
                                    errors.machines?.[index]?.machine_id
                                      ?.message
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <select
                                {...register(`machines[${index}].assets_serialNo`, {
                                  required: "This is required message",
                                })}
                                className="form-control"
                                name={`machines[${index}].assets_serialNo`}
                                id="assets_serialNo"
                                value={item.assets_serialNo}
                                style={{
                                  backgroundImage: `url(${process.env.PUBLIC_URL +
                                    "/images/down_arrow.png"
                                    })`,
                                }}
                                onChange={(event) => {
                                  const serialNo = event.target.value;
                                  const assetsFind = machinesAssets?.[
                                    index
                                  ].find((a) => a.serialNo === serialNo);

                                  let selectedMachines = getValues('machines');
                                  const match = selectedMachines.some(a => a.serialNo == serialNo);
                                  if (!match) {
                                    let temp_fild = { ...item };
                                    temp_fild.assets_id = assetsFind._id;
                                    temp_fild.assets_serialNo = assetsFind.serialNo;
                                    temp_fild.serialNo = assetsFind.serialNo;
                                    update(index, temp_fild);
                                  } else {
                                    showInfo(`This ${serialNo} serial no already exists.`)
                                  }
                                }}
                                disabled={item.matchPathActive === true}
                              >
                                <option value="">Select assets</option>
                                {/* {!!(machinesAssets.length > index) && */}
                                {machinesAssets?.[index]?.map(
                                  (access, index) => (
                                    <option key={index} value={access.serialNo}>
                                      {access.name} - {access.serialNo}
                                    </option>
                                  )
                                )}
                              </select>
                              {!!errors.machines?.[index]?.assets_id && (
                                <Error
                                  message={
                                    errors.machines?.[index]?.assets_id?.message
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <input
                                {...register(`machines[${index}].serialNo`, {
                                  required: "This is required message",
                                })}
                                type="text"
                                name={`machines[${index}].serialNo`}
                                value={item.serialNo}
                                autoComplete="off"
                                className="form-control"
                                placeholder="Serial No"
                                disabled
                              />
                              {!!errors.machines?.[index]?.serialNo && (
                                <Error
                                  message={
                                    errors.machines?.[index]?.serialNo?.message
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              {fields.length > 1 && (
                                <button
                                  className="dtlbtn-class text-right"
                                  type="button"
                                  onClick={() => {
                                    removeAssets(item, index);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* <div className="row">
                          <div className="col-md-12">
                            {fields.length > 1 && (
                              <button
                                className="dtlbtn-class text-right"
                                type="button"
                                onClick={() => {
                                  machinesLinkedArray.map((a) => {
                                    if (a.assets?.serialNo === item.serialNo) {
                                      a.assets.assigned = false;
                                      a.machine.assigned = false;
                                      a.assigned = false;
                                    };
                                    return a;
                                  });
                                  remove(index)
                                }}
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        </div> */}

                        {!!apiError && <Error message={apiError} />}
                      </div>
                    );
                  })}
                  <div className="common_btn addmorebtn">
                    <button
                      type="button"
                      className=""
                      onClick={() => {
                        append(fieldsObj);
                      }}
                    >
                      Add More
                    </button>
                    {/* <button type="submit" className="w-25 ml-4">
                      Submit
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={"Confirm"}
        onClose={() => setShow(false)}
        show={show}
      >
        <div className="">
          <p>Are you sure you want to delete this assets?</p>
          <div className="common_btn">
            <button className="confirm-alert-btn" onClick={confirmHandler}>Yes</button>
            <button className="confirm-alert-btn" onClick={closeModal}>No</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IsLoadingHOC(AssetsForm);
