import React, { useEffect, useState } from "react";
import "../styles/pages/settings.css";
import TitleSetter from "../components/common/TitleSetter";
import {
  ChangePasswordFeature,
  FQAFeature,
  TermsConditionsFeature,
} from "../components/settings";
import { callBackendAPI } from "../utils/helpers";
import { REST_API_METHODS } from "../utils/constants";
import IsLoadingHOC from "../components/common/IsLoadingHOC";
import NotificationManagement from "./NotificationManagement";

const Settings = (props) => {
  const { setLoading } = props;
  const [description, setDescription] = useState("");

  useEffect(() => {
    setLoading(false);
  }, []);

  const onChangeHandler = async (type) => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getContents?type=${type}`,
        REST_API_METHODS.GET
      );
      if (!!response.status) {
        setDescription(response.data.contents);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <TitleSetter title={"Settings"} />
      <div className="explorewrap setting-page termscondition">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="explorecont setting_wrap settingcard">
                <div className="cartPage-heading">
                  <h3>Settings</h3>
                </div>

                <div className="settingpage-item">
                  <div className="setingitem-wrap">
                    <ul className="nav nav-pills">
                      <li className="active">
                        <a href="#faq" data-toggle="tab" className="active">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 20 20"
                          >
                            <path
                              d="M12,2A10,10,0,1,0,22,12,10.029,10.029,0,0,0,12,2Zm0,15a1,1,0,0,1,0-2,1,1,0,0,1,0,2Zm1-4a1.075,1.075,0,0,1-1,1,1.075,1.075,0,0,1-1-1v-.7a.961.961,0,0,1,.7-.9l.7-.2a.875.875,0,0,0,.6-.9.9.9,0,0,0-.9-.9H12a1.234,1.234,0,0,0-.8.4.984.984,0,0,1-1.4.3,1.12,1.12,0,0,1-.3-1.5,2.945,2.945,0,0,1,2.4-1.3H12a2.9,2.9,0,0,1,2.9,2.9A2.71,2.71,0,0,1,13,13Z"
                              transform="translate(-2 -2)"
                              fill="#b5b5b5"
                            />
                          </svg>
                          FAQs
                        </a>
                      </li>
                      <li onClick={() => onChangeHandler(3)}>
                        <a href="#termscondition" data-toggle="tab">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="31.848"
                            height="28.809"
                            viewBox="0 0 31.848 28.809"
                          >
                            <g transform="translate(-3.109 -7.726)">
                              <path
                                d="M9.168,18.78l-.415-.492a.329.329,0,0,0-.5.424l.415.492a.329.329,0,1,0,.5-.424Zm1.346,2.108a.329.329,0,0,0,.04-.464l-.415-.492a.329.329,0,1,0-.5.424l.415.492a.329.329,0,0,0,.459.04Zm.208-2.432a.329.329,0,0,0-.464-.04l-.492.415a.329.329,0,0,0,.424.5l.492-.415a.329.329,0,0,0,.039-.46ZM8.545,20.72l.492-.415a.329.329,0,1,0-.424-.5l-.492.415a.329.329,0,0,0,.424.5Zm.12,10.411a.329.329,0,0,0,.5-.424l-.415-.492a.329.329,0,1,0-.5.424Zm.97,1.152.415.492a.329.329,0,0,0,.5-.424l-.415-.492a.329.329,0,0,0-.5.424Zm1.086-1.9a.329.329,0,0,0-.464-.04l-.492.415a.329.329,0,0,0,.424.5l.492-.415a.329.329,0,0,0,.04-.461ZM9.077,31.768a.329.329,0,0,0-.464-.04l-.492.415a.329.329,0,1,0,.424.5l.492-.415a.329.329,0,0,0,.04-.46ZM35.387,9.421A.329.329,0,0,0,35.89,9l-.415-.5a.329.329,0,1,0-.5.424Zm1.385,1.645a.329.329,0,0,0,.5-.424l-.415-.492a.329.329,0,0,0-.5.424Zm.14-1.514.493-.415a.329.329,0,1,0-.424-.5l-.493.415a.329.329,0,1,0,.424.5Zm-1.644,1.385.492-.415a.329.329,0,1,0-.424-.5l-.492.415a.329.329,0,0,0,.424.5ZM37.911,36.32H36.124a.329.329,0,1,0,0,.658h1.788a.329.329,0,0,0,0-.658Z"
                                transform="translate(-3.284 -0.443)"
                                fill="#b5b5b5"
                              />
                              <path
                                d="M31.7,35.877H30.511a1.29,1.29,0,0,0,.44-.966V23.061a.97.97,0,0,1-.2-.431L28.492,25.4a1.07,1.07,0,0,1-.793.4l-5.166,6.344c-.188.086-2.856,1.3-2.889,1.32a.719.719,0,0,1-.319.072.767.767,0,0,1-.566-.257.971.971,0,0,1-.346-.109,1.362,1.362,0,0,1-.141-.1.491.491,0,0,0-.237-.118,6.257,6.257,0,0,0-1.264.355.656.656,0,0,1-.816-.29,2.184,2.184,0,0,0-.6-.694,1.1,1.1,0,0,0-.54.023c-.1.016-.191.033-.283.046a3.071,3.071,0,0,1-.339.026,1.805,1.805,0,0,1-.632,1.1,1.332,1.332,0,0,1-.81.263,1.517,1.517,0,0,1-.244-.02,1.37,1.37,0,0,1-.918-.573.329.329,0,1,1,.546-.365.725.725,0,0,0,.48.29.715.715,0,0,0,.546-.118,1.12,1.12,0,0,0,.369-.635,1.588,1.588,0,0,1-.313-.1,1.259,1.259,0,0,1-.763-1.527.938.938,0,0,1,1.82.165,2.461,2.461,0,0,1,.02.833.091.091,0,0,1,0,.03,1.612,1.612,0,0,0,.165-.016q.118-.015.237-.039a1.606,1.606,0,0,1,.9.01,2.573,2.573,0,0,1,.944.994,5.093,5.093,0,0,1,1.612-.4,1.042,1.042,0,0,1,.457.194c.122-.533.681-2.975.688-3,1.1-1.349,1.991-2.445,2.718-3.34H11.532a.329.329,0,1,1,0-.658H22.546l1.909-2.346a1.063,1.063,0,0,1,.2-.809H11.532a.329.329,0,0,1,0-.658h13.65l2.57-3.156H11.532a.329.329,0,0,1,0-.658H28.285l1.385-1.7a1.069,1.069,0,0,1,.836-.4,1.13,1.13,0,0,1,.316.046l.128-.155V10.191a2.465,2.465,0,0,0-2.465-2.465H7.876a2.789,2.789,0,0,1,1,2.142V16.5L8.856,34.91a1.29,1.29,0,0,0,.44.966H5.728a.329.329,0,1,0,0,.658H31.7a.329.329,0,1,0,0-.658ZM11.532,10.237h16.74a.329.329,0,1,1,0,.658H11.532a.329.329,0,0,1,0-.658Zm0,3.814h16.74a.329.329,0,1,1,0,.658H11.532a.329.329,0,0,1,0-.658ZM4.451,35.877H3.438a.329.329,0,1,0,0,.658H4.451a.329.329,0,1,0,0-.658Z"
                                fill="#b5b5b5"
                              />
                              <path
                                d="M6.654,15.849H9.907V9.871a2.141,2.141,0,0,0-4.282,0V14.82a1.028,1.028,0,0,0,1.029,1.029ZM15.03,30.743a.262.262,0,0,0-.053.006.307.307,0,0,0-.214.2.62.62,0,0,0,.409.708,1.071,1.071,0,0,0,.15.054v-.046a1.9,1.9,0,0,0,0-.611C15.314,31.018,15.239,30.743,15.03,30.743Zm13.676-5.149-.046-.036-2.139-1.741a.52.52,0,0,1-.043-.039L22,29.283,24.225,31.1Zm3.161-9.01-4.99,6.13a.422.422,0,0,0,.061.594l2.137,1.74a.422.422,0,0,0,.594-.061l4.462-5.48.4.328a.437.437,0,0,1,.063.614l-1.451,1.782a.329.329,0,1,0,.51.416L35.1,20.865a1.1,1.1,0,0,0-.158-1.54l-.4-.328.113-.139a.422.422,0,0,0-.061-.594l-.251-.2.532-.647a1.092,1.092,0,1,0-1.686-1.387l-.551.645-.181-.148a.422.422,0,0,0-.593.057ZM20.91,32.744a.107.107,0,0,0,.149.121l2.752-1.259-2.224-1.813Z"
                                transform="translate(-1.689 -0.003)"
                                fill="#b5b5b5"
                              />
                            </g>
                          </svg>
                          Terms & Conditions
                        </a>
                      </li>
                      <li onClick={() => onChangeHandler(2)}>
                        <a href="#privacypolicy" data-toggle="tab">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="31.848"
                            height="28.809"
                            viewBox="0 0 31.848 28.809"
                          >
                            <g transform="translate(-3.109 -7.726)">
                              <path
                                d="M9.168,18.78l-.415-.492a.329.329,0,0,0-.5.424l.415.492a.329.329,0,1,0,.5-.424Zm1.346,2.108a.329.329,0,0,0,.04-.464l-.415-.492a.329.329,0,1,0-.5.424l.415.492a.329.329,0,0,0,.459.04Zm.208-2.432a.329.329,0,0,0-.464-.04l-.492.415a.329.329,0,0,0,.424.5l.492-.415a.329.329,0,0,0,.039-.46ZM8.545,20.72l.492-.415a.329.329,0,1,0-.424-.5l-.492.415a.329.329,0,0,0,.424.5Zm.12,10.411a.329.329,0,0,0,.5-.424l-.415-.492a.329.329,0,1,0-.5.424Zm.97,1.152.415.492a.329.329,0,0,0,.5-.424l-.415-.492a.329.329,0,0,0-.5.424Zm1.086-1.9a.329.329,0,0,0-.464-.04l-.492.415a.329.329,0,0,0,.424.5l.492-.415a.329.329,0,0,0,.04-.461ZM9.077,31.768a.329.329,0,0,0-.464-.04l-.492.415a.329.329,0,1,0,.424.5l.492-.415a.329.329,0,0,0,.04-.46ZM35.387,9.421A.329.329,0,0,0,35.89,9l-.415-.5a.329.329,0,1,0-.5.424Zm1.385,1.645a.329.329,0,0,0,.5-.424l-.415-.492a.329.329,0,0,0-.5.424Zm.14-1.514.493-.415a.329.329,0,1,0-.424-.5l-.493.415a.329.329,0,1,0,.424.5Zm-1.644,1.385.492-.415a.329.329,0,1,0-.424-.5l-.492.415a.329.329,0,0,0,.424.5ZM37.911,36.32H36.124a.329.329,0,1,0,0,.658h1.788a.329.329,0,0,0,0-.658Z"
                                transform="translate(-3.284 -0.443)"
                                fill="#b5b5b5"
                              />
                              <path
                                d="M31.7,35.877H30.511a1.29,1.29,0,0,0,.44-.966V23.061a.97.97,0,0,1-.2-.431L28.492,25.4a1.07,1.07,0,0,1-.793.4l-5.166,6.344c-.188.086-2.856,1.3-2.889,1.32a.719.719,0,0,1-.319.072.767.767,0,0,1-.566-.257.971.971,0,0,1-.346-.109,1.362,1.362,0,0,1-.141-.1.491.491,0,0,0-.237-.118,6.257,6.257,0,0,0-1.264.355.656.656,0,0,1-.816-.29,2.184,2.184,0,0,0-.6-.694,1.1,1.1,0,0,0-.54.023c-.1.016-.191.033-.283.046a3.071,3.071,0,0,1-.339.026,1.805,1.805,0,0,1-.632,1.1,1.332,1.332,0,0,1-.81.263,1.517,1.517,0,0,1-.244-.02,1.37,1.37,0,0,1-.918-.573.329.329,0,1,1,.546-.365.725.725,0,0,0,.48.29.715.715,0,0,0,.546-.118,1.12,1.12,0,0,0,.369-.635,1.588,1.588,0,0,1-.313-.1,1.259,1.259,0,0,1-.763-1.527.938.938,0,0,1,1.82.165,2.461,2.461,0,0,1,.02.833.091.091,0,0,1,0,.03,1.612,1.612,0,0,0,.165-.016q.118-.015.237-.039a1.606,1.606,0,0,1,.9.01,2.573,2.573,0,0,1,.944.994,5.093,5.093,0,0,1,1.612-.4,1.042,1.042,0,0,1,.457.194c.122-.533.681-2.975.688-3,1.1-1.349,1.991-2.445,2.718-3.34H11.532a.329.329,0,1,1,0-.658H22.546l1.909-2.346a1.063,1.063,0,0,1,.2-.809H11.532a.329.329,0,0,1,0-.658h13.65l2.57-3.156H11.532a.329.329,0,0,1,0-.658H28.285l1.385-1.7a1.069,1.069,0,0,1,.836-.4,1.13,1.13,0,0,1,.316.046l.128-.155V10.191a2.465,2.465,0,0,0-2.465-2.465H7.876a2.789,2.789,0,0,1,1,2.142V16.5L8.856,34.91a1.29,1.29,0,0,0,.44.966H5.728a.329.329,0,1,0,0,.658H31.7a.329.329,0,1,0,0-.658ZM11.532,10.237h16.74a.329.329,0,1,1,0,.658H11.532a.329.329,0,0,1,0-.658Zm0,3.814h16.74a.329.329,0,1,1,0,.658H11.532a.329.329,0,0,1,0-.658ZM4.451,35.877H3.438a.329.329,0,1,0,0,.658H4.451a.329.329,0,1,0,0-.658Z"
                                fill="#b5b5b5"
                              />
                              <path
                                d="M6.654,15.849H9.907V9.871a2.141,2.141,0,0,0-4.282,0V14.82a1.028,1.028,0,0,0,1.029,1.029ZM15.03,30.743a.262.262,0,0,0-.053.006.307.307,0,0,0-.214.2.62.62,0,0,0,.409.708,1.071,1.071,0,0,0,.15.054v-.046a1.9,1.9,0,0,0,0-.611C15.314,31.018,15.239,30.743,15.03,30.743Zm13.676-5.149-.046-.036-2.139-1.741a.52.52,0,0,1-.043-.039L22,29.283,24.225,31.1Zm3.161-9.01-4.99,6.13a.422.422,0,0,0,.061.594l2.137,1.74a.422.422,0,0,0,.594-.061l4.462-5.48.4.328a.437.437,0,0,1,.063.614l-1.451,1.782a.329.329,0,1,0,.51.416L35.1,20.865a1.1,1.1,0,0,0-.158-1.54l-.4-.328.113-.139a.422.422,0,0,0-.061-.594l-.251-.2.532-.647a1.092,1.092,0,1,0-1.686-1.387l-.551.645-.181-.148a.422.422,0,0,0-.593.057ZM20.91,32.744a.107.107,0,0,0,.149.121l2.752-1.259-2.224-1.813Z"
                                transform="translate(-1.689 -0.003)"
                                fill="#b5b5b5"
                              />
                            </g>
                          </svg>
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="#changePassword" data-toggle="tab">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="31.331"
                            height="32.06"
                            viewBox="0 0 31.331 32.06"
                          >
                            <g
                              id="Group_262581"
                              data-name="Group 262581"
                              transform="translate(-119 -630.47)"
                            >
                              <path
                                id="Path_280844"
                                data-name="Path 280844"
                                d="M17.692,10a4.274,4.274,0,0,0-4.269,4.269v1.423A1.423,1.423,0,0,0,12,17.115v7.115a1.423,1.423,0,0,0,1.423,1.423h8.538a1.423,1.423,0,0,0,1.423-1.423V17.115a1.423,1.423,0,0,0-1.423-1.423V14.269A4.274,4.274,0,0,0,17.692,10Zm-1.423,5.692V14.269a1.423,1.423,0,0,1,2.846,0v1.423Z"
                                transform="translate(116.973 627.961)"
                                fill="#b5b5b5"
                                opacity="1"
                              />
                              <path
                                id="Path_280845"
                                data-name="Path 280845"
                                d="M31.643,9.615a15.681,15.681,0,0,0-19.722-1.84L11.9,5.442a.712.712,0,0,0-.717-.706l-1.423.01a.711.711,0,0,0-.706.717l.04,5.326a1.423,1.423,0,0,0,2.437.988A12.808,12.808,0,0,1,32.352,25.989a.7.7,0,0,0,.311.925l1.27.649a.723.723,0,0,0,.982-.332A15.6,15.6,0,0,0,31.643,9.615ZM31.33,29.434a1.422,1.422,0,0,0-1.549.32A12.808,12.808,0,0,1,8.963,15.542a.7.7,0,0,0-.311-.925l-1.27-.649A.723.723,0,0,0,6.4,14.3,15.656,15.656,0,0,0,29.394,33.757l.018,2.333a.712.712,0,0,0,.717.706l1.423-.01a.711.711,0,0,0,.706-.717l-.04-5.326A1.423,1.423,0,0,0,31.33,29.434Z"
                                transform="translate(114.008 625.734)"
                                fill="#b5b5b5"
                                opacity="1"
                              />
                            </g>
                          </svg>
                          Change Password
                        </a>
                      </li>
                      <li>
                        <a href="#notification" data-toggle="tab">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28.05"
                            height="32.057"
                            viewBox="0 0 28.05 32.057"
                          >
                            <g
                              id="Group_262583"
                              data-name="Group 262583"
                              transform="translate(-120.64 -630.471)"
                            >
                              <path
                                id="Path_280844"
                                data-name="Path 280844"
                                d="M17.692,10a4.274,4.274,0,0,0-4.269,4.269v1.423A1.423,1.423,0,0,0,12,17.115v7.115a1.423,1.423,0,0,0,1.423,1.423h8.538a1.423,1.423,0,0,0,1.423-1.423V17.115a1.423,1.423,0,0,0-1.423-1.423V14.269A4.274,4.274,0,0,0,17.692,10Zm-1.423,5.692V14.269a1.423,1.423,0,0,1,2.846,0v1.423Z"
                                transform="translate(116.973 627.961)"
                                fill="#6c6c6c"
                                opacity="1"
                              />
                              <path
                                id="Icon_awesome-bell"
                                data-name="Icon awesome-bell"
                                d="M14.025,32.057A4.006,4.006,0,0,0,18.03,28.05H10.02A4.006,4.006,0,0,0,14.025,32.057Zm13.486-9.374c-1.21-1.3-3.473-3.255-3.473-9.66a9.887,9.887,0,0,0-8.01-9.715V2a2,2,0,1,0-4,0v1.3a9.887,9.887,0,0,0-8.01,9.715c0,6.405-2.263,8.36-3.473,9.66A1.956,1.956,0,0,0,0,24.043a2.005,2.005,0,0,0,2.01,2H26.04a2,2,0,0,0,2.01-2A1.955,1.955,0,0,0,27.511,22.683Z"
                                transform="translate(120.64 630.471)"
                                fill="#b5b5b5"
                              />
                            </g>
                          </svg>
                          Notification Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="itemcontent-wrap">
                    <div className="tab-content">
                      <div role="tabpanel" className="tab-pane in active" id="faq">
                        <FQAFeature />
                      </div>
                      <div role="tabpanel" className="tab-pane" id="termscondition">
                        <TermsConditionsFeature
                          title={"Terms & Conditions"}
                          description={description}
                        />
                      </div>
                      <div role="tabpanel" className="tab-pane" id="privacypolicy">
                        <TermsConditionsFeature
                          title={"Privacy Policy"}
                          description={description}
                        />
                      </div>
                      <div role="tabpanel" className="tab-pane" id="changePassword">
                        <ChangePasswordFeature />
                      </div>
                      <div role="tabpanel" className="tab-pane" id="notification">
                        <NotificationManagement />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(Settings);
