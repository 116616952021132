import React from "react";
import { Link } from "react-router-dom";

const MoreButton = (params) => {
  const { path } = params;
  return (
    <Link to={path}>
     <h5>Explore More</h5>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30.851"
          height="30.851"
          viewBox="0 0 30.851 30.851"
        >
          <g id="Group_403" data-name="Group 403" transform="translate(0)">
            <ellipse
              id="Ellipse_4"
              data-name="Ellipse 4"
              cx="15.425"
              cy="15.425"
              rx="15.425"
              ry="15.425"
              transform="translate(0 0)"
              fill="#fff"
            />
            <g
              id="Group_16"
              data-name="Group 16"
              transform="translate(6.097 6.098)"
            >
              <path
                id="Path_77"
                data-name="Path 77"
                d="M0,0H18.657V18.657H0Z"
                transform="translate(0 0)"
                fill="none"
              />
              <path
                id="Path_78"
                data-name="Path 78"
                d="M8.442,5.332V6.887H4.555v8.551h8.551V11.551H14.66v4.664a.777.777,0,0,1-.777.777H3.777A.777.777,0,0,1,3,16.215V6.109a.777.777,0,0,1,.777-.777ZM16.993,3V9.219H15.438V5.653L9.38,11.712l-1.1-1.1,6.057-6.058H10.774V3Z"
                transform="translate(-0.668 -0.668)"
                fill="#1e3e7b"
              />
            </g>
          </g>
        </svg>
      </span>
    </Link>
  );
};

export default MoreButton;
