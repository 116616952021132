import React from "react";

import { useHistory } from "react-router-dom";

const BackButton = () => {
  const history = useHistory();

  return (
    <a href="javascript:void(0)" onClick={() => history.goBack()}>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="72"
          height="56"
          viewBox="0 0 72 56"
        >
          <g
            id="Group_262533"
            data-name="Group 262533"
            transform="translate(-131 -799)"
          >
            <rect
              id="Rectangle_5353"
              data-name="Rectangle 5353"
              width="72"
              height="56"
              rx="8"
              transform="translate(131 799)"
              fill="#1c3e7d"
              opacity="0.234"
            ></rect>
            <g id="Layer_2" data-name="Layer 2" transform="translate(142 803)">
              <g id="arrow-back">
                <rect
                  id="Rectangle_5333"
                  data-name="Rectangle 5333"
                  width="48"
                  height="48"
                  transform="translate(48) rotate(90)"
                  fill="#1c3e7d"
                  opacity="0"
                ></rect>
                <path
                  id="Path_280820"
                  data-name="Path 280820"
                  d="M33.975,16.991h-23.7l7.254-8.713a2,2,0,1,0-3.077-2.558L4.46,17.711a2.378,2.378,0,0,0-.18.3.253.253,0,0,1-.14.26,1.92,1.92,0,0,0,0,1.439.253.253,0,0,0,.14.26,2.378,2.378,0,0,0,.18.3l9.992,11.99A2,2,0,1,0,17.529,29.7l-7.254-8.713h23.7a2,2,0,1,0,0-4Z"
                  transform="translate(3.993 4.99)"
                  fill="#1c3e7d"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </span>
    </a>
  );
};

export default BackButton;
