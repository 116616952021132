import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import $ from 'jquery';

import { BACKEND_API_URL, REST_API_METHODS } from "../utils/constants";
import "../styles/pages/my-submitted.css";
import { callBackendAPI } from "../utils/helpers";
import { useHistory } from "react-router";
import Empty from "../components/common/Empty";
import TitleSetter from "../components/common/TitleSetter";
import IsLoadingHOC from "../components/common/IsLoadingHOC";
import Preference from "./Preference";
import Modal from "../modal/Modal";

const MySubmittedRequest = (props) => {
  const { setLoading, isLoading } = props;
  const history = useHistory();
  const limit = 10;

  const [show, setShow] = useState(false);
  const [submittedHeader, setSubmittedHeader] = useState([]);
  const [submittedRequests, setSubmittedRequests] = useState([]);
  const [submittedRequestsData, setSubmittedRequestsData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [isDownload, setDownload] = useState(false);
  const [defaultPreference, setDefaultPreference] = useState([]);

  const [preference, setPreference] = useState([
    { name: 'Ticket Id', type: '_id', checked: true },
    { name: 'Ticket Id', type: 'ticketId', checked: true },
    { name: 'Ticket Type', type: 'type', checked: true },
    { name: 'Serial No', type: 'serialNo', checked: true },
    { name: 'Assets Name', type: 'assets', checked: true },
    { name: 'Assets Group Name', type: 'machine', checked: false },
    { name: 'Parts Name | Part Number | Quantity', type: 'parts', checked: false },
    { name: 'Expected Delivery Date', type: 'requestDate', checked: false },
    { name: 'Processing Date', type: 'createdAt', checked: false },
    { name: 'Contact', type: 'phone', checked: false },
    { name: 'Status', type: 'ticketStatus', checked: true },
    { name: 'Address', type: 'address', checked: false },
    { name: 'Comment', type: 'comment', checked: false },
    { name: 'Action', type: 'action', checked: true }
  ]);

  useEffect(() => {
    getSubmittedRequest();
    const preferenceKeys = preference.reduce((keys, a) => { if (a.checked) { keys.push(a.type); } return keys; }, []);
    setDefaultPreference(preferenceKeys);
    $('#submitted-request').addClass('active');
  }, []);

  const getSubmittedRequest = async (offset = 0) => {
    try {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await callBackendAPI(
        `getTicketList?userId=${user._id}&skip=${offset}&limit=${limit}`,
        REST_API_METHODS.GET
      );
      if (!!response?.status && response.data?.length) {
        setPageCount(Math.ceil(response.totalItems / limit));
        setSubmittedRequestsData(response.data);
        filterPreference(response.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    getSubmittedRequest(newOffset);
  };

  const downloadHandler = (event, id) => {
    event.stopPropagation();
    setDownload(true);
    const token = localStorage.getItem("token");
    const options = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(`${BACKEND_API_URL + "/api/v1"}/ticketDownload?id=${id}`, options)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((uril) => {
        var link = document.createElement("a");
        link.href = uril;
        link.download = `ticket-${id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownload(false);
      })
      .catch((err) => {
        setDownload(false);
        console.log(err);
      });
  };

  const viewDetails = (event, id) => {
    event.stopPropagation();
    history.push('/submitted-request/' + id)
  }

  const checkedHandler = (event, index) => {
    const checked = event.target.checked;
    const temp_state = [...preference];
    let temp_ele = { ...temp_state[index] };
    temp_ele.checked = checked;
    temp_state[index] = temp_ele;
    setPreference(temp_state);
  }

  const filterPreference = (submittedRequestsData) => {
    const newResult = [];
    let props = preference.flatMap(o => o.checked ? o.type : []);
    submittedRequestsData.forEach(function (o) {
      const obj = props.reduce(function (props, name) {
        props[name] = o[name];
        return props;
      }, {});
      newResult.push(obj);
    });

    let temp_state = [...submittedRequests];
    temp_state = newResult;
    console.log('result', temp_state)

    const headers = preference.filter(a => a.checked);
    let temp_header_state = [...submittedHeader];
    temp_header_state = headers;

    setSubmittedHeader(temp_header_state);
    setSubmittedRequests(temp_state);
    closeModal();
  }

  const applyPreference = () => {
    filterPreference(submittedRequestsData);
  }

  const closeModal = () => {
    setShow(false);
  }

  const resetDefault = () => {
    const temp_state = [...preference];
    temp_state.map(a => {
      if (defaultPreference.includes(a.type)) {
        a.checked = true;
      } else {
        a.checked = false;
      }
      return a;
    });
    setPreference(temp_state);
  }

  const tBody = () => {
    return submittedRequests.map((request, index) => {
      return (
        <tr key={index}>
          {submittedHeader.map((a, i) => {
            if (a.type === 'type' || a.type === 'assets' || a.type === 'machine') {
              return <td key={i + a.type} className="text-capitalize">{request[a.type]?.name || 'N/A'}</td>
            } else if (a.type === 'address') {
              return <td key={i + a.type} className="text-capitalize">
                <span>
                  {request[a.type].address}, {request[a.type].state},{" "}
                  {request[a.type].city}, {request[a.type].pin_code}
                </span>
              </td>
            } else if (a.type === 'parts') {
              return <td key={i + a.type} className="text-capitalize row ml-2 mr-2">
                {request[a.type].length ? (<span>
                  {request[a.type].map((item, index) => (
                    <div className="" key={index}>
                      <span>
                        {item.partName || "--"} <b>|</b>{" "}
                        {item.partNumber || "--"} <b>|</b>{" "}
                        {item.quantity || "--"}
                      </span>
                    </div>
                  ))}
                </span>) : 'N/A'}
              </td>
            } else if (a.type === 'ticketStatus') {
              return <td key={i + a.type} className="text-capitalize">
                {request[a.type] === 0 && <span>Pending</span>}
                {request[a.type] === 1 && <span>In Process</span>}
                {request[a.type] === 2 && <span>Completed</span>}
              </td>
            } else if (a.type === 'action') {
              return <td key={i + a.type}>
                <div className="action-text">
                  <a className="cursor-pointer ml-3" onClick={(e) => viewDetails(e, request._id)}>Ticket Details</a>
                  <a className="cursor-pointer ml-3" disabled={isDownload} onClick={(e) => downloadHandler(e, request._id)}>Download Ticket</a>
                </div>
              </td>
            } else if (a.type != '_id') {
              return <td key={i + a.type} className="text-capitalize">{request[a.type] || 'N/A'}</td>
            }
          })}
        </tr>
      )
    })
  }

  return (
    <>
      <TitleSetter title={"My Submitted Request"} />
      <div className="explorewrap services-pg-wrap termscondition">
        <div className="mysubmittedrequested">
          <div className="cartPage-heading">
            <h3>My Submitted Requests</h3>
            <div className="subitprefenctbtn">
              <button className="prefrencebtn" onClick={() => { setShow(true) }}>Set Preference</button>
              <div className="common_btn">
                <button
                  className="pl-4 pr-4"
                  onClick={() => {
                    localStorage.removeItem("partNames");
                    history.push("/raise-ticket/0/new");
                  }}
                >
                  Raise Ticket
                </button>
              </div>
            </div>
          </div>

          <div className="tabledatawrap table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {submittedHeader.map((a, i) => {
                    if (a.type != '_id') {
                      return <th key={i}>{a.name}</th>;
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {tBody()}
              </tbody>
            </table>
            {!submittedRequests.length && !isLoading && (
              <div className="emptycart-wrap">
                <p><Empty message="No submitted request found." /></p>
              </div>
            )}
          </div>

          <ReactPaginate
            className="pagination mb-3"
            breakLabel="..."
            nextLabel="Next"
            previousLabel="Previous"
            pageCount={pageCount}
            pageRangeDisplayed={3}
            renderOnZeroPageCount={null}
            onPageChange={handlePageClick}
          />
        </div>
      </div>

      <Modal
        title={"Set Preference"}
        onClose={() => setShow(false)}
        show={show}
        type="preference"
      >
        <Preference
          preference={preference}
          checkedHandler={checkedHandler}
          applyPreference={applyPreference}
          resetDefault={resetDefault} />
      </Modal>
    </>
  );
};

export default IsLoadingHOC(MySubmittedRequest);
