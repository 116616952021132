import React, { useState, useEffect } from "react";
import TitleSetter from "../components/common/TitleSetter";
import "../styles/pages/explore.css";

const Explore = () => {

  return (
    <>
      <TitleSetter title={"Explore"} />
      <div className="explorewrap services-pg-wrap termscondition explorepage-wrap">
        {/* <img src={process.env.PUBLIC_URL + "/images/left_bg.png"} alt="" /> */}
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-xl-1">
              <div className="imageswrap aboutsimg-div explorebg-image">
                <img src="images/exploreimg.png" alt="" />
              </div>
            </div>

            <div className="col-md-6 order-lg-0">
              <div className="explorecont-heading">
                <h3>Product Applications</h3>
              </div>
              <div className="explorecont exploredv">
                <p>
                  Elgin Separation Solutions is an innovative turn-key solutions
                  provider of natural resource recycling, liquid/solid
                  separation, product classification, dewatering, waste
                  management, and material handling operations that solve
                  challenging real-world problems operators face today. We have
                  brought together the industry’s leading specialists in the
                  engineering, design, manufacture, commissioning, and servicing
                  aspects to fully support your operational needs. Learn more
                  about how our team can custom design the ideal system for you.
                </p>
                <p>
                  In addition to manufacturing the most dependable products on
                  the market, Elgin’s field service team offers complete system
                  commissioning/installation, onsite repair, and 24 /7 technical
                  phone support. Looking for spare parts? Elgin maintains a
                  complete inventory of spare parts to keep your system
                  operational.
                </p>
              </div>
              <div className="explore-btn">
                <a
                  href="https://elginseparationsolutions.com/contact-elgin/contact-elgin-industrial/"
                  target="_blank" rel="noreferrer"
                >
                  Contact Elgin Today
                </a>
              </div>
            </div>
          </div>

          {/* <div className="row mb-3">
            {categories.map((cat, index) => (
              <div key={index} className="col-md-4 mt-3">
                <div className="eplorericoncard mr-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="eplorericon">
                        <p className="mb-3">{cat.name}</p>
                        <div className="svgwrap">
                          {}
                          <img src={cat.icon || "images/no-image.png"} alt="" />
                        </div>

                        <div className="explore-list-box">
                          <ul>
                            {cat?.assets.map((asset, i) => (
                              <li key={i}>
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => openAssetsDetails(asset._id)}
                                >
                                  {asset.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <div className="exploretype-wrap">
        <div className="explore-elementor">
          <div className="container">
            <div className="row mb-3">
              <div className="col-md-4 col-sm-6 col-12 mar100">
                <div className="eplorericoncard">
                  <p className="mb-3">Natural Resource Processing</p>
                  <div className="svgwrap">
                    <img src="images/Natural_Resource_Recycling_2.jpg" alt="" />
                  </div>
                  <div className="explore-list-box">
                    <ul>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/tabor-vibrating-screens/"
                          target="_blank" rel="noreferrer"
                        >
                          Vibrating Screens
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/csi-screen-scroll/"
                          target="_blank" rel="noreferrer"
                        >
                          Screen Scroll Centrifuge
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/csi-vibratory-centrigfuge/"
                          target="_blank" rel="noreferrer"
                        >
                          Vibratory Centrifuge
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/rendering-decanter-centrifuges/"
                          target="_blank" rel="noreferrer"
                        >
                          Rendering Centrifuge
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/rotary-breaker/"
                          target="_blank" rel="noreferrer"
                        >
                          Rotary Breaker
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/polyurethane-screens/"
                          target="_blank" rel="noreferrer"
                        >
                          Profile Wire &amp; Polyurethane Screens
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/polyurethane-screens/"
                          target="_blank" rel="noreferrer"
                        >
                          Curved Static Sieve
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/dredge-dewatering-systems/"
                          target="_blank" rel="noreferrer"
                        >
                          Dredge Dewatering
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 mar100">
                <div className="eplorericoncard">
                  <p className="mb-3">Waste Management</p>
                  <div className="svgwrap">
                    <img src="images/Waste_Management_2.jpg" alt="" />
                  </div>
                  <div className="explore-list-box">
                    <ul>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/vertical-cuttings-dryer/"
                          target="_blank" rel="noreferrer"
                        >
                          Vertical Cuttings Dryer
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/drying-shakers/"
                          target="_blank" rel="noreferrer"
                        >
                          Drying Shakers
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/waste-cuttings-treatment-system/"
                          target="_blank" rel="noreferrer"
                        >
                          Turn-Key Waste Cuttings Treatment System
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/composite-shaker-screens/"
                          target="_blank" rel="noreferrer"
                        >
                          Composite Shaker Screens
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/modular-hydrovac-treatment-systems/"
                          target="_blank" rel="noreferrer"
                        >
                          Hydrovac Turn-Key Solutions
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/mega-g-412-scalping-shaker/"
                          target="_blank" rel="noreferrer"
                        >
                          Mega-G 412 Scalping Shaker
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 mar100">
                <div className="eplorericoncard">
                  <p className="mb-3">Dewatering</p>
                  <div className="svgwrap">
                    <img src="images/Dewatering_2.jpg" alt="" />
                  </div>
                  <div className="explore-list-box">
                    <ul>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/decanter-centrifuges/"
                          target="_blank" rel="noreferrer"
                        >
                          Decanter Centrifuges
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/containerized-dewatering-systems/"
                          target="_blank" rel="noreferrer"
                        >
                          Dewatering Systems
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/polymer-injection-systems/"
                          target="_blank" rel="noreferrer"
                        >
                          Polymer Injection Systems
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/mud-cleaners/"
                          target="_blank" rel="noreferrer"
                        >
                          Mud Cleaners
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/mobile-polishing-systems/"
                          target="_blank" rel="noreferrer"
                        >
                          Mobile XP Polisher
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/jet-shear-systems/"
                          target="_blank" rel="noreferrer"
                        >
                          Jet Shear Systems
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/csi-screen-scroll/"
                          target="_blank" rel="noreferrer"
                        >
                          Screen Scroll
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/csi-vibratory-centrigfuge/"
                          target="_blank" rel="noreferrer"
                        >
                          Vibratory Centrifuge
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 mar100">
                <div className="eplorericoncard">
                  <p className="mb-3">Liquid/Solid Separation</p>
                  <div className="svgwrap">
                    <img src="images/Liquid_Solid_Separation_2.jpg" alt="" />
                  </div>
                  <div className="explore-list-box">
                    <ul>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/package-mud-recycling-systems/"
                          target="_blank" rel="noreferrer"
                        >
                          Packaged Mud Recycling Systems
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/shale-shakers/"
                          target="_blank" rel="noreferrer"
                        >
                          Shale Shakers
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/mud-cleaners/"
                          target="_blank" rel="noreferrer"
                        >
                          Mud Cleaners
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/decanter-centrifuge/"
                          target="_blank" rel="noreferrer"
                        >
                          Decanter Centrifuges
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/composite-shaker-screens/"
                          target="_blank" rel="noreferrer"
                        >
                          Composite Shaker Screens
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/hydrocyclone-manifolds/"
                          target="_blank" rel="noreferrer"
                        >
                          Hydrocyclone Manifolds
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/polyurethane-screens/"
                          target="_blank" rel="noreferrer"
                        >
                          Profile Wire &amp; Polyurethane Screens
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/slurry-pumps/"
                          target="_blank" rel="noreferrer"
                        >
                          Slurry Pumps
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/vacuum-degassers/"
                          target="_blank" rel="noreferrer"
                        >
                          Vacuum Degassers
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 mar100">
                <div className="eplorericoncard">
                  <p className="mb-3">Material Handling</p>
                  <div className="svgwrap">
                    <img src="images/Material_Handling_2.jpg" alt="" />
                  </div>
                  <div className="explore-list-box">
                    <ul>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/screw-conveyors/"
                          target="_blank" rel="noreferrer"
                        >
                          Screw Conveyors
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/slurry-pumps/"
                          target="_blank" rel="noreferrer"
                        >
                          Slurry Pumps
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/material-handling-products/"
                          target="_blank" rel="noreferrer"
                        >
                          Belt Wipers
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/material-handling-products/"
                          target="_blank" rel="noreferrer"
                        >
                          Impact Beds
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/material-handling-products/"
                          target="_blank" rel="noreferrer"
                        >
                          Moon sliders
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/material-handling-products/"
                          target="_blank" rel="noreferrer"
                        >
                          Custom Chute Design
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/material-handling-products/"
                          target="_blank" rel="noreferrer"
                        >
                          Sieve Bends
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/material-handling-products/"
                          target="_blank" rel="noreferrer"
                        >
                          Feed Box Systems
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 mar100">
                <div className="eplorericoncard">
                  <p className="mb-3">Water Intake</p>
                  <div className="svgwrap">
                    <img src="images/Water_Intake_2.jpg" alt="" />
                  </div>
                  <div className="explore-list-box">
                    <ul>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/copper-nickel/"
                          target="_blank" rel="noreferrer"
                        >
                          Copper Nickel Solutions
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/coanda-screens/"
                          target="_blank" rel="noreferrer"
                        >
                          Coanda Screens
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/cylindrical-screens/"
                          target="_blank" rel="noreferrer"
                        >
                          Cylindrical Screens
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/flat-panel-screens/"
                          target="_blank" rel="noreferrer"
                        >
                          Flat Panel Screens
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/side-hill-screen"
                          target="_blank" rel="noreferrer"
                        >
                          Side Hill Screens
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://elginseparationsolutions.com/airburst-screen-cleaning-systems/"
                          target="_blank" rel="noreferrer"
                        >
                          Screen Cleaning Systems
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="explore-footer-content">
          <div className="explore-logo">
            <img src="images/logo_ergin.jpg" alt="" />
          </div>
          <div className="explore-descri">
            <p>DID YOU KNOW ?</p>
            <span>
              Elgin can take your existing assests and incorporate into a custom
              solution? Our in-house engineering team can design the ideal skid
              mounted, trailerized, or containerized solution for your
              operational needs.
            </span>
            <div className="explore-btn explorefootr-btn">
              <a href="https://elginseparationsolutions.com/product-applications/">Visit Elgin's Custom Solutions Section for More Information</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Explore;
