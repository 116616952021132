import React, { useEffect, useState } from "react";
import $ from 'jquery';
import { BACKEND_API_URL, REST_API_METHODS } from "../utils/constants";
import "../styles/pages/my-submitted.css";
import {
  callBackendAPI,
  convertDateAndTime,
} from "../utils/helpers";
import { useParams } from "react-router";
import TitleSetter from "../components/common/TitleSetter";
import IsLoadingHOC from "../components/common/IsLoadingHOC";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";

const MySubmittedDetails = (props) => {
  const { setLoading } = props;
  const { id } = useParams();

  const [submittedDetails, setSubmittedDetails] = useState({});
  const [isDownload, setDownload] = useState(false);

  useEffect(() => {
    getSubmittedDetails();
    $('#submitted-request').addClass('active');
    return (() => {
      $('#submitted-request').removeClass('active');
    });
  }, []);

  const getSubmittedDetails = async () => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getTicketDetails?id=${id}`,
        REST_API_METHODS.GET
      );
      if (!!response?.status) {
        setSubmittedDetails(response.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };


  const downloadHandler = (id) => {
    setDownload(true);
    const token = localStorage.getItem("token");
    const options = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(`${BACKEND_API_URL + "/api/v1"}/ticketDownload?id=${id}`, options)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((uril) => {
        var link = document.createElement("a");
        link.href = uril;
        link.download = `ticket-${id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownload(false);
      })
      .catch((err) => {
        setDownload(false);
        console.log(err);
      });
  };

  return (
    <>
      <TitleSetter title={"My Submitted Request Details"} />
      <div className="explorewrap services-pg-wrap termscondition">
        <div className="submited-dtl-page">
          <div className="cartPage-heading">
            <h3>Request Details</h3>
            <div className="d-flex rightsid-btn">
              <div className="common_btn">
                {submittedDetails.ticketStatus == 0 && <button className="pl-4 pr-4 orngebtn">Pending</button>}
                {submittedDetails.ticketStatus == 1 && <button className="pl-4 pr-4 yellowbtn">In Process</button>}
                {submittedDetails.ticketStatus == 2 && <button className="pl-4 pr-4 greenbtn">Completed</button>}
              </div>
              <div className="downloadbtn">
                <FontAwesomeIcon
                  icon={
                    isDownload
                      ? faSpinner
                      : faDownload
                  }
                  className="courser-pointer"
                  onClick={() => downloadHandler(submittedDetails?._id)}
                />
              </div>
            </div>
          </div>
          <div className="submiteddetail-wrap">
            <div className="dtlsdivbox">
              <ul>
                <li>
                  <div className="dtlsdiv">
                    <div className="dtlsheading">Ticket ID</div>
                    <div className="dtlsbxrow">{submittedDetails?.ticketId}</div>
                  </div>
                </li>
                <li>
                  <div className="dtlsdiv">
                    <div className="dtlsheading">Serial No</div>
                    <div className="dtlsbxrow">{submittedDetails?.serialNo || 'N/A'}</div>
                  </div>
                </li>
                <li>
                  <div className="dtlsdiv">
                    <div className="dtlsheading">Asset Name</div>
                    <div className="dtlsbxrow">{submittedDetails?.assets?.name || 'N/A'}</div>
                  </div>
                </li>
                <li>
                  <div className="dtlsdiv">
                    <div className="dtlsheading">Ticket Type</div>
                    <div className="dtlsbxrow">{submittedDetails?.type?.name || 'N/A'}</div>
                  </div>
                </li>
                <li>
                  <div className="dtlsdiv">
                    <div className="dtlsheading">Assets Group Name</div>
                    <div className="dtlsbxrow">{submittedDetails?.machine?.name || 'N/A'}</div>
                  </div>
                </li>
                <li>
                  <div className="dtlsdiv">
                    <div className="dtlsheading">Expected Delivery Date</div>
                    <div className="dtlsbxrow">{submittedDetails?.requestDate || 'N/A'}</div>
                  </div>
                </li>
                <li>
                  <div className="dtlsdiv">
                    <div className="dtlsheading">Processing Date</div>
                    <div className="dtlsbxrow">{convertDateAndTime(submittedDetails?.createdAt)}</div>
                  </div>
                </li>
                {/* <li>
                  <div className="dtlsdiv">
                    <div className="dtlsheading">
                      Part Name | Number | Quantity
                    </div>
                    {!!Object.keys(submittedDetails).length && <div className="dtlsbxrow">
                      {submittedDetails?.parts.map((item, index) => (
                        <div className="" key={index}>
                          <span>
                            {item.partName || "--"} <b>|</b>{" "}
                            {item.partNumber || "--"} <b>|</b>{" "}
                            {item.quantity || "--"}
                          </span>
                        </div>
                      ))}
                      {!submittedDetails?.parts.length && <span>N/A</span>}
                    </div>}
                  </div>
                </li> */}
                <li>
                  <div className="dtlsdiv">
                    <div className="dtlsheading">Customer Name</div>
                    <div className="dtlsbxrow">{submittedDetails?.customer_name || 'N/A'}</div>
                  </div>
                </li>
                <li>
                  <div className="dtlsdiv">
                    <div className="dtlsheading">Contact</div>
                    <div className="dtlsbxrow">{submittedDetails?.phone ? submittedDetails?.phone : 'N/A'}</div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="addressdiv-left">
              <div className="addresswrap">
                {(!!Object.keys(submittedDetails).length && !!submittedDetails?.parts.length) && <div>
                  <p>Part Details</p>
                  <table className="sub">
                    <tr>
                      <th>Part Name</th>
                      <th>Number</th>
                      <th>Quantity</th>
                    </tr>
                    <tr>
                      <td colspan="3"> {!!Object.keys(submittedDetails).length && <div className="dtlsbxrow">
                        {submittedDetails?.parts.map((item, index) => (
                          <div className="tbrrw" key={index}>
                            <span>
                              {item.partName || "--"} {" "}
                            </span>
                            <span>{item.partNumber || "--"} {" "}</span>
                            <span>{item.quantity || "--"}</span>
                          </div>
                        ))}
                        {!submittedDetails?.parts.length && <span>N/A</span>}
                      </div>}</td>
                    </tr>
                  </table>
                  <div className="divbdr"></div>
                </div>}
                <p>Address</p>
                <span>
                  {submittedDetails?.address ? (
                    <span>
                      {submittedDetails.address.address}, {submittedDetails.address.state},{" "}
                      {submittedDetails.address.city}, {submittedDetails.address.pin_code}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </span>
                <br /><br />
                <div className="divbdr"></div>
                <p>Comments</p>
                <span>{submittedDetails?.comment || 'N/A'}</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(MySubmittedDetails);
