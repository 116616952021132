// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, d } from "firebase/messaging";
import { getDatabase, ref, get } from "firebase/database";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    databaseURL: "https://hexa-rpi34-default-rtdb.firebaseio.com",
    messagingSenderId: process.env.REACT_APP_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

const messaging = getMessaging();

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                return currentToken;
                // Perform any other neccessary action with the token
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
                return null;
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("payload", payload)
            resolve(payload);
        });
    });

export const getUserData = (payload) => {
    return new Promise((resolve, reject) => {
        const dbRef = ref(getDatabase());
        get(dbRef, payload + '/').then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                let obj = {};
                for (const prop in data) {
                    obj = data[prop];
                }
                resolve(obj);
            } else {
                console.log("No data available");
                resolve(null);
            }
        }).catch((error) => {
            console.error(error);
            reject(null)
        });
    });
};
