import React from "react";
import BaseWrapper from "../components/auth-flow/BaseWrapper";
import LoginForm from "../components/auth-flow/LoginForm";
import TitleSetter from "../components/common/TitleSetter";

const Login = () => {
  return (
    <div>
      <TitleSetter title={"Sign in"} />
      <BaseWrapper
        FormComponent={LoginForm}
      />
    </div>
  );
};

export default Login;
