import React from "react";
import BaseWrapper from "../components/auth-flow/BaseWrapper";
import ForgotPasswordForm from "../components/auth-flow/ForgotPasswordForm";
import TitleSetter from "../components/common/TitleSetter";

const ForgotPassword = () => {
  return (
    <div>
      <TitleSetter title={"Forgot Password"} />
      <BaseWrapper
        FormComponent={ForgotPasswordForm}
        backgroundImageURL={`url(${process.env.PUBLIC_URL + "/images/loginbg.jpg"})`}
      />
    </div>
  );
};

export default ForgotPassword;
