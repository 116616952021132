import { BACKEND_API_URL, REST_API_METHODS } from "./constants";
import { toast } from 'react-toastify';
import * as moment from "moment";

/**
 *
 * Call Backend API
 *
 * @param {string} path
 * @param {string} method
 * @param {Object=} payload
 * @returns Promise
 */
export const callBackendAPI = (path, method, payload = {}) => {
  const options = {
    method,
    cache: "no-cache",
    headers: authHeader(),
  };

  if (method !== REST_API_METHODS.GET) {
    options.body = JSON.stringify(payload);
  }

  return fetch(`${BACKEND_API_URL + '/api/v1'}/${path}`, options)
    .then((response) => {
      return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if ([401, 403].includes(response.status)) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            showError(data.message);
            localStorage.clear();
            window.location.href = process.env.PUBLIC_URL;
          }

          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        return data;
      });
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const callBackendAPIForm = (path, method, payload = {}) => {
  const options = {
    method,
    cache: "no-cache",
    headers: authHeaderForm(),
  };

  if (method !== REST_API_METHODS.GET) {
    options.body = payload;
  }

  return fetch(`${BACKEND_API_URL + '/api/v1'}/${path}`, options)
    .then((response) => {
      return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if ([401, 403].includes(response.status)) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            showError(data.message);
            localStorage.clear();
            window.location.href = process.env.PUBLIC_URL;
          }

          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        return data;
      });
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

// auth helper functions

function authHeader() {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = localStorage.getItem("token");
  const isLoggedIn = !!token;
  const headers = { "Content-Type": "application/json" };
  if (isLoggedIn) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

function authHeaderForm() {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = localStorage.getItem("token");
  const isLoggedIn = !!token;
  const headers = {};
  if (isLoggedIn) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

export const showMessage = (message) => {
  toast.dismiss();
  toast.success(message)
}

export const showError = (message) => {
  toast.dismiss();
  toast.error(message)
}

export const showInfo = (message) => {
  toast.dismiss();
  toast.info(message)
}

export const getCurrentTime = () => {
  return moment().valueOf();
}

export const convertDateToMilliseconds = (date) => {
  return moment(date).valueOf();
}

export const convertDateToMillisecondsWithTime = (date) => {
  return moment(date).set({ "hour": 22, "minute": 32, second: 22 }).valueOf();
}

export const convertDateToMillisecondsWithoutTime = (date) => {
  const currentDate = moment(date).format("D MMMM Y");
  const milliseconds = moment(currentDate).valueOf();
  return milliseconds;
}

export const convertDate = (date) => {
  return moment(parseInt(date)).format("D MMMM Y");
};

export const convertDateAndTime = (date) => {
  return moment(parseInt(date)).format("D MMMM Y hh:mm A");
};

export const convertTime = (time) => {
  return moment(parseInt(time)).format("HH:mm A");
};

export const userInfo = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const setUserInfo = (user) => {
  return localStorage.setItem("user", JSON.stringify(user));
};

export const convertDateForCalender = (date) => {
  return moment(new Date(parseInt(date))).format('YYYY-MM-DD')
}

export const convertDateForGraph = (date) => {
  return moment(new Date(parseInt(date))).format('D MMMM Y')
}

export const getDays = (assetsInstallDate) => {
  let days = 0;
  if (!!assetsInstallDate) {
    const currentDate = new Date();
    const installDate = new Date(parseInt(assetsInstallDate));
    const current = moment(currentDate, 'DD-MM-YYYY');
    const enter = moment(installDate, 'DD-MM-YYYY');
    days = current.diff(enter, 'days');
  }

  return days;
}

export const getHours = (assetsInstallDate) => {
  let hours = 0;
  if (!!assetsInstallDate) {
    const currentDate = new Date();
    const installDate = new Date(parseInt(assetsInstallDate));
    const current = moment(currentDate, 'DD-MM-YYYY');
    const enter = moment(installDate, 'DD-MM-YYYY');
    hours = current.diff(enter, 'hours');
  }

  return hours;
}

export const addDays = (date, days) => {
  const installDate = new Date(parseInt(date));
  const addedDate = moment(installDate, "DD-MM-YYYY").add(days, 'days');
  return addedDate.valueOf()
}

export const removeDays = (date, days) => {
  const installDate = new Date(date);
  const addedDate = moment(installDate).subtract(days, 'days');
  return addedDate.valueOf()
}

export const removeMonths = (date, month) => {
  const installDate = new Date(parseInt(date));
  const addedDate = moment(installDate).subtract(month, 'month');
  return addedDate.valueOf()
}

export const getWeekName = () => {
  return moment().format('dddd');
}

export const unique = (values) => {
  var setObj = new Set(); // create key value pair from array of array

  return values.reduce((acc, item) => {
    if (!setObj.has(item._id)) {
      setObj.add(item._id, item)
      acc.push(item)
    }
    return acc;
  }, []);
}

export const getLocality = (latitude, longitude) => {
  return new Promise((resolve, reject) => {
    let geocoder;

    geocoder = new window.google.maps.Geocoder();
    const latlng = new window.google.maps.LatLng(latitude, longitude);
    geocoder.geocode(
      { latLng: latlng },
      function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const fullAddress = results[0].formatted_address;
            if (!!results[0].address_components.length) {
              const findRodeName = results[0].address_components.find((ac) => ac.types[0] === 'route');
              const colonyName = results[0].address_components.find((ac) => ac.types[2] === 'sublocality_level_1');
              const areaName = results[0].address_components.find((ac) => ac.types[2] === 'sublocality_level_2');
              const findCountry = results[0].address_components.find((ac) => ac.types[0] === 'country');
              const findState = results[0].address_components.find((ac) => ac.types[0] === 'administrative_area_level_1');
              const findCity = results[0].address_components.find((ac) => ac.types[0] === 'locality');
              const findPinCode = results[0].address_components.find((ac) => ac.types[0] === 'postal_code');
              const findFloor = results[0].address_components.find((ac) => ac.types[0] === 'floor');
              const findStreetNumber = results[0].address_components.find((ac) => ac.types[0] === 'street_number');

              resolve({
                city: findCity ? findCity.long_name : '',
                state: findState ? findState.long_name : '',
                country: findCountry ? findCountry.long_name : '',
                countryIsoCode: findCountry ? findCountry.short_name.toLocaleLowerCase() : 'in',
                houseNo: '',
                roadName: findRodeName ? findRodeName.long_name : '',
                pin_code: findPinCode ? findPinCode.long_name : '',
                colonyName: colonyName ? colonyName.long_name : '',
                areaName: areaName ? areaName.long_name : '',
                floor: findFloor ? findFloor.long_name : '',
                streetNumber: findStreetNumber ? findStreetNumber.long_name : '',
                address: fullAddress,
                latitude,
                longitude
              });
            } else {
              reject(false);
              console.log('address not found');
            }
          } else {
            reject(false);
            console.log('Geocoder failed due to: ' + status);
          }
        }
      }
    );
  });
}

export const viewSupport = () => {
  window.open('http://support.elgin-ai.com/chat/1g7osf9u4', '_self');
}