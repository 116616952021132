import { useState, useEffect } from "react";
import { REST_API_METHODS } from "../../utils/constants";
import { callBackendAPI, convertDate, showMessage } from "../../utils/helpers";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBellSlash } from "@fortawesome/free-solid-svg-icons";
import IsLoadingHOC from "./IsLoadingHOC";

const MaintenanceList = (props) => {
  const { alerts, user, id, fetchAssetsFnCallback, setLoading, isLoading, modalHandler } = props;
  const [maintenanceAlert, setMaintenanceAlert] = useState([]);

  useEffect(() => {
    if (isLoading) {
      setLoading(false);
    }
    setMaintenanceAlert(alerts);
  }, [alerts]);

  return (
    <div>
      <div className="maintenance-month-list">
        <div className="monthwise-list">
          {maintenanceAlert.map((alertDate, index) => (
            <div key={index}>
              <div className="d-flex">
                {/* <span className="circlediv"></span> */}
                <div className="month-date"><img src="images/calender-icon.svg" alt="" /> {alertDate.date}</div>
              </div>

              <div className="maintenance-content-box">
                <ul>
                  {alertDate?.maintenanceAlerts.map((main, i) => (
                    <li key={i}>
                      {/* <div className={`statusline ${!main.status ? 'greenline' : ''}`}>
                        <ul>
                          <li>
                            <div className="iconimg">
                              <img src="images/progres-icon.png" />
                            </div>
                          </li>
                        </ul>
                      </div> */}
                      <div className="maintenancediv-wrap">
                        <div className="maintenance-info">
                          <div className="partnumberdiv">
                            <div className="partdiv"><span>Part Name:</span> <p>{main.name}</p></div>
                            <div className="markdiv ondesktop" onClick={() => modalHandler(main._id, 'show', user, id)}>Mark Complete</div>
                          </div>
                          <div className="info-content">

                            <div className="runingdiv"><span>Running Hours:</span><p>{main.runningHours}</p></div>
                            {/* <div className="maintenance-number">{main.runningHours}</div> */}
                          </div>
                          {main.description && <div className="maintenancepoint-list">
                            <ul>
                              <ol>{main.description}</ol>
                            </ul>
                          </div>}
                          <div className="partnumberdiv onphone">
                            <div className="markdiv " onClick={() => modalHandler(main._id, 'show', user, id)}>Mark Complete</div>
                          </div>

                        </div>
                      </div>
                    </li>))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IsLoadingHOC(MaintenanceList);